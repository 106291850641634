import { Card, CardHeader, CardBody, CardFooter, Divider, Button } from "@nextui-org/react";
import { FaUsers } from "react-icons/fa6";
import { useEffect } from "react";
import { IoAddOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import AdminService from '../../../services/AdminService'
import AuthService from "../../../services/AuthService";
import { useState } from "react";
import { useMessageContext } from '../../../contexts/MessageContext';


const Users = () => {
    const navigate = useNavigate();
    const [cantidadUsuarios, setCantidadUsuarios] = useState(0);
    const { handleError } = useMessageContext();

    useEffect(() => {
        loadUsuarios();

        // eslint-disable-next-line
    }, [])

    const loadUsuarios = async () => {
        if (await AuthService.validateToken(handleError)) {
            try {
                const cantidadUsers = await AdminService.cantidadUsuarios();
                if (cantidadUsers) {
                    setCantidadUsuarios(cantidadUsers);
                }
            } catch (error) {
             
            }

        }
    }

    return (
        <div>
            <Card className="w-full min-h-[230px]">
                <CardHeader className="flex gap-3">
                    < FaUsers />
                    <div className="flex flex-col">
                        <p className="text-md">Usuarios</p>
                        <p className="text-small text-default-500">Administra los usuarios</p>
                    </div>
                </CardHeader>
                <Divider />
                <CardBody>
                    <div className="flex flex-col items-center">
                        <h2 className="text-4xl font-semibold">{cantidadUsuarios}</h2>
                        <span className="text-default-500 text-medium font-light">Usuarios activos</span>
                    </div>
                </CardBody>
                <Divider />
                <CardFooter className="flex justify-center gap-2">

                    <Button color="primary" auto shadow className="w-full" onClick={() => navigate('/usuarios')}>
                        Administrar
                    </Button>
                    <Button color="warning" auto shadow className="p-3 text-lg min-w-fit rounded-full float-right" onClick={() => navigate('/usuarios')}>
                        <IoAddOutline />
                    </Button>
                </CardFooter>
            </Card>
        </div>
    )
}
export default Users