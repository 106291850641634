
import { useEffect, useState } from 'react';
import { Input, Button } from '@nextui-org/react';
import { RiUserSettingsFill } from 'react-icons/ri';
import { Card, CardBody, CardHeader } from '@nextui-org/react';
import { useMessageContext } from '../../../contexts/MessageContext';
import AdminService from '../../../services/AdminService';
import { useParams } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
const PasosUsuario = () => {

    const [pasos, setPasos] = useState(0);
    const { id } = useParams();
    const { handleError, handleSuccess } = useMessageContext();

    useEffect(() => {
        fetchPasos();
        // eslint-disable-next-line
    }
        , []);

    const fetchPasos = async () => {
        if (await AuthService.validateToken(handleError)) {
            const pasos = await AdminService.getPasos(id);
            setPasos(pasos.pasos);
        }
    }
    const handleEditPassos = async () => {
        if (await AuthService.validateToken(handleError)) {
            const data = await AdminService.updatePasos(id, pasos);
            if (data) {
                handleSuccess("Pasos actualizados correctamente");
            }
        }
    }


    return (
        <>
            <div className=' bg-default-50 dark:bg-default-50 h-full p-4 rounded-xl'>
                <div className='mb-4 flex flex-column align-center justify-between'>
                    <p className='text-xs text-gray-400 flex justify-center'>
                        Cantidad de pasos diarios
                    </p>

                </div>
                <div className='flex justify-center items-center gap-4  h-full'>
                    <Card className='mb-2 bg-default-100 dark:bg-default-100 '>
                        <CardHeader className='flex items-center text-small p-4'>
                            Añade la cantidad de pasos que realiza el usuario diariamente
                        </CardHeader>
                        <CardBody>
                            <div className='flex justify-center items-center gap-4'>
                                <Input
                                    type='number'
                                    value={pasos}

                                    onChange={(e) => setPasos(e.target.value)}
                                    startContent={
                                        <RiUserSettingsFill />
                                    }
                                />

                                <Button
                                    color='primary'
                                    auto
                                    onClick={handleEditPassos}
                                >
                                    Guardar
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </div>




            </div>

        </>

    )
}

export default PasosUsuario;