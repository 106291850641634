
const AlimentosService = {
  getAlimentos: async (tipo) => {

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/dieta/getalimentos/${tipo}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        //throw new Error('Error en la autenticación');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      //throw new Error(error.message);
    }
  },
  getDieta: async () => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/dieta/getdieta`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token: token,
        },
      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        //throw new Error('Error en la autenticación');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      //throw new Error(error.message);
    }
  },
  getSuplmentos: async () => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/dieta/getsuplementos`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token: token,
        },
      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        //throw new Error('Error en la autenticación');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      //throw new Error(error.message);
    }
  },
  descargarDieta : async () => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/dieta/descargardieta`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token: token,
        },
      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        //throw new Error('Error en la autenticación');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      //throw new Error(error.message);
    }
  }

};

export default AlimentosService;
