import React, { createContext, useContext, useState, useRef } from 'react';
import { toast } from 'react-toastify';
const MessageContext = createContext();

export const useMessageContext = () => {
  return useContext(MessageContext);
};

export const MessageProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const timeLastMessage = useRef(null);

  const handleError = (errorMessage) => {
    const currentTime = new Date().getTime();
    if (timeLastMessage.current === null || currentTime - timeLastMessage.current >= 2000) {
      toast.error(errorMessage);
      setError(errorMessage);
      timeLastMessage.current = currentTime;
    }
  };

  const handleUnauthenticated = (errorMessage) => {
    const currentTime = new Date().getTime();
    if (timeLastMessage.current === null || currentTime - timeLastMessage.current >= 2000) {
      toast.error(errorMessage);
      setError('No estás autorizado para realizar esta acción');
      timeLastMessage.current = currentTime;
    }
  };

  const handleSuccess = (successMessage) => {
    const currentTime = new Date().getTime();
    if (timeLastMessage.current === null || currentTime - timeLastMessage.current >= 2000) {
      toast.success(successMessage);
      setSuccess(successMessage);
      timeLastMessage.current = currentTime;
    }
  };

  const clearMessages = () => {
    setError(null);
    setSuccess(null);
  };

  return (
    <MessageContext.Provider value={{ error, success, handleError, handleSuccess, clearMessages, handleUnauthenticated }}>
      {children}
    </MessageContext.Provider>
  );
};