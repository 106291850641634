import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(null);
  const [isAdministrator, setAdministrator] = useState(null);

  const login = (token, admin, firstTime) => {
    localStorage.setItem('40coaching_token', JSON.stringify(token));
    setAuthenticated(true);
    if (admin) {
      localStorage.setItem('40coaching_admin', 'true');
      setAdministrator(true);
    } else {
      localStorage.setItem('40coaching_admin', 'false');
    }
    if(firstTime){
      window.location.href = '/empecemos';
    }


  };

  const logout = () => {
    setAdministrator(false);
    localStorage.removeItem('40coaching_admin');
    localStorage.removeItem('40coaching_token');
    setAuthenticated(false);

  };



  useEffect(() => {
    const storedToken = localStorage.getItem('40coaching_token');
    const storedAdmin = localStorage.getItem('40coaching_admin');
    if (storedToken) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
    if (storedAdmin === 'true') {
      setAdministrator(true);
    } else {
      setAdministrator(false);
    }
  }, []);

  if (isAuthenticated === null) {
    // Mientras se verifica la autenticación, puedes devolver un componente de carga o null.
    return <div></div>;
  }




  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, isAdministrator }}>
      {children}
    </AuthContext.Provider>
  );
};
