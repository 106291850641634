import { Card } from "@nextui-org/react"
import { Switch } from "@nextui-org/react";
import { Input } from "@nextui-org/react";
import { BsWhatsapp } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import UsersService from "../../services/UsersService";
import AuthService from "../../services/AuthService";
import { useEffect, useState } from "react";
import { Button } from "@nextui-org/react";
import { useMessageContext } from '../../contexts/MessageContext';


const Notificaciones = () => {
    const [notificacionesInfo, setNotificacionesInfo] = useState(null);
    const { handleUnauthenticated, handleError, handleSuccess } = useMessageContext();

    useEffect(() => {
        UsersService.getInfoNotificaciones(handleUnauthenticated).then((res) => {
            setNotificacionesInfo(res)
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = async () => {
        if (await AuthService.validateToken(handleError)) {
            UsersService.setInfoNotificaciones(notificacionesInfo, handleUnauthenticated, handleError, handleSuccess).then((res) => {
            }
            );
        }
    };

    return (
        <>
            {notificacionesInfo && (
                <>

                    <Card shadow className="p-4 mt-4 m-2">
                        <h2 className="text-sm mb-4">Quieres recibir notificaciones?</h2>
                        <Switch
                            defaultSelected
                            color="success"
                            size="md"
                            isSelected={notificacionesInfo.notificaciones[0].noti === 1}
                            onValueChange={(value) => {
                                setNotificacionesInfo({
                                    ...notificacionesInfo,
                                    notificaciones: [
                                        {
                                            ...notificacionesInfo.notificaciones[0],
                                            noti: value ? 1 : 0,
                                        },
                                    ],
                                });
                            }
                            }

                        >

                            <p className="text-small text-gray-400">Recibir notificaciones</p></Switch>

                    </Card>

                    <Card shadow className="p-4 mt-4 m-2"
                    >
                        <h2 className="text-sm mb-4">Medios de notificacion</h2>
                        <Input
                            type="number"
                            className="text-sm"
                            placeholder="673636565"
                            labelPlacement="outside"
                            startContent={
                                <div className="pointer-events-none flex items-center">
                                    <span className="text-default-400 text-small"><BsWhatsapp /></span>
                                </div>
                            }
                            value={notificacionesInfo.datos.telefono}
                            onChange={(e) => {
                                setNotificacionesInfo({
                                    ...notificacionesInfo,
                                    datos: {
                                        ...notificacionesInfo.datos,
                                        telefono: e.target.value,
                                    },
                                });
                            }}

                        />
                        <Input
                            type="email"
                            className="mt-2 text-sm"
                            placeholder="tu@example.com"
                            labelPlacement="outside"
                            startContent={
                                <div className="pointer-events-none flex items-center">
                                    <span className="text-default-400 text-small"><MdOutlineEmail /></span>
                                </div>
                            }
                            value={notificacionesInfo.datos.email}
                            onChange={(e) => {
                                setNotificacionesInfo({
                                    ...notificacionesInfo,
                                    datos: {
                                        ...notificacionesInfo.datos,
                                        email: e.target.value,
                                    },
                                });
                            }}

                        />


                    </Card>
                    <Card shadow className="p-4 mt-4 m-2" >
                        <h2 className="text-sm mb-4">Que notificaciones quieres recibir?</h2>
                        <div className="flex justify-between">
                            <p className="text-sm text-gray-400">Notificaciones de revisiones</p>
                            <Switch defaultSelected color="success" size="sm"
                                isSelected={notificacionesInfo.notificaciones[0].tipo1 === 1}
                                onValueChange={(value) => {
                                    setNotificacionesInfo({
                                        ...notificacionesInfo,
                                        notificaciones: [
                                            {
                                                ...notificacionesInfo.notificaciones[0],
                                                tipo1: value ? 1 : 0,
                                            },
                                        ],
                                    });
                                }
                                }
                            ></Switch>
                        </div>
                        <div className="flex justify-between mt-3">
                            <p className="text-sm text-gray-400">Notificaciones de nuevos archivos </p>
                            <Switch defaultSelected color="success" size="sm"
                                isSelected={notificacionesInfo.notificaciones[0].tipo2 === 1}
                                onValueChange={(value) => {
                                    setNotificacionesInfo({
                                        ...notificacionesInfo,
                                        notificaciones: [
                                            {
                                                ...notificacionesInfo.notificaciones[0],
                                                tipo2: value ? 1 : 0,
                                            },
                                        ],
                                    });
                                }
                                }
                            ></Switch>
                        </div>

                    </Card>

                    <div className="flex justify-end mr-3">

                        <Button className="mt-1" color="primary" auto onClick={handleSave}>
                            Guardar
                        </Button>
                    </div>


                </>
            )}
        </>

    )
}
export default Notificaciones