
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { useEffect } from "react";
import { useState } from "react";


const Calentamiento = () => {


    const { isOpen, onOpenChange } = useDisclosure();
    const [openVideo, setOpenVideo] = useState(false);

    useEffect(() => {
        onOpenChange(true);
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);

    return (
        <>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Calentamiento</ModalHeader>
                            <ModalBody>
                                {
                                    openVideo ? (
                                        <iframe width="315" height="560" title="video de calentamiento"
                                            src="https://www.youtube.com/embed/hgCk1sQ9J2s?si=qU0vBTTCbynPOwEe"
                                            allowfullscreen></iframe>
                                    ) : <>
                                        <p>

                                            Coge una barra ligera y ejecuta los movimientos del video. Estos son: 50 movimientos de curl de bíceps, 50 de press de hombro, 25 de dislocación de hombros, 15 sentadillas y los estiramientos de piernas.
                                        </p>

                                    </>
                                }

                            </ModalBody>
                            <ModalFooter>
                                <Button color="warning" variant="solid" onPress={() => setOpenVideo(!openVideo)}>
                                    {
                                        openVideo ? (
                                            <>Explicación</>
                                        ) : <>Ver ejemplo</>
                                    }
                                </Button>
                                <Button color="primary" onPress={onClose}>
                                    Finalizado
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}


export default Calentamiento