import { Card, CardBody, CardHeader, CardFooter } from '@nextui-org/react';
import { Button } from '@nextui-org/react';
import { FaCirclePlus } from "react-icons/fa6";
import { Chip } from '@nextui-org/react';
import { Tooltip } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import AdminService from '../../../services/AdminService';
import { AiFillEdit } from "react-icons/ai";
import ModalDieta from './ModalDieta';

const ListDietaUser = (id) => {

    const [dieta, setDieta] = useState([]);
    const [accion, setAccion] = useState(false);

    useEffect(() => {
        getDieta();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accion]);

    const getDieta = async () => {
        const data = await AdminService.getDieta(id.id);
        setDieta(data);
    }


    return (
        <div className=' bg-default-50 dark:bg-default-50 h-full p-4 rounded-xl'>
            <div className=' bg-default-50 dark:bg-default-50 h-full p-4 rounded-xl px-2 py-2 '>
                <div className='mb-4 flex flex-column align-center justify-between'>
                    <p className='text-xs text-gray-400 flex justify-center'>
                        Dieta del usuario
                    </p>
                    <Button color='warning' className='p-1 self-end' onClick={() => setAccion("add")}>
                        <FaCirclePlus />
                    </Button>
                </div>
                <div className='grid grid-cols-2 gap-2'>

                    {
                        dieta.length === 0 ? <p className="text-center ">No hay dieta programada</p> :
                            dieta.map((comida) => (
                                <div key={comida.id}>
                                    <Card className=' bg-default-100 dark:bg-default-100'>
                                        <CardHeader >
                                            <p className='text-xs'>
                                                {comida.nombre}
                                            </p>
                                        </CardHeader>

                                        <CardBody className='flex flex-row items-center justify-start flex-wrap w-full pt-0'>
                                            {comida.alimentos.map((alimento) => (
                                                <Tooltip content={alimento.tipo} key={alimento.tipoID}>
                                                   <Chip className= "text-white text-xs m-1"
                                                    style={{ backgroundColor: 
                                                        alimento.tipoID === 1 ? "#002554" : 
                                                        alimento.tipoID === 2 ? "#d22630" : 
                                                        alimento.tipoID === 3 ? "#ffc845" : 
                                                        alimento.tipoID === 4 ? "pink" : 
                                                        alimento.tipoID === 5 ? "#888b8d" : 
                                                        alimento.tipoID === 6 ? "#e4d5d3" :
                                                        alimento.tipoID === 7 ? "#50a684" :
                                                        alimento.tipoID === 8 ? "#a83d72" :
                                                         "gray"}}
                                                   >
                                                        X{alimento.cantidad}
                                                    </Chip>
                                                </Tooltip>
                                            ))}
                                        </CardBody>
                                        <CardFooter className='text-xs'>
                                            <div className='flex justify-between w-full align-center items-center'>
                                                <div>
                                                    <span className='text-gray-500 mr-1'>Total: </span>
                                                    {
                                                        comida.alimentos.reduce((acc, alimento) => acc + Number(alimento.cantidad), 0)
                                                    }
                                                </div>
                                                <Tooltip content="Editar comida" placement="top" >
                                                    <Button color='warning' style={{ width: '30px', height: '30px', minWidth: '0px', padding: '0px', borderRadius: '50px' }} onClick={() => setAccion(comida)}>
                                                        <AiFillEdit className='cursor-pointer text-small' />
                                                    </Button>
                                                </Tooltip>

                                            </div>
                                        </CardFooter>

                                    </Card>
                                </div>
                            ))
                    }


                </div>

            </div>
            <ModalDieta accion={accion} close={() => setAccion(false)} user={id.id} />
        </div>
    )
}
export default ListDietaUser