import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { Card, CardHeader } from '@nextui-org/react';
import { FaCompressArrowsAlt } from "react-icons/fa";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { Input } from "@nextui-org/react";
import { useEffect } from 'react';
import { useMessageContext } from '../../../contexts/MessageContext';
import { MdDelete } from "react-icons/md";

function Droppable({ children, guardar, editados }) {
  const { isOver, setNodeRef } = useDroppable({
    id: 'droppable',

  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ejercicioTemporal, setEjercicioTemporal] = React.useState(null);
  const [ejerciciosConfirmados, setEjerciciosConfirmados] = React.useState([]);
  const { handleError } = useMessageContext();



  // Abre el modal cuando se añade un nuevo ejercicio
  useEffect(() => {

    if (editados) {
      setEjerciciosConfirmados(editados);
    }

    if (children.length > 0) {
      // Asigna el último ejercicio añadido al estado temporal
      setEjercicioTemporal(children[children.length - 1]);
      onOpen();
    }
  }, [children, onOpen, editados]);

  const handleGuardar = () => {
    // validar que los campos existan y no estén vacíos
    if (!ejercicioTemporal.series ||
      !ejercicioTemporal.descanso ||
      !ejercicioTemporal.repeticiones) {
      handleError('Debes completar todos los campos');
      return;
    }



    onClose();
    if (ejercicioTemporal) {
      const nuevosEjerciciosConfirmados = [...ejerciciosConfirmados, ejercicioTemporal];
      setEjerciciosConfirmados(nuevosEjerciciosConfirmados);
      guardar(nuevosEjerciciosConfirmados); // Llama a la función de callback con los nuevos ejercicios
      setEjercicioTemporal(null);
    }
  };

  

  return (
    <>
      <div ref={setNodeRef} className='w-full bg-gray-200 dark:bg-gray-800 rounded-xl p-2 relative' style={{ border: isOver ? '2px dashed #00000045' : '2px dashed #00000021', minHeight: '140px' }}>


        <FaCompressArrowsAlt className='text-gray-500 absolute top-0 left-0 right-0 bottom-0 m-auto opacity-20' size={80} />




        {ejerciciosConfirmados.map((ejercicio) => (
          <Card key={ejercicio.id} className='mb-2 bg-default-100 dark:bg-default-100 w-fit z-10'>

            <CardHeader className='flex items-center gap-2'>
              <p
                className='text-xs text-gray-500 flex justify-center'
              >
                {ejercicio.nombre}
              </p>
              <MdDelete className='text-red-500 cursor-pointer' onClick={
                () => { setEjerciciosConfirmados(ejerciciosConfirmados.filter(ej => ej.id !== ejercicio.id)); 
                guardar(ejerciciosConfirmados.filter(ej => ej.id !== ejercicio.id)); }}
                
                />
            </CardHeader>
          </Card>
        ))}
      </div>
      <div className="flex flex-wrap gap-3">
      </div>
      <Modal isOpen={isOpen} onClose={onClose} >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Información del ejercicio</ModalHeader>
              <ModalBody>
                <Input type="number" label="Cantidad de series" placeholder="3" onChange={(e) => setEjercicioTemporal({ ...ejercicioTemporal, series: Number(e.target.value) })} />
                <Input type="number" label="Tiempo de descanso" placeholder="60" onChange={(e) => setEjercicioTemporal({ ...ejercicioTemporal, descanso: Number(e.target.value) })} />
                <Input type="number" label="Cantidad de repeticiones" placeholder="10" onChange={(e) => setEjercicioTemporal({ ...ejercicioTemporal, repeticiones: Number(e.target.value) })} />
                <Input type="text" label="Notas del ejercicio" placeholder="Si no tienes una barra, puedes hacerlo con mancuernas" onChange={(e) => setEjercicioTemporal({ ...ejercicioTemporal, notas: e.target.value })} />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={() => {
                  onClose();
                  // Aquí se podría revertir el ejercicio añadido si fuera necesario
                  setEjercicioTemporal(null);
                }}>
                  Cancelar
                </Button>
                <Button color="primary" onPress={handleGuardar}>
                  Confirmar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>

  );
}

export default Droppable;