import React from "react";
import { Link, Button } from "@nextui-org/react";
import { AcmeLogo } from "./LogoWhite.jsx";
import {BlackLogo} from "./LogoBlack.jsx";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem } from "@nextui-org/navbar";
import { useAuthContext } from '../contexts/AuthContext';
import { FaSun, FaMoon } from 'react-icons/fa';
import { useColorMode } from '../contexts/ColorModeContext';
import { useNavigate } from 'react-router-dom';


export default function Header() {

  const { logout } = useAuthContext();
  const { colorMode, toggleColorMode } = useColorMode(); 
  const navigate = useNavigate();

  const menuItems = [
    "Dashboard",
    "Entreno",
    "Nutrición",
    "Perfil",
    "Mis documentos",
    "Configuración",
    "Log Out",
  ];
  const handleClick = (item) => {
       switch (item) {
      case "Dashboard":
        navigate('/');
        break;
      case "Entreno":
        navigate('/listadoentrenos');
        break;
      case "Nutrición":
        navigate('/dieta');
        break;
      case "Perfil":
        navigate('/perfil');
        break;
      case "Configuración":
        navigate('/configuracion');
        break;
      case "Mis documentos":
        navigate('/documentos');
        break;
      case "Log Out":
        logout();
        break;
      default:
        console.log("Default");
        break;
    }
  }

  return (
    <Navbar isBordered>
      <NavbarContent className="sm:hidden" justify="start">
        <NavbarMenuToggle />
      </NavbarContent>

      <NavbarContent className="sm:hidden pr-3" justify="center">
        <NavbarBrand onClick={() => navigate('/')}>
   
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarBrand onClick={() => navigate('/')}>
        {
            colorMode === 'dark' ?   <AcmeLogo />:<BlackLogo />
          }
        </NavbarBrand>
        <NavbarItem>
          <Link color="foreground" href="#">
           Dashboard
          </Link>
        </NavbarItem>
        <NavbarItem isActive>
          <Link href="#" aria-current="page" color="succef">
            Customers
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color="foreground" href="#">
            Integrations
          </Link>
        </NavbarItem>
      </NavbarContent>

      <NavbarContent justify="end">
        <NavbarItem className="hidden lg:flex">
          <Link href="#">Login</Link>
        </NavbarItem>
        <NavbarItem>
        <Link color="warning" variant="flat" >
          <Button as={Link} color="warning" variant="flat" onClick={() => navigate('/listadoentrenos')}>
            Entrenar 🏋️‍♂️
          </Button>
        </Link>
        </NavbarItem>
        <NavbarItem onClick={toggleColorMode}>
          {colorMode === 'dark' ? <FaSun /> : <FaMoon />}
        </NavbarItem>
      </NavbarContent>

      <NavbarMenu>
        {menuItems.map((item, index) => (
          <NavbarMenuItem key={`${item}-${index}`}>
            <Link
              className="w-full"
              color={
                index === menuItems.length - 1 ? "danger" : "foreground"
              }
              onClick={() => handleClick(item)}
              size="lg"
            >
              {item}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>
  );
}
