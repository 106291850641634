import { Skeleton } from "@nextui-org/react";
import UsersService from "../../services/UsersService";
import { useMessageContext } from '../../contexts/MessageContext';
import { useEffect, useState } from "react";
import Tools from "../../tools/tools";
import { Card } from '@nextui-org/react';
import { useRevisionContext } from "../../contexts/RevisionContext";


export default function ListadoFechas() {
    const { handleUnauthenticated } = useMessageContext();
    const [revisiones, setRevisiones] = useState([]);
    const { tocaRevision } = useRevisionContext();

    useEffect(() => {
            UsersService.GetRevisiones(handleUnauthenticated).then((res) => {
                setRevisiones(res.revisiones);
                if (res.tocaRevision) {
                    tocaRevision();
                }

            }).catch((err) => {
                console.log(err);
            
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {revisiones.length > 0 ? (
                <div className="max-w-[600px] w-full flex items-center gap-2 overflow-x-auto pt-1 pb-1 pr-0.5 pl-0.5">
                    {revisiones.map((revision, index) => {
                        let mes = Tools.sqlToJsDate(revision.fecha).toLocaleString('es-ES', { month: 'short' });
                        let dia = Tools.sqlToJsDate(revision.fecha).toLocaleString('es-ES', { day: 'numeric' });
                        let disabled = revision?.id ? false : true;
                        return (
                            <div key={index} className={`w-full flex flex-col gap-2 min-w-unit-16 ${disabled ? 'opacity-50' : ''}`}>
                                <Card className="w-full h-16 rounded-xl flex items-center justify-center flex-col gap-1 " >
                                    <h2 className="text-2xl">{dia}</h2>
                                    <h2 className="text-xs">{mes}</h2>
                                </Card>
                            </div>
                        );
                    })}
                </div>
            ) : (

                <div className="max-w-[600px] w-full flex items-center gap-2 pt-1 pb-1 pr-0.5 pl-0.5">
                    <div className="w-full flex flex-col gap-2">
                        <Skeleton className="h-16 w-full rounded-xl" />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                        <Skeleton className="h-16 rounded-xl w-full" />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                        <Skeleton className="h-16 rounded-xl w-full" />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                        <Skeleton className="h-16 w-full rounded-xl" />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                        <Skeleton className="h-16 w-full rounded-xl" />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                        <Skeleton className="h-16 w-full rounded-xl" />
                    </div>
                </div>
            )}
        </>
    );
}
