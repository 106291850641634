import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { useEffect } from "react";
import { Input } from "@nextui-org/react";
import { Select, SelectItem } from "@nextui-org/react";
import { BsFillPencilFill } from "react-icons/bs";
import { PiBowlFoodFill } from "react-icons/pi";
import AuthService from "../../../services/AuthService";
import { useMessageContext } from '../../../contexts/MessageContext';
import { useState } from "react";
import Tools from "../../../tools/tools";
import { FaBalanceScaleRight } from "react-icons/fa";
import AdminService from "../../../services/AdminService";





export default function ModalAlimentos({ tipoModal, setTipo, handleRefresh, idEdit, setId }) {
    const { handleError } = useMessageContext();
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const [nombre, setNombre] = useState('');
    const [cantidad, setCantidad] = useState('');
    const [tipoAlimento, setTipoAlimento] = useState('');


    useEffect(() => {
        if (tipoModal) {
            onOpen()
        }

        if (tipoModal === 'edit' && idEdit) {
            getAlimentoEdit(idEdit);

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoModal]);

    const getAlimentoEdit = async (id) => {
        if (await AuthService.validateToken(handleError)) {
            const alimento = await AdminService.getAlimento(id);
            setNombre(alimento.nombre);
            setCantidad(alimento.cantidad);
            setTipoAlimento(alimento.tipo);
        }
    }


    const handleClose = () => {
        setTipo();
        setNombre('');
        setCantidad('');
        setTipoAlimento('');
        onClose();
        setId();
    }
    const handleGuardar = async () => {

        //si algun campo esta vacio no se guarda
        if (!nombre || !cantidad || !tipoAlimento) {
            handleError('Debes rellenar todos los campos');
            return;
        }

        if (tipoModal === 'add') {
            if (await AuthService.validateToken(handleError)) {
                await AdminService.addAlimento(nombre, cantidad, Number(tipoAlimento));
            }
        }

        if (tipoModal === 'edit') {
            if (await AuthService.validateToken(handleError)) {
                console.log(nombre, cantidad, tipoAlimento);
                await AdminService.editAlimento(idEdit, nombre, cantidad, Number(tipoAlimento));

            }
        }

        handleClose();
        handleRefresh();
    }


    return (
        <>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} onClose={handleClose}>
                <ModalContent>
                    {() => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                {tipoModal === 'add' && 'Añadir alimento'}
                                {tipoModal === 'edit' && 'Editar alimento'}
                            </ModalHeader>
                            <ModalBody>
                                {
                                    tipoModal === 'add' && (
                                        <>
                                            <Input
                                                type="text"
                                                name="nombre"
                                                value={nombre}
                                                onChange={(e) => setNombre(e.target.value)}
                                                placeholder="Muslo de pollo"
                                                labelPlacement="outside"
                                                startContent={
                                                    <BsFillPencilFill />
                                                }
                                            />
                                            <Input
                                                type="text"
                                                name="cantidad"
                                                value={cantidad}
                                                onChange={(e) => setCantidad(e.target.value)}
                                                placeholder="200 gr"
                                                labelPlacement="outside"
                                                startContent={
                                                    <FaBalanceScaleRight />
                                                }
                                            />

                                            <Select
                                                name="principal"
                                                value={tipoAlimento}
                                                onSelectionChange={(value) => setTipoAlimento(value.currentKey)}
                                                placeholder="Proteínas grasas"
                                                labelPlacement="outside"
                                                startContent={
                                                    <PiBowlFoodFill />
                                                }
                                            >
                                                {
                                                    Array.from({ length: 8 }, (_, i) => (
                                                        <SelectItem value={i + 1} key={i + 1}>
                                                            {Tools.getInfoAlimento(i + 1).info}
                                                        </SelectItem>
                                                    ))
                                                }



                                            </Select>


                                        </>

                                    )
                                }
                                {
                                    tipoModal === 'edit' && tipoAlimento && (
                                        <>
                                            <Input
                                                type="text"
                                                name="nombre"
                                                value={nombre}
                                                onChange={(e) => setNombre(e.target.value)}
                                                placeholder="Muslo de pollo"
                                                labelPlacement="outside"
                                                startContent={
                                                    <BsFillPencilFill />
                                                }
                                            />
                                            <Input
                                                type="text"
                                                name="cantidad"
                                                value={cantidad}
                                                onChange={(e) => setCantidad(e.target.value)}
                                                placeholder="200 gr"
                                                labelPlacement="outside"
                                                startContent={
                                                    <FaBalanceScaleRight />
                                                }
                                            />

                                            <Select
                                                name="principal"
                                                value={tipoAlimento}
                                                onSelectionChange={(value) => setTipoAlimento(value.currentKey)}
                                                defaultSelectedKeys={[tipoAlimento.toString()]}
                                                placeholder="Proteínas grasas"
                                                labelPlacement="outside"
                                                startContent={
                                                    <PiBowlFoodFill />
                                                }
                                            >
                                                {
                                                    Array.from({ length: 8 }, (_, i) => (
                                                        <SelectItem value={i + 1} key={i + 1}>
                                                            {Tools.getInfoAlimento(i + 1).info}
                                                        </SelectItem>
                                                    ))
                                                }
                                                </Select>
                                        </>
                                    )
                                }

                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={handleClose}>
                                    Cerrar
                                </Button>
                                <Button color="primary" onPress={handleGuardar}>
                                    Guardar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}