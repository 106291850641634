
import LoginForm from "../components/LoginForm";


const Login = () => {
    return (
        <main className="flex flex-col items-center justify-center w-full h-screen ">
            <div className="">
                <LoginForm />
            </div>

        </main>

    );
}
export default Login;