import Header from "../../components/admin/Header";
import Users from "../../components/admin/dashboard/Users";
import Entrenos from "../../components/admin/dashboard/Entrenos";
import Notificaciones from "../../components/admin/dashboard/Notificaciones";
import Conexiones from "../../components/admin/dashboard/Conexiones";
import Grafico from "../../components/admin/dashboard/GraficoActividad";
import Acciones from "../../components/admin/dashboard/AccionesRapidas";
import Soporte from "../../components/admin/dashboard/Soporte";
const Admin = () => {
    return (
        <>
            <Header />

            <div className="pl-14 pr-14 pb-14 mx-auto flex flex-col lg:flex-row gap-16">
                <div className=" w-4/6 ">
                    <h1 className="text-xl font-medium mt-10">Resumen</h1>
                    <div className="pt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" >
                        <Users />
                        <Entrenos />
                        <Soporte />
                    </div>
                    <div className="pt-4">
                    <h1 className="text-xl font-medium mt-5">Conexiones</h1>
                        <Grafico />
                    </div>
                </div>
                <div className=" w-2/6 ">

                    <h1 className="text-xl font-medium mt-10">Notificaciones</h1>
                    <div className="pt-4">
                        <Notificaciones />
                    </div>

                    <div className="pt-4">
                        <Conexiones />
                    </div>
                    <div className="pt-4">
                        <Acciones />

                    </div>
              
                </div>
            </div>
        </>
    )
}
export default Admin