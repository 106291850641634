import { useState } from "react";
import { Input, Spacer, Button } from "@nextui-org/react";
import { FiHelpCircle } from 'react-icons/fi';
import UserService from "../services/UsersService";
import AuthService from "../services/AuthService";
import { useMessageContext } from '../contexts/MessageContext';
import { Checkbox } from "@nextui-org/react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure
} from "@nextui-org/react";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";


const FormularioInicialForm = () => {
    const [s, setS] = useState(null);
    const { isOpen: isHelpOpen, onOpen: onHelpOpen, onOpenChange: onHelpOpenChange } = useDisclosure();
    const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onOpenChange: onConfirmOpenChange } = useDisclosure();
    const [idPregunta, setIdPregunta] = useState(null);
    const [publicidad, setPublicidad] = useState(false);
    const [imagen, setImagen] = useState(false);
    const { handleUnauthenticated, handleError, handleSuccess } = useMessageContext();
    const [formData, setFormData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchSexo() {
            if (await AuthService.validateToken(handleError)) {
                setS(await UserService.GetS());
            }
        }
        fetchSexo();

    }
        , []);


    const preguntas = [
        {
            id: 1,
            titulo: 'Alergias o intolerancias',
            descripcion: '¿Tienes alguna alergia o intolerancia y a qué?',
            type: 'string'
        },
        {
            id: 2,
            titulo: 'Padeces alguna enfermedad',
            descripcion: 'Ejemplo: diabetes, hipertensión, etc.',
            type: 'string'
        },
        {
            id: 3,
            titulo: 'Niveles de colesterol',
            descripcion: 'Dime tus niveles de colesterol de tu última analítica (HDL-LDL-TOTAL) y que sea reciente (3/6 meses aprox)',
            type: 'string'

        },
        {
            id: 4,
            titulo: 'Objetivo',
            descripcion: '¿Cuál es tu objetivo? Perder grasa, ganar masa muscular...',
            type: 'string'
        },
        {
            id: 5,
            titulo: 'Días de entrenamiento de musculación',
            descripcion: 'Días que puedes entrenar musculación (de 4 a 6)',
            type: 'string'
        },
        {
            id: 6,
            titulo: 'Horas de sueño y descanso',
            descripcion: '¿Cuántas horas sueles dormir? ¿Te despiertas de noche? ¿Te levantas descansad@?',
            type: 'string'
        },
        {
            id: 7,
            titulo: 'Lesiones o limitaciones físicas',
            descripcion: '¿Tienes alguna lesión, dolor articular, limitación física, etc.?',
            type: 'string'
        },
        {
            id: 8,
            titulo: 'Peso en ayunas',
            descripcion: 'Peso en ayunas (en kg)',
            type: 'number'
        },
        {
            id: 9,
            titulo: 'Edad',
            descripcion: 'Edad',
            type: 'number'
        },
        {
            id: 10,
            titulo: 'Altura',
            descripcion: 'Altura (en cm)',
            type: 'number'
        },
        {
            id: 11,
            titulo: 'Diámetro del cuello',
            descripcion: 'Medida en cm del diámetro de tu cuello',
            type: 'number'
        },
        {
            id: 12,
            titulo: 'Cintura a la altura del ombligo',
            descripcion: 'Medida en cm de tu cintura a la altura del ombligo (sin esconder barriga)',
            type: 'number'
        },
        {
            id: 13,
            titulo: 'Profesión',
            descripcion: '¿De qué trabajas?',
            type: 'string'
        },
        {
            id: 14,
            titulo: 'Deporte habitual',
            descripcion: '¿Haces deporte habitualmente? De qué tipo y cuántas veces por semana.',
            type: 'string'
        },
        {
            id: 15,
            titulo: 'Momento del día con más hambre',
            descripcion: '¿En qué momento del día tienes más hambre, mañana, tarde o noche?',
            type: 'string'
        },
        {
            id: 16,
            titulo: 'Ejemplo de alimentación diaria',
            descripcion: 'A continuación ponme un ejemplo de lo que comes en un día habitualmente, cuántas comidas haces al día y qué alimentos son sagrados para ti, por ejemplo: el café con leche de la mañana me gusta con leche entera....',
            type: 'string'
        },
        {
            id: 17,
            titulo: '¿Has tenidoa alguna vez algun evento cardiovascular?',
            descripcion: 'A continuación expresanos si has tenido alguna vez algun problema cardiovascular, como por ejemplo: infarto, angina de pecho, arritmias, etc.',
            type: 'string'
        },
        {
            id: 18,
            titulo: 'Cadera a la altura de la parte más ancha',
            descripcion: 'Medida en cm de tu cadera a la altura de la parte más ancha',
            type: 'number',
            s: 2

        }


    ];




    const handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        let jsonData = {};
        for (let [key, value] of data.entries()) {
            jsonData[key] = value;
        }
        setFormData(jsonData);
        onConfirmOpen();
    };
    const handleConfirm = async () => {
      
        formData['publicidad'] = publicidad;
        formData['imagen'] = imagen;
        if (await AuthService.validateToken(handleError)) {
            UserService.EnviarFormularioInicial(formData, handleUnauthenticated, handleError, handleSuccess);
            onConfirmOpenChange(false);
            handleSuccess('Formulario enviado correctamente');
            setTimeout(() => {
                navigate('/');
            }, 2000);
        }

    };

    return (
        <form onSubmit={handleSubmit}>
            <Spacer y={4} />
            {
                preguntas
                .filter(pregunta => pregunta.s === undefined || pregunta.s === s)
                .map((pregunta) => {
                    return (
                        <div key={pregunta.id} >
                            <Spacer y={4} />
                            <div key={pregunta.id} style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    type={pregunta.type}
                                    label={pregunta.titulo}
                                    width="100%"
                                    name={`pregunta${pregunta.id}`}
                                />
                                <FiHelpCircle
                                    size="1.5em"
                                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                                    onClick={() => { onHelpOpen(); setIdPregunta(pregunta.id) }}
                                />
                            </div>
                        </div>

                    )
                })
            }
            <div className="py-2">
                <p className="text-xs mb-1 pt-2">
                    <b>Información básica sobre Protección de Datos</b>
                </p>
                <p className="text-xs text-gray-500">
                    Información básica sobre Protección de Datos
                    En cumplimiento de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
                    Personales y Garantía de los Derechos Digitales y el Reglamento (UE) 2016/679 relativo a la
                    protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la
                    libre circulación de estos datos, 40 PLUS COACHING, S.L. con NIF B56849540 y domicilio en
                    Rambla Sant Francesc 23, 1º1ª – 08720 – Vilafranca del Penedès (Barcelona), le informa de que
                    sus datos, entre los que se incluyen datos de categoría especial, serán incorporados en un
                    registro de actividades del que es responsable con la finalidad de prestar el servicio contratado
                    de forma personalizada y segura, y realizar la gestión administrativa, contable y fiscal de los
                    mismos.
                    Sus datos de contacto se podrán utilizar para el envío de comunicaciones comerciales vía
                    correo electrónico o WhatsApp. Usted puede negarse a recibir estas comunicaciones
                    comerciales. Asimismo, también podrá darse de baja de las mismas en cualquier momento
                    solicitándolo a admin@40pluscoaching.es
                </p>


                <Checkbox
                    isSelected={publicidad}
                    onValueChange={setPublicidad}
                    name="acepto"
                    className="text-xs text-gray-500 mt-1 mb-1"
                    size="sm"
                >Deseo recibir comunicaciones comerciales</Checkbox>
            </div>
            <div className="">
                <p className="text-xs text-gray-500">
                    Asimismo, solicitamos su consentimiento expreso, voluntario y libre para el posible uso de su
                    imagen en Internet (redes sociales y página web de la entidad) con la finalidad de poder
                    mostrar los resultados y promocionar nuestros servicios. La publicación de las imágenes en
                    redes sociales e Internet podrá implicar la transferencia internacional de las mismas. Podrá
                    retirar el consentimiento otorgado en cualquier momento, sin que ello afecte a publicaciones
                    ya realizadas con el debido consentimiento:
                </p>


                <Checkbox
                    isSelected={imagen}
                    onValueChange={setImagen}
                    name="acepto"
                    className="text-xs text-gray-500 mt-1 mb-1"
                    size="sm"
                >Autorizo el uso y difusión de mi imagen</Checkbox>
                <p className="text-xs text-gray-500">
                    Usted tiene derecho a acceder, limitar, oponerse, portar, rectificar y suprimir los datos
                    dirigiendo una comunicación escrita en la que se identifique de forma fehaciente, e indicando
                    el derecho que desea ejercer, al domicilio del Responsable o a admin@40pluscoaching.es.
                    Si quiere acceder a información adicional sobre el tratamiento de sus datos puede solicitarla
                    en los mismos medios indicados o consultarla en la Política de Privacidad de
                    www.40pluscoaching.es.
                </p>
            </div>

            <Spacer y={4} />
            <Button auto type="secondary" ghost>Enviar</Button>
            <Modal isOpen={isHelpOpen} onOpenChange={onHelpOpenChange} >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{preguntas.find(pregunta => pregunta.id === idPregunta).titulo}</ModalHeader>
                            <ModalBody>
                                {
                                    preguntas.find(pregunta => pregunta.id === idPregunta).descripcion

                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onPress={onClose}>
                                    Entendido
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
            <Modal isOpen={isConfirmOpen} onOpenChange={onConfirmOpenChange} >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader> ¿Estás seguro de que quieres enviar el formulario?</ModalHeader>
                            <ModalBody>
                                Una vez enviado no podrás modificarlo.

                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Cancelar
                                </Button>
                                <Button color="primary" onPress={handleConfirm}>
                                    Confirmar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>


        </form>
    );
};

export default FormularioInicialForm;