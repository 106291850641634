import { Button } from "@nextui-org/react";
import { IoMdAlert } from "react-icons/io";
import { FaArrowsRotate } from "react-icons/fa6";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { useState } from "react";
const AcalaraionesDieta = () => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [tipo, setTipo] = useState(null);

    return (
        <>
            <div className="w-full flex items-center gap-1 mt-2 mb-4 pl-3 pr-3">
                <Button color="warning" startContent={<IoMdAlert />} className="w-full" onClick={() => { onOpen(); setTipo("importante"); }}>
                    Importante
                </Button>
                <Button color="warning" startContent={<FaArrowsRotate />} className="w-full" onClick={() => { onOpen(); setTipo("combinaciones"); }}>
                    Combinaciones
                </Button>
            </div>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            {tipo === "combinaciones" ? <ModalHeader className="flex flex-col gap-1">Nota aclaratoria de la dieta</ModalHeader> : <ModalHeader className="flex flex-col gap-1">Importante</ModalHeader>}

                            <ModalBody>
                                {
                                    tipo === "combinaciones" ?
                                        <>
                                            <div className="text-sm">
                                                Come cada comida conjuntamente de una sola vez. Las 4 comidas están idealmente repartidas, pero en caso excepcional, se  pueden sumar comidas o repartirlas, lo más importante es que comas todo el plan (ni más ni menos) cada día. Intenta que pase un mínimo de 3 horas entre comidas.
                                                Por otra parte, dentro de cada comida, puedes combinar los múltiplos como más te apetezcan.
                                                Si tienes alguna duda (al principio es normal) contacta con tu COACH, que para eso está.
                                            </div>
                                            <div className="text-sm">
                                                <p className="text-primary-500">
                                                    Disfuta de esta manera de comer, pues <b>¡¡ las combinaciones son infinitas!!</b>
                                                </p>
                                            </div>
                                            <div className="text-sm">
                                                <p className="mb-1">
                                                    <b>Equivalencias 1: </b>Un múltiplo de PROTEÍNAS MAGRAS + Un múltiplo de GRASAS = 2 múltiplos de PROTEÍNAS GRASAS (y viceversa)
                                                </p>
                                                <p className="mb-1">
                                                    <b> Equivalencias 2 : </b>Un múltiplo de FRUTA = Un múltiplo de CARBOHIDRATOS (y viceversa)
                                                </p>
                                                <p className="mb-1">
                                                    <b>Equivalencias 3 :</b> Un múltiplo de CARBOHIDRATOS + 1 DE PROTEÍNAS MAGRAS = 2 múltiplos de LÁCTEOS (y viceversa)
                                                </p>
                                            </div>


                                        </>
                                        :
                                        <>
                                            <div className="text-sm">
                                                <p className="text-primary-500">
                                                    PESA LOS ALIMENTOS SIEMPRE.
                                                </p>
                                                <p className="text-primary-500 mb-1">
                                                    LOS PESOS SON EN CRUDO.
                                                </p>
                                                <p className="mb-1">
                                                    Bebe al menos 3 litros de agua al día. (toma infusiones si te apetecen, te yudarán a llegar al objetivo y calman la ansiedad por comer)
                                                </p>
                                                <p className="mb-1">
                                                    No debes endulzar ninguna bebida o comida con azúcar. Si tomas refrescos que sean si alcohol ni azúcares (light/zero).
                                                </p>
                                                <p className="mb-1">
                                                    Puedes usar todas las espécias que quieras y en la pasta o arroz puedes echarte tomate frito sin azúcares y bajos en grasa, los hay, o bien ketchup light a las carnes o mostaza antigua o soja baja en sales.
                                                </p>
                                                <p className="mb-1">
                                                    No añadas aceite de oliva a no ser que aparezca en el plan. Las carnes/pescados a la plancha/horno/brasa sin aceite a penas (pasa servilleta para esparcirlo y recoger el sobrante)
                                                </p>
                                                <p className="mb-1">
                                                    Pesa las cantidades que en la dieta salgan a pesar. Las verduras/ensaladas no hace falta pesarlas, puedes comer un bowl mediano.
                                                </p>
                                                <p className="mb-1">
                                                    Duerme al menos 7 horas seguidas cada noche, te ayudará a perder grasa y ganar masa muscular.
                                                </p>
                                                <p className="mb-1">
                                                   <b> COMIDA LIBRE SEMANAL: MÁXIMO 800 KCAL. ASEGURA LA PROTEÍNA EN ESTA INGESTA, INTENTA QUE SEA LA CENA, PROCURA NO BEBER ALCOHOL, NO TE EXCEDAS EN EXCESO.</b>
                                                </p>
                                                Seguir estas recomendaciones al 100% a veces es imposible, no pasa nada, es mejor al 90% que nada, esto se debe transformar en un hábito, no te sientas mal si a veces no lo haces todo perfecto, no pasa nada, nadie lo hace.
                                            </div>
                                        </>

                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Cerrar
                                </Button>
                              
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

        </>
    );
}
export default AcalaraionesDieta;
