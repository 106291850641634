
import { Card, CardHeader, Image, CardFooter, Button } from "@nextui-org/react";
import { useMessageContext } from '../../contexts/MessageContext';
import UsersService from "../../services/UsersService";
import { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";

export default function Receta() {

  const { handleUnauthenticated } = useMessageContext();
  const [recetas, setRecetas] = useState([]);
  const [index, setIndex] = useState(1);

  useEffect(() => {
    const fetchRecetas = async () => {
      try {
        const response = await UsersService.GetRecetas();
        if (response) {
          setRecetas(response);
        } else if (response.status === 401) {
          handleUnauthenticated();
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchRecetas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {recetas.length > 0 && (
        <div className="max-w-[900px] w-full flex items-center gap-2 ">
          <div className="w-full h-full">
            <Card isFooterBlurred className="h-60 w-full rounded-xl" >
              <CardHeader className="absolute z-10 top-1 flex-col items-start">
                <h4 className="text-white font-medium text-sm w-1/3">{recetas[index]?.titulo}</h4>
              </CardHeader>
              <Image
                removeWrapper
                alt="receta"
                className="z-0 w-full h-full object-cover"
                src={recetas[index]?.img}
              />
              <div className="absolute z-1 w-full h-full bg-black opacity-20"></div> 
              <CardFooter className="absolute bg-black bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
                <div className="flex flex-grow gap-2 items-center">
                  <div className="flex gap-2 items-center">
                    <Button radius="full" size="sm">
                      <FaArrowLeftLong onClick={() => index > 0 && setIndex(index - 1)} />
                    </Button>
                    <Button radius="full" size="sm">
                      <FaArrowRightLong onClick={() => index < recetas.length - 1 && setIndex(index + 1)} />
                    </Button>
                  </div>
                </div>
                <Button radius="full" size="sm"
                  onClick={() => window.open(recetas[index]?.url, "_blank")}
                >Descargar Receta</Button>
              </CardFooter>
            </Card>
          </div>
        </div>
      )}


    </>
  );
}