import Header from "../../components/admin/Header";
import Acciones from "../../components/admin/ejercicios/Acciones";
import TablaEjercicios from "../../components/admin/ejercicios/TablaEjercicios";
import Modal from "../../components/admin/ejercicios/Modal";
import { useState } from "react";

const Ejercicios = () => {
    const [accioSeleccionada, setAccioSeleccionada] = useState(null);
    const [refresh, setRefresh] = useState(0);
    const [idEdit, setIdEdit] = useState(null);
    


    const handleChangeAccio = (tipo,id) => {
        setAccioSeleccionada(tipo);
        setIdEdit(id)
    }
    const handleRefresh = () => {
     let random = Math.floor(Math.random() * 1000);
        setRefresh(random);
    }

    const setTipo = () => {
        setAccioSeleccionada(null)
    }

    const setId = () => {
        setIdEdit(null)
    }
    
    return (
        <>
            <Header />
            <div className="p-14">
                <div className="w-fit">
                   <Acciones  handleChangeAccio={handleChangeAccio} />
                </div>
                <div className="mt-4 mx-auto">
                    <TablaEjercicios handleRefresh={handleRefresh} refresh={refresh} handleChangeAccio={handleChangeAccio} />
                    <Modal tipoModal={accioSeleccionada}  setTipo={setTipo} handleRefresh={handleRefresh} idEdit={idEdit} setId={setId}/>
                </div>
            </div>
        </>
    )
}
export default Ejercicios