import { Card, CardBody } from "@nextui-org/react";

const Notificaciones = () => {
    return (
        <div>
            <Card className="w-full p-9">
      
                <CardBody>
                    <div className="flex flex-col items-center">
                        <span className="text-default-500 text-medium font-light">No tienes notificaciones pendientes</span>
                    </div>
                </CardBody>
               
            </Card>
        </div>
    )
}
export default Notificaciones