import { Card, CardBody } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { Progress } from "@nextui-org/react";
import Tools from "../../../tools/tools";
const InfoUser = ({ data }) => {

    const [totalDias, setTotalDias] = useState(0);
    const [diasRestantes, setDiasRestantes] = useState(0);

    useEffect(() => {

        if (!data.formulario) return;

        const dias = Tools.fechaToDias(Tools.sqlToJsDate(data.formulario.fecha), Tools.sqlToJsDate(data.info.caducidad));
        setTotalDias(dias);
        const restantes = Tools.fechaToDias(Tools.sqlToJsDate(data.info.caducidad), new Date());
        setDiasRestantes(dias - restantes);

    }// eslint-disable-next-line react-hooks/exhaustive-deps
        , [data]);

    return (
        <Card className="w-full px-9 py-4">

            <CardBody className="flex justify-center  flex-col">
                <Progress
                    label="Ha consumido el"
                    size="sm"
                    value={diasRestantes}
                    maxValue={totalDias}
                    color="warning"
                    showValueLabel={true}
                    className="max-w-md"
                />
                   <p className="text-xs text-gray-500 mt-2">Su suscripción termina el {Tools.sqlToJsDate(data.info.caducidad).toLocaleDateString()}</p>
            </CardBody>

        </Card>
    )
}
export default InfoUser