
import Peso from './rendimiento/Peso';
import UsersService from '../../services/UsersService';
import { useEffect, useState } from 'react';
import { useMessageContext } from '../../contexts/MessageContext';
import Grasa from './rendimiento/Grasa';
import Massamagre from './rendimiento/Massamagre';
import IMC from './rendimiento/IMC';


const Rendimiento = () => {
    const { handleUnauthenticated } = useMessageContext();
    const [revisiones, setRevisiones] = useState(null);

    useEffect(() => {
        UsersService.GetRevisionesBBDD(handleUnauthenticated).then((res) => {
            setRevisiones(res);

        }).catch((err) => {
            console.log(err);

        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (

        <div className="container mx-auto p-6">
            {
                revisiones &&
                <>

                    <h1>Progreso</h1>
                  
                    <Peso revisiones={revisiones} />
                    <IMC />
                 
                    <Grasa />
                 
                   
                   
                   <Massamagre />
               



                </>
            }
        </div>

    )
}
export default Rendimiento;
