import { useEffect } from 'react'
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { useMessageContext } from '../../../contexts/MessageContext';
import { Button } from '@nextui-org/react';
import { useState } from 'react';
import AdminService from '../../../services/AdminService'
import AuthService from '../../../services/AuthService';
import Tools from "../../../tools/tools";

const TablaAlimentos = ({ handleRefresh, refresh, handleChangeAccio}) => {

    const [alimentos, setAlimentos] = useState([])
    const { handleError } = useMessageContext();
    useEffect(() => {
        fetchAlimentos();
        // eslint-disable-next-line 
    }, [refresh])

    const fetchAlimentos = async () => {

        if (await AuthService.validateToken(handleError)) {
            const alimentosData = await AdminService.getAlimentos();
            setAlimentos(alimentosData);
        }

    }
    const handleDelete = async (id) => {
        if (await AuthService.validateToken(handleError)) {
            await AdminService.delAlimento(id);
            handleRefresh();
        }
    }
 


    return (
        <Table>
            <TableHeader>
                <TableColumn span={2}>ID</TableColumn>
                <TableColumn span={2}>Tipo</TableColumn>
                <TableColumn span={2}>Nombre</TableColumn>
                <TableColumn span={2}>Cantidad</TableColumn>
                <TableColumn span={2}>Editar alimento</TableColumn>
                <TableColumn span={2}>Eliminar alimento</TableColumn>
            </TableHeader>
            <TableBody>
                {alimentos.map((alimento) => (
                    <TableRow key={alimento.id}>
                        <TableCell>{alimento.id}</TableCell>
                        <TableCell>{Tools.getInfoAlimento(alimento.tipo).info}</TableCell>
                        <TableCell>{alimento.nombre}</TableCell>
                        <TableCell>{alimento.cantidad}</TableCell>
                        <TableCell><Button color='warning'
                            onClick={() => handleChangeAccio("edit",alimento.id ) }
                        >
                            
                            Editar</Button></TableCell>
                        <TableCell><Button color='danger'
                            onClick={() => {
                                if (window.confirm('¿Estás seguro de que quieres eliminar este ejercicio?')) {
                                    handleDelete(alimento.id);
                                }
                            }}
                        >Eliminar</Button></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
export default TablaAlimentos
