import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useReducer } from "react";
import { Input } from "@nextui-org/react";
import { Select, SelectItem } from "@nextui-org/react";
import { BsFillPencilFill } from "react-icons/bs";
import { CiTextAlignCenter } from "react-icons/ci";
import { GiMuscleUp } from "react-icons/gi";
import { FaVideo } from "react-icons/fa6";
import AdminService from '../../../services/AdminService'
import AuthService from "../../../services/AuthService";
import { useMessageContext } from '../../../contexts/MessageContext';
import { useState } from "react";

const initialState = {
    nombre: '',
    descripcion: '',
    principal: '',
    secundario: '',
    url: ''
};

function reducer(state, action) {
    switch (action.type) {
        case 'field': {
            return {
                ...state,
                [action.fieldName]: action.payload,
            };
        }
        default:
            return state;
    }
}




export default function ModalEjercicios({ tipoModal, setTipo, handleRefresh, idEdit, setId }) {
    const { handleError } = useMessageContext();
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { nombre, descripcion, principal, secundario, url } = state;
    const [ejercicioEdit, setEjercicioEdit] = useState(null);

    const handleInputChange = (e, name) => {

        let value = e.target ? e.target.value : e;
        const fieldName = e.target ? e.target.name : name;



        // If the value is an object with a 'currentKey' property, use the 'currentKey' as the value
        if (typeof value === 'object' && value.currentKey) {
            value = value.currentKey;
        }

        dispatch({
            type: 'field',
            fieldName: fieldName,
            payload: value,
        });
    };


    useEffect(() => {
        if (tipoModal) {
            onOpen()
        }
        if (tipoModal === 'edit' && idEdit) {

            fetchEjercicio(idEdit);
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoModal]);

    const fetchEjercicio = async (id) => {
        if (await AuthService.validateToken(handleError)) {
            const data = await AdminService.getEjercicio(id);
            data.principal = data.primero;

            setEjercicioEdit(data);

        }
    }

    const handleClose = () => {
        setTipo();
        onClose();
        setId();
    }
    const handleGuardar = async () => {
        if (tipoModal === 'add') {
            if (await AuthService.validateToken(handleError)) {
                AdminService.registrarEjercicio(state);
            }
        }

        if (tipoModal === 'edit') {
            if (await AuthService.validateToken(handleError)) {
                console.log(ejercicioEdit);
                AdminService.editarEjercicio(idEdit, ejercicioEdit);
            }
        }

        handleClose();
        handleRefresh();
    }


    return (
        <>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} onClose={handleClose}>
                <ModalContent>
                    {() => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                {tipoModal === 'add' && 'Añadir un ejercicio'}
                                {tipoModal === 'edit' && 'Editar ejercicio'}
                            </ModalHeader>
                            <ModalBody>
                                {
                                    tipoModal === 'add' && (
                                        <>
                                            <Input
                                                type="text"
                                                name="nombre"
                                                value={nombre}
                                                onChange={handleInputChange}
                                                placeholder="Nombre"
                                                labelPlacement="outside"
                                                startContent={
                                                    <BsFillPencilFill />
                                                }
                                            />
                                            <Input
                                                type="text"
                                                name="descripcion"
                                                value={descripcion}
                                                onChange={handleInputChange}
                                                placeholder="Descripción"
                                                labelPlacement="outside"
                                                startContent={
                                                    <CiTextAlignCenter />
                                                }
                                            />
                                            <Select
                                                name="principal"
                                                value={principal}
                                                onSelectionChange={(value) => handleInputChange(value, 'principal')}
                                                placeholder="Músculo principal"
                                                labelPlacement="outside"
                                                startContent={
                                                    <GiMuscleUp />
                                                }
                                            >
                                                <SelectItem value="Pecho" key={"Pecho"}>Pecho</SelectItem>
                                                <SelectItem value="Espalda" key={"Espalda"}>Espalda</SelectItem>
                                                <SelectItem value="Hombros" key={"Hombros"}>Hombros</SelectItem>
                                                <SelectItem value="Biceps" key={"Biceps"}>Biceps</SelectItem>
                                                <SelectItem value="Triceps" key={"Triceps"}>Triceps</SelectItem>
                                                <SelectItem value="Piernas" key={"Piernas"}>Piernas</SelectItem>
                                                <SelectItem value="Gluteos" key={"Gluteos"}>Gluteos</SelectItem>
                                                <SelectItem value="Abdominales" key={"Abdominales"}>Abdominales</SelectItem>
                                                <SelectItem value="Trapecio" key={"Trapecio"}>Trapecio</SelectItem>
                                                <SelectItem value="Femoral" key={"Femoral"}>Trapecio</SelectItem>
                                                <SelectItem value="Cuadriceps" key={"Cuadriceps"}>Cuádriceps</SelectItem>
                                                <SelectItem value="Aductores " key={"Aductores"}>Aductores</SelectItem>
                                                <SelectItem value="Gemelo" key={"Gemelo"}>Gemelo</SelectItem>
                                                <SelectItem value="Gemelo" key={"Soleo"}>Sóleo</SelectItem>
                                                <SelectItem value="Deltoides" key={"Deltoides"}>Deltoides</SelectItem>


                                            </Select>



                                            <Select
                                                name="secundario"
                                                value={secundario}
                                                onSelectionChange={(value) => handleInputChange(value, 'secundario')}
                                                placeholder="Músculo secundario"
                                                labelPlacement="outside"
                                                startContent={
                                                    <GiMuscleUp />
                                                }
                                            >
                                                <SelectItem value="Pecho" key={"Pecho"}>Pecho</SelectItem>
                                                <SelectItem value="Espalda" key={"Espalda"}>Espalda</SelectItem>
                                                <SelectItem value="Hombros" key={"Hombros"}>Hombros</SelectItem>
                                                <SelectItem value="Biceps" key={"Biceps"}>Biceps</SelectItem>
                                                <SelectItem value="Triceps" key={"Triceps"}>Triceps</SelectItem>
                                                <SelectItem value="Piernas" key={"Piernas"}>Piernas</SelectItem>
                                                <SelectItem value="Gluteos" key={"Gluteos"}>Gluteos</SelectItem>
                                                <SelectItem value="Abdominales" key={"Abdominales"}>Abdominales</SelectItem>
                                                <SelectItem value="Trapecio" key={"Trapecio"}>Trapecio</SelectItem>
                                                <SelectItem value="Femoral" key={"Femoral"}>Trapecio</SelectItem>
                                                <SelectItem value="Cuadriceps" key={"Cuadriceps"}>Cuádriceps</SelectItem>
                                                <SelectItem value="Aductores " key={"Aductores"}>Aductores</SelectItem>
                                                <SelectItem value="Gemelo" key={"Gemelo"}>Gemelo</SelectItem>
                                                <SelectItem value="Gemelo" key={"Soleo"}>Sóleo</SelectItem>
                                                <SelectItem value="Deltoides" key={"Deltoides"}>Deltoides</SelectItem>
                                            </Select>
                                            <Input
                                                type="text"
                                                name="url"
                                                value={url}
                                                onChange={handleInputChange}
                                                placeholder="URL del video"
                                                labelPlacement="outside"
                                                startContent={
                                                    <FaVideo />
                                                }
                                            />


                                        </>

                                    )
                                }
                                {
                                    tipoModal === 'edit' && ejercicioEdit && (
                                        <>
                                            <Input
                                                type="text"
                                                name="nombre"
                                                value={ejercicioEdit.nombre}
                                                onChange={(e) => setEjercicioEdit({ ...ejercicioEdit, nombre: e.target.value })}
                                                placeholder="Nombre"
                                                labelPlacement="outside"
                                                startContent={
                                                    <BsFillPencilFill />
                                                }
                                            />
                                            <Input
                                                type="text"
                                                name="descripcion"
                                                value={ejercicioEdit.descripcion}
                                                onChange={(e) => setEjercicioEdit({ ...ejercicioEdit, descripcion: e.target.value })}
                                                placeholder="Descripción"
                                                labelPlacement="outside"
                                                startContent={
                                                    <CiTextAlignCenter />
                                                }
                                            />
                                            <Select
                                                name="principal"
                                                defaultSelectedKeys={[ejercicioEdit.principal]}
                                                // onSelectionChange={(value) => handleInputChange(value, 'principal')}
                                                onSelectionChange={(value) => setEjercicioEdit({ ...ejercicioEdit, principal: value.currentKey })}
                                                placeholder="Músculo principal"
                                                labelPlacement="outside"
                                                startContent={
                                                    <GiMuscleUp />
                                                }
                                            >
                                                <SelectItem value="Pecho" key={"Pecho"}>Pecho</SelectItem>
                                                <SelectItem value="Espalda" key={"Espalda"}>Espalda</SelectItem>
                                                <SelectItem value="Hombros" key={"Hombros"}>Hombros</SelectItem>
                                                <SelectItem value="Biceps" key={"Biceps"}>Biceps</SelectItem>
                                                <SelectItem value="Triceps" key={"Triceps"}>Triceps</SelectItem>
                                                <SelectItem value="Piernas" key={"Piernas"}>Piernas</SelectItem>
                                                <SelectItem value="Gluteos" key={"Gluteos"}>Gluteos</SelectItem>
                                                <SelectItem value="Abdominales" key={"Abdominales"}>Abdominales</SelectItem>
                                                <SelectItem value="Trapecio" key={"Trapecio"}>Trapecio</SelectItem>
                                                <SelectItem value="Femoral" key={"Femoral"}>Trapecio</SelectItem>
                                                <SelectItem value="Cuadriceps" key={"Cuadriceps"}>Cuádriceps</SelectItem>
                                                <SelectItem value="Aductores " key={"Aductores"}>Aductores</SelectItem>
                                                <SelectItem value="Gemelo" key={"Gemelo"}>Gemelo</SelectItem>
                                                <SelectItem value="Gemelo" key={"Soleo"}>Sóleo</SelectItem>
                                                <SelectItem value="Deltoides" key={"Deltoides"}>Deltoides</SelectItem>

                                            </Select>

                                            <Select
                                                name="secundario"
                                                value={ejercicioEdit.secundario}
                                                selectedKeys={[ejercicioEdit.secundario]}
                                                //onSelectionChange={(value) => handleInputChange(value, 'secundario')}
                                                onSelectionChange={(value) => setEjercicioEdit({ ...ejercicioEdit, secundario: value.currentKey })}
                                                placeholder="Músculo secundario"
                                                labelPlacement="outside"
                                                startContent={
                                                    <GiMuscleUp />
                                                }
                                            >
                                                <SelectItem value="Pecho" key={"Pecho"}>Pecho</SelectItem>
                                                <SelectItem value="Espalda" key={"Espalda"}>Espalda</SelectItem>
                                                <SelectItem value="Hombros" key={"Hombros"}>Hombros</SelectItem>
                                                <SelectItem value="Biceps" key={"Biceps"}>Biceps</SelectItem>
                                                <SelectItem value="Triceps" key={"Triceps"}>Triceps</SelectItem>
                                                <SelectItem value="Piernas" key={"Piernas"}>Piernas</SelectItem>
                                                <SelectItem value="Gluteos" key={"Gluteos"}>Gluteos</SelectItem>
                                                <SelectItem value="Abdominales" key={"Abdominales"}>Abdominales</SelectItem>
                                                <SelectItem value="Trapecio" key={"Trapecio"}>Trapecio</SelectItem>
                                                <SelectItem value="Femoral" key={"Femoral"}>Trapecio</SelectItem>
                                                <SelectItem value="Cuadriceps" key={"Cuadriceps"}>Cuádriceps</SelectItem>
                                                <SelectItem value="Aductores " key={"Aductores"}>Aductores</SelectItem>
                                                <SelectItem value="Gemelo" key={"Gemelo"}>Gemelo</SelectItem>
                                                <SelectItem value="Gemelo" key={"Soleo"}>Sóleo</SelectItem>
                                                <SelectItem value="Deltoides" key={"Deltoides"}>Deltoides</SelectItem>

                                            </Select>
                                            <Input
                                                type="text"
                                                name="url"
                                                value={ejercicioEdit.url}
                                                onChange={(e) => setEjercicioEdit({ ...ejercicioEdit, url: e.target.value })}
                                                placeholder="URL del video"
                                                labelPlacement="outside"
                                                startContent={
                                                    <FaVideo />
                                                }
                                            />



                                        </>

                                    )



                                }



                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={handleClose}>
                                    Cerrar
                                </Button>
                                <Button color="primary" onPress={handleGuardar}>
                                    Guardar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}