import { Card, CardBody } from '@nextui-org/react';
import { Switch } from '@nextui-org/react';

const NotificacionesUser = ({ data }) => {

    return (
        <Card className="w-full px-9 py-4">

            <CardBody>
                {
                    data.length > 0 && (


                        <>
                            <p className="text-xs font-semibold text-gray-500">Información notifiaciones User</p>

                            <div className='mt-2 flex items-center h-full'>

                                <Switch
                                    defaultSelected

                                    color="success"
                                    size="md"
                                    isSelected={data[0].noti === 1}
                                />
                                <h2 className="text-sm">Quiere recibir notificaciones?</h2>
                            </div>
                            <p className="mt-4 text-xs font-semibold text-gray-500">Que tipo de notificaciones quiere recibir?</p>
                            <div className='mt-2 flex items-center h-full justify-between'>

                                <div className=' flex items-center h-full'>
                                    <Switch
                                        defaultSelected

                                        color="success"
                                        size="sm"
                                        isSelected={data[0].noti === 1}
                                    />
                                    <h2 className="text-xs">Revisiones</h2>
                                </div>
                                <div className='flex items-center h-full'>
                                    <Switch
                                        defaultSelected

                                        color="success"
                                        size="sm"
                                        isSelected={data[0].noti === 1}
                                    />
                                    <h2 className="text-xs">Arxivos</h2>
                                </div>

                            </div>

                        </>
                    )}

                {!data && <p className="text-center ">El usuario no ha completado el formulario inicial</p>}

            </CardBody>

        </Card>
    )
}
export default NotificacionesUser