
import Tools from "../tools/tools";

const UserService = {



  GetConsejo: async (handleUnauthenticated) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/consejo`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
      });
      if (response.ok) {
        const consejoData = await response.json();
        return consejoData;
      } else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        UserService.CerrarSession();
      } else {
        throw new Error('Error al obtener el consejo');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      // throw new Error(error.message);
    }
  },

  GetRecetas: async (handleUnauthenticated) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/recetas`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
      });
      if (response.ok) {
        const recetasData = await response.json();
        console.log("recetasData", recetasData);  
        return recetasData;
      } else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        UserService.CerrarSession();
      } else {
        throw new Error('Error al obtener las recetas');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      // throw new Error(error.message);
    }
  },
  

  GetCaducidad: async (handleUnauthenticated) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/caducidad`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
      });
      if (response.ok) {
        const caducidadData = await response.json();
        return caducidadData;
      }
      else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        UserService.CerrarSession();
      }
      else {
        console.log(response.status);
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      // throw new Error(error.message);
    }
  },
  GetRevisionesBBDD: async (handleUnauthenticated) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/revisiones`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
      });
      if (response.ok) {
        const revisionesData = await response.json();
        return revisionesData;
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      // throw new Error(error.message);
    }
  },


  GetRevisiones: async (handleUnauthenticated) => {

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/revisiones`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
      });
      if (response.ok) {
        const revisionesData = await response.json();

        const fechaCaducidadSinTratar = await UserService.GetCaducidad()

        let tocaRevision = false;

        const fechaDeCaducidad = Tools.sqlToJsDate(fechaCaducidadSinTratar.caducidad);


        //*Trataremos los datos para que devuelva los dias y si corresponde revision o no
        // Calcular la fecha de la próxima revisión después de la última revisión existente
        let fechaProximaRevision = new Date();


        if (revisionesData.length > 0) {
          const ultimaRevision = revisionesData[revisionesData.length - 1];
          fechaProximaRevision = new Date(ultimaRevision.fecha);
          fechaProximaRevision.setDate(fechaProximaRevision.getDate() + 7); //avanzamos una semana
        }

        // Generar revisiones futuras a partir de la semana siguiente de la última revisión
        const revisionesFuturas = [];
        while (fechaProximaRevision < fechaDeCaducidad) {
          revisionesFuturas.push({ fecha: new Date(fechaProximaRevision) });
          fechaProximaRevision.setDate(fechaProximaRevision.getDate() + 7); // Avanzar una semana
        }

        // Revisamos si hay alguna revisión futura que ya haya pasado
        const currentDate = new Date();
        for (let i = 0; i < revisionesFuturas.length; i++) {
          if (revisionesFuturas[i].fecha < currentDate) {
            tocaRevision = true;
            break;
          }
        }

        // Combinar revisiones existentes y futuras
        const revisiones = [...revisionesData, ...revisionesFuturas];


        return {
          revisiones,
          tocaRevision,
        }

      }
      else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        UserService.CerrarSession();
      }
      else {
        console.log(response.status);
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      // throw new Error(error.message);
    }
  },
  getArchivos: async (handleUnauthenticated, handleError, handleSuccess) => {

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/getArchivos`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
      });
      if (response.ok) {
        const archivosData = await response.json();
        handleSuccess(archivosData.message);
        return archivosData;
      }
      else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        UserService.CerrarSession();
      }
      else {
        handleError('Error al obtener los archivos');
      }

    } catch (error) {
      handleError('Error al obtener los archivos');
      // throw new Error(error.message);
    }

  },

  EnviarFormularioSemanal: async (formulario, handleUnauthenticated, handleError, handleSuccess) => {

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/formulariosemanal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
        body: JSON.stringify(formulario),
      });
      if (response.ok) {
        const consejoData = await response.json();
        handleSuccess(consejoData.message);
        return { ok: true };
      } else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        UserService.CerrarSession();
      } else {
        handleError('Error al enviar el formulario, revise los datos');
      }
    }
    catch (error) {
      console.error('Error de red:', error.message);
      //throw new Error(error.message);
    }


  },

  EnviarFormularioInicial: async (formulario, handleUnauthenticated, handleError, handleSuccess) => {

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/formularioinicial`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
        body: JSON.stringify(formulario),
      });
      if (response.ok) {
        const consejoData = await response.json();
        handleSuccess(consejoData.message);
        return { ok: true };
      } else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        UserService.CerrarSession();
      } else {
        handleError('Error al enviar el formulario, revise los datos');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      //throw new Error(error.message);
    }
  },

  EnviarArchivo: async (archivo, handleUnauthenticated, handleError, handleSuccess) => {

    try {
      // Create a new instance of FormData
      const formData = new FormData();
      // Append the file to the formData
      formData.append('archivo', archivo['file']);
      formData.append('descripcion', archivo['descripcion']);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/archivo`, {
        method: 'POST',

        headers: {
          Token: localStorage.getItem('40coaching_token'),
        },
        body: formData,

      });
      if (response.ok) {
        const resultado = await response.json();
        handleSuccess(resultado.message);
        return { ok: true };
      } else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        UserService.CerrarSession();
      } else {
        handleError('Error al enviar el archivo, revise los datos');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      throw new Error(error.message);
    }
  },
  CerrarSession: () => {
    localStorage.removeItem('40coaching_token');
    localStorage.removeItem('40coaching_user');
    window.location.href = '/login';
  },
  getInfoPerfil: async (handleUnauthenticated) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/getInfo`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
      });
      if (response.ok) {
        const infoData = await response.json();
        return infoData;
      } else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        UserService.CerrarSession();
      } else {
        //throw new Error('Error al obtener la información');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      // throw new Error(error.message);
    }
  },
  getGrasa: async (handleUnauthenticated) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/getGrasa`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
      });
      if (response.ok) {
        const grasaData = await response.json();
        return grasaData;
      } else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        UserService.CerrarSession();
      } else {
        //throw new Error('Error al obtener la información');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      // throw new Error(error.message);
    }
  },

  getIMC: async (handleUnauthenticated) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/getImc`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
      });
      if (response.ok) {
        const imcData = await response.json();
        return imcData;
      } else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        UserService.CerrarSession();
      } else {
        //throw new Error('Error al obtener la información');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      // throw new Error(error.message);
    }
  },
  getInfoNotificaciones: async (handleUnauthenticated) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/getNotificacionesInfo`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
      });
      if (response.ok) {
        const infoData = await response.json();
        return infoData;
      } else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        UserService.CerrarSession();
      } else {
        //throw new Error('Error al obtener la información');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      // throw new Error(error.message);
    }
  },
  setInfoNotificaciones: async (notificaciones, handleUnauthenticated, handleError, handleSuccess) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/setNotificacionesInfo`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
        body: JSON.stringify(notificaciones),
      });
      if (response.ok) {
        const infoData = await response.json();
        handleSuccess(infoData.message);
        return infoData;
      } else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        // UserService.CerrarSession();
      } else {
        handleError('Error al actualizar la información');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      // throw new Error(error.message);
    }
  },
  GetPasos: async (handleUnauthenticated) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/pasos`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
      });
      if (response.ok) {
        const pasosData = await response.json();
        return pasosData.pasos;
      } else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        UserService.CerrarSession();
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      // throw new Error(error.message);
    }
  },
  GetS: async (handleUnauthenticated) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/s`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem('40coaching_token'),
        },
      });
      if (response.ok) {
        const sData = await response.json();
        return sData.s;
      } else if (response.status === 401) {
        handleUnauthenticated('Tu sesión ha expirado, hemos cerrado tu sesión');
        UserService.CerrarSession();
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      // throw new Error(error.message);
    }
  }

};

export default UserService;
