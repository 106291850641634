import { useEffect } from 'react'
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { useMessageContext } from '../../../contexts/MessageContext';
import { Button } from '@nextui-org/react';
import { useState } from 'react';
import AdminService from '../../../services/AdminService'
import AuthService from '../../../services/AuthService';


const TablaEjercicios = ({ handleRefresh, refresh, handleChangeAccio}) => {

    const [ejercicios, setEjercicios] = useState([])
    const { handleError } = useMessageContext();
    useEffect(() => {
        fetchEjercicios();
        // eslint-disable-next-line 
    }, [refresh])

    const fetchEjercicios = async () => {

        if (await AuthService.validateToken(handleError)) {
            const ejerciciosData = await AdminService.getEjercicios();
            setEjercicios(ejerciciosData);
        }

    }

    const handleDelete = async (id) => {
        const response = await AdminService.delEjercicio(id);
        if (!response.error) {
            fetchEjercicios();
        }
    }


    return (
        <Table>
            <TableHeader>
                <TableColumn span={2}>ID</TableColumn>
                <TableColumn span={2}>Nombre del ejercicio</TableColumn>
                <TableColumn span={2}>Descripción</TableColumn>
                <TableColumn span={2}>Editar ejercicio</TableColumn>
                <TableColumn span={2}>Eliminar ejercicio</TableColumn>
            </TableHeader>
            <TableBody>
                {ejercicios.map((ejercicio) => (
                    <TableRow key={ejercicio.id}>
                        <TableCell>{ejercicio.id}</TableCell>
                        <TableCell>{ejercicio.nombre}</TableCell>
                        <TableCell>{ejercicio.descripcion}</TableCell>
                        <TableCell><Button color='warning'
                            onClick={() => handleChangeAccio("edit",ejercicio.id ) }
                        >
                            
                            Editar</Button></TableCell>
                        <TableCell><Button color='danger'
                            onClick={() => {
                                if (window.confirm('¿Estás seguro de que quieres eliminar este ejercicio?')) {
                                    handleDelete(ejercicio.id);
                                }
                            }}
                        >Eliminar</Button></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
export default TablaEjercicios
