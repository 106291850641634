import { useEffect } from 'react'
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { useMessageContext } from '../../../contexts/MessageContext';
import { Button } from '@nextui-org/react';
import { useState } from 'react';
import AdminService from '../../../services/AdminService'
import AuthService from '../../../services/AuthService';
import { Avatar } from '@nextui-org/react';


const TablaRecetas = ({ handleRefresh, refresh, handleChangeAccio}) => {

    
    const [recetas, setRecetas] = useState([])
    const { handleError } = useMessageContext();
    useEffect(() => {
        fetchRecetas();
        // eslint-disable-next-line 
    }, [refresh])

    const fetchRecetas = async () => {

        if (await AuthService.validateToken(handleError)) {
            const recetasData = await AdminService.getRecetas();
     
            setRecetas(recetasData);
        }

    }

    const handleDelete = async (id) => {
        if (await AuthService.validateToken(handleError)) {
            const res = await AdminService.deleteReceta(id, handleError);
            if (res) {
                handleRefresh();
            }
        }
       
    }


    return (
        <Table>
            <TableHeader>
                <TableColumn span={2}>Imagen</TableColumn>
                <TableColumn span={2}>Titulo</TableColumn>
                <TableColumn span={2}>PDF</TableColumn>
                <TableColumn span={2}>Eliminar receta</TableColumn>
            </TableHeader>
            <TableBody>
                {recetas.map((receta) => (
                    <TableRow key={receta.id}>
                        <TableCell><Avatar src={receta.img} /></TableCell>
                        <TableCell>{receta.titulo}</TableCell>
                        <TableCell>
                                <Button color='success' onClick={() => window.open(receta.url, '_blank')}>Ver PDF</Button>
                           </TableCell>
                     
                        <TableCell><Button color='danger'
                            onClick={() => {
                                if (window.confirm('¿Estás seguro de que quieres eliminar esta receta?')) {
                                    handleDelete(receta.id);
                                }
                            }}
                        >Eliminar</Button></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
export default TablaRecetas
