import { Card, CardBody } from "@nextui-org/react";
import { Link as LinkR } from "react-router-dom";
const AvisoRevision = () => {
  return (
    <Card className="bg-warning-300 mb-2">
      <CardBody>
        <p className="text-center text-small">⚠️Tienes una revisión pendiente.
          <LinkR to="/revision-semanal">
        
            <p className="text-small text-primary-500">
            Haz clic aquí para revisar ahora.
            </p>
        </LinkR>
      </p>
    </CardBody>
      </Card >
    )
}
export default AvisoRevision