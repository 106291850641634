
const EntrenoService = {
    getEntrenos: async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/entreno/entreno/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Token: localStorage.getItem('40coaching_token'),
                },
            });
            if (response.ok) {
                const entrenosData = await response.json();
                return entrenosData;
            }
            else if (response.status === 401) {
                console.log(response.status);
            }
            else {
                console.log(response.status);
            }
        } catch (error) {
            console.error('Error de red:', error.message);
            // throw new Error(error.message);



        }
    },
    getEntrenosRealizados: async () => {
        let token = localStorage.getItem('40coaching_token');
        if (token.startsWith('"') && token.endsWith('"')) {
            token = token.slice(1, -1);
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/entreno/entrenosrealizados`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Token: token,
                },
            });
            if (response.ok) {
                const entrenosData = await response.json();
                console.log(entrenosData);
                return entrenosData;
            }
            else if (response.status === 401) {
                console.log(response.status);
            }
            else {
                console.log(response.status);
            }
        } catch (error) {
            console.error('Error de red:', error.message);
            // throw new Error(error.message);
        }
    },


    finalizarEntreno: async (id, ejerciciosRealizados,tipo) => {
        let token = localStorage.getItem('40coaching_token');
        if (token.startsWith('"') && token.endsWith('"')) {
            token = token.slice(1, -1);
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/entreno/finalizarentreno/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Token: token,
                },
                body: JSON.stringify({ ejerciciosRealizados, tipo }) 


            });
            if (response.ok) {
                const entrenosData = await response.json();
                return entrenosData;
            }
            else if (response.status === 401) {
                console.log(response.status);
            }
            else {
                console.log(response.status);
            }
        } catch (error) {
            console.error('Error de red:', error.message);
            // throw new Error(error.message);
        }
    },
    getListaEntrenos: async () => {
        let token = localStorage.getItem('40coaching_token');
        if (token.startsWith('"') && token.endsWith('"')) {
            token = token.slice(1, -1);
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/entreno/listaentrenos`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Token: token,
                },
            });
            if (response.ok) {
                const entrenosData = await response.json();
                return entrenosData;
            }
            else if (response.status === 401) {
                console.log(response.status);
            }
            else {
                console.log(response.status);
            }
        } catch (error) {
            console.error('Error de red:', error.message);
            // throw new Error(error.message);
        }
    }

}
export default EntrenoService;