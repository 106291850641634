import { Card, CardBody, CardHeader, Button, Tooltip } from '@nextui-org/react';
import { FaGear } from "react-icons/fa6";
import { FaBell } from "react-icons/fa";
import { FaChartPie } from "react-icons/fa";
import { PiBowlFoodFill } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import { FaCloudUploadAlt } from "react-icons/fa";

const Acciones = () => {
    const navigate = useNavigate();
    return (
        <Card className="w-full px-9 py-4">
            <CardHeader className="flex flex-col items-center">
                <h2
                    className="text-small font-medium text-default-500 text-center"
                >Acciones Rapidas</h2>
            </CardHeader>
            <CardBody>
                <div className="flex items-center justify-center gap-4">
                    <Tooltip showArrow={true} content="Configuración de la APP">
                        <Button color="warning" auto shadow className="p-3 text-lg min-w-fit rounded-full float-right">
                            <FaGear />
                        </Button>
                    </Tooltip>
                    <Tooltip showArrow={true} content="Configuración de las notificaciones">
                        <Button color="warning" auto shadow className="p-3 text-lg min-w-fit rounded-full float-right">
                            <FaBell />
                        </Button>
                    </Tooltip>
                    <Tooltip showArrow={true} content="Configuración de Graficos">
                        <Button color="warning" auto shadow className="p-3 text-lg min-w-fit rounded-full float-right">
                            <FaChartPie />
                        </Button>
                    </Tooltip>
                    <Tooltip showArrow={true} content="Subir nueva receta">
                        <Button color="warning" auto shadow className="p-3 text-lg min-w-fit rounded-full float-right" onClick={() => navigate('/recetas')}>
                            <PiBowlFoodFill />
                        </Button>
                    </Tooltip>
                    <Tooltip showArrow={true} content="Subir imagen">
                    <Button color="warning" auto shadow className="p-3 text-lg min-w-fit rounded-full float-right" onClick={() => navigate('/uploads')}>
                        <FaCloudUploadAlt />
                    </Button>
                    </Tooltip>

                </div>
            </CardBody>

        </Card>
    )
}
export default Acciones