import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';
import { useEffect, useRef, useState } from "react";
import Tools from '../../../tools/tools';
import UserService from "../../../services/UsersService";
import { Card, CardBody } from '@nextui-org/react';
import { Spinner } from "@nextui-org/react";

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale);

const Massa = () => {
    const chartRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [massa_magra, setMassa_magra] = useState(null);
    const [massa_grasa, setMassa_grasa] = useState(null);




    const generarData = (revisiones) => {


        const data = revisiones.map((revision) => {
            return revision.porcentaje_grasa


        })
        setLoading(false)
        return data
    }

    // Función para extraer las fechas de las revisiones
    // Función para extraer las fechas de las revisiones y formatearlas en DD/MM/YYYY
    const extractFechas = (revisiones) => {
        const fechas = revisiones.map((revision) => {
            const fechaJs = Tools.sqlToJsDate(revision.fecha);
            const dia = fechaJs.getDate();
            const mes = fechaJs.getMonth() + 1; // Sumar 1 porque los meses se indexan desde 0
            //const año = fechaJs.getFullYear();

            // Formatear la fecha en el estilo DD/MM/YYYY
            const fechaFormateada = `${dia}/${mes}`;

            return fechaFormateada;
        });

        return fechas;
    };


    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }
        const fetchData = async () => {
            let config;
            try {
                const response = await UserService.getGrasa();
                if (response) {
                    
                    setMassa_grasa(response[response.length - 1].masa_grasa);
                    setMassa_magra(response[response.length - 1].masa_magra);
                    if (chartRef.current) {
                        chartRef.current.destroy();
                    }

                    const fechas = extractFechas(response);
                    let dataGrasa = generarData(response);
                    let dataMagra = response.map((revision) => revision.masa_magra); // Assuming this is how you get the data for massa magra
                    let minData = Math.min(...dataGrasa, ...dataMagra) - 3;
                    let maxData = Math.max(...dataGrasa, ...dataMagra) + 3;

                    config = {
                        type: "line",
                        data: {
                            labels: fechas,
                            datasets: [
                                {
                                    label: "% Grasa",
                                    backgroundColor: "rgb(255, 99, 132)",
                                    borderColor: "#e69c1a",
                                    data: dataGrasa,
                                },
                                {
                                    label: "Massa Magra",
                                    backgroundColor: "rgb(75, 192, 192)",
                                    borderColor: "#1a8fe6",
                                    data: dataMagra,
                                },
                            ],
                        },
                        options: {
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    grid: {
                                        display: false,
                                    },
                                    suggestedMin: minData,
                                    suggestedMax: maxData
                                },
                            },
                        },
                    };
                    let ctx3 = document.getElementById("myChart3").getContext("2d");
                    chartRef.current = new Chart(ctx3, config);
                }
            }
            catch (error) {
                console.error(error);
            }
          
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [massa_grasa, massa_magra]);



    return (
        loading ?
            <Card
                className='mt-2 mb-7'
            >
                <CardBody>
                    <Spinner size="lg" />
                </CardBody>
            </Card>
            :
            <Card
                className='mt-2 mb-7'
            >
                <CardBody>
                    <p className='text-xs text-gray-400 mb-2 '>
                        Musculo  vs  Grasa
                        <br></br>
                        <span className='text-xl text-default-800'>
                            {massa_magra.toFixed(2)
                            } <span className='text-xs text-gray-300'> vs</span> {
                                massa_grasa.toFixed(2)
                            }
                        </span>

                    </p>
                    <div className="charts bg-default-50 dark:bg-default-50 h-full w-full flex items-center justify-center rounded-xl">
                      <canvas id="myChart3" style={{ width: "100%", height: "100%"}}></canvas>
                    </div>
                </CardBody>
            </Card>
    );
}

export default Massa;