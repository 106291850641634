import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';
import { useEffect, useRef } from "react";
import Tools from '../../../tools/tools';


Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale);

const Peso = (props) => {
    const chartRef = useRef(null);



    const generarData = () => {
        const data = props.props.revisiones.map((revision) => {
            return revision.peso

        })

        return data
    }

    // Función para extraer las fechas de las revisiones
    // Función para extraer las fechas de las revisiones y formatearlas en DD/MM/YYYY
    const extractFechas = () => {
        const fechas = props.props.revisiones.map((revision) => {
            const fechaJs = Tools.sqlToJsDate(revision.fecha);
            const dia = fechaJs.getDate();
            const mes = fechaJs.getMonth() + 1; // Sumar 1 porque los meses se indexan desde 0
            //const año = fechaJs.getFullYear();

            // Formatear la fecha en el estilo DD/MM/YYYY
            const fechaFormateada = `${dia}/${mes}`;

            return fechaFormateada;
        });

        return fechas;
    };


    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }
        // Añadir las fechas a las etiquetas
        const fechas = extractFechas();

        let data = generarData();
        let minData = Math.min(...data) - 3;
        let maxData = Math.max(...data) + 3;

        // Añadir las fechas a las etiquetas
        let config = {
            type: "line",
            data: {
                labels: fechas,
                datasets: [
                    {
                        label: "Peso",
                        backgroundColor: "rgb(255, 99, 132)",
                        borderColor: "#e69c1a",
                        data: data,
                    },
                ],
            },
            options: {
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                        suggestedMin: minData,
                        suggestedMax: maxData

                    },
                },
            },
        };

        let ctx = document.getElementById("myChart").getContext("2d");
        chartRef.current = new Chart(ctx, config);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fechas]);


    return (
        <div className=' bg-default-50 dark:bg-default-50 h-full p-4 rounded-xl'>
            <p className='text-xs text-gray-400 mb-2 '>
                Peso
            </p>
            <div className="charts  w-full flex items-center justify-center rounded-xl">
                <canvas id="myChart" style={{ width: "100%", height: "100%" }}></canvas>
            </div>
        </div>
    )
}

export default Peso;