
import Header from "../components/Header";
import { Tabs, Tab } from "@nextui-org/react";
import { useState } from 'react';
import Notificaciones from "../components/configuracion/Notificaciones";
import Privacidad from "../components/configuracion/Privacidad";


const Configuracio = () => {
    const [selected, setSelected] = useState("notificaciones");
    const handleChange = (value) => {
        setSelected(value);
    }

    return (
        <>
            <Header />
            <div className="contaier p-4">
                <Tabs
                    key='bordered'
                    variant='bordered'
                    aria-label="Tabs variants"
                    className="flex justify-center mt-4"
                    selectedKey={selected}
                    onSelectionChange={handleChange}
                >
                    <Tab key="notificaciones" title="Notificaciones" />
                    <Tab key="privacidad" title="Privacidad" />
                </Tabs>
            </div>

            {selected === "notificaciones" && <Notificaciones />}
            {selected === "privacidad" && <Privacidad />}


        </>
    )
}
export default Configuracio