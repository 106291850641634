import { Card, CardHeader, CardBody, CardFooter, Divider, Button } from "@nextui-org/react";

import { IoAddOutline } from "react-icons/io5";
import { LiaDumbbellSolid } from "react-icons/lia";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminService from '../../../services/AdminService'
import AuthService from "../../../services/AuthService";
import { useMessageContext } from '../../../contexts/MessageContext';

const Entrenos = () => {

    const navigate = useNavigate();
    const [cantidadEjercicios, setCantidadEjercicios] = useState(0);
    const { handleError } = useMessageContext();


    useEffect(() => {
        loadCantidadEjercicios();

        // eslint-disable-next-line
    }, [])

    const loadCantidadEjercicios = async () => {
        if(await AuthService.validateToken(handleError)){
            const cantidadEjercicios = await AdminService.cantidadEjercicios();
            if(cantidadEjercicios){
                setCantidadEjercicios(cantidadEjercicios);
            }
        
        }
    }

    return (
        <div>
            <Card className="w-full min-h-[230px]">
                <CardHeader className="flex gap-3">
                    <LiaDumbbellSolid />
                    <div className="flex flex-col">
                        <p className="text-md">Ejercicios</p>
                        <p className="text-small text-default-500">Administra los entrenos</p>
                    </div>
                </CardHeader>
                <Divider />
                <CardBody>
                    <div className="flex flex-col items-center">
                        <h2 className="text-4xl font-semibold">{cantidadEjercicios}</h2>
                        <span className="text-default-500 text-medium font-light">Ejercicios</span>
                    </div>
                </CardBody>
                <Divider />
                <CardFooter className="flex justify-center gap-2">
                    <Button color="primary" auto shadow className="w-full" onClick={() => navigate('/ejercicios')}>
                        Administrar
                    </Button>
                    <Button color="warning" auto shadow className="p-3 text-lg min-w-fit rounded-full float-right" onClick={() => navigate('/ejercicios')}>
                        <IoAddOutline />
                    </Button>
                </CardFooter>
            </Card>
        </div>
    )
}
export default Entrenos