import { Card, CardBody } from "@nextui-org/react";
import { Button } from "@nextui-org/react";
import { PiBowlFood } from "react-icons/pi";
import LogoColores from "../../assets/logo_dieta_colores.png";
import { useNavigate } from 'react-router-dom';


const Dieta = () => {
    const navigate = useNavigate();
    return (

        <Card className="shadow-lg rounded-lg">
            <CardBody className="grid grid-cols-5 gap-4">
                <div className="col-span-3 flex items-center justify-center">
                  <img src={LogoColores} alt="logo" />
                </div>
                <div className="col-span-2 mt-2 flex align-center justify-center flex-col">
                    <p className="text-xs text-gray-400 mb-2 ">
                    Genera y registra tu  <span className="text-default-800">alimentación</span> 
                    </p>
                    
                    <Button
                       
                        size="small"
                        auto
                       color="warning"
                       onClick={() =>  navigate('/dieta')}
                    >
                        Mi dieta
                        <PiBowlFood className="ml-2" />
                    </Button>
                    
                </div>
            </CardBody>
        </Card>
    )
}
export default Dieta
