import { Modal, ModalContent, ModalHeader, ModalBody, useDisclosure } from "@nextui-org/react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import DietaService from "../../services/DietaService";
import { useState } from "react";
import { useEffect } from "react";
import Tools from "../../tools/tools";

const ModalAlimentos = ({ tipo, clearTipo }) => {
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const [alimentos, setAlimentos] = useState([]);
    const [tipoInfo, setTipoInfo] = useState(null);

    useEffect(() => {


        if (tipo) {
            getAlimentos(tipo);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipo]);

    const getAlimentos = async (tipo) => {
        setAlimentos(await DietaService.getAlimentos(tipo));
        setTipoInfo(Tools.getInfoAlimento(tipo));
        onOpen();
    }

    const handleClose = () => {
        onClose();
        clearTipo();
    }
    return (
        <Modal isOpen={isOpen} onOpenChange={onOpenChange} onClose={handleClose} placement="center" >
            <ModalContent >
                <ModalHeader className="flex flex-col gap-1">
                    {
                        tipoInfo && <h1 className="text-center text-2xl font-bold">{tipoInfo.info}</h1>
                    }
                </ModalHeader>
                <ModalBody>
                    <div style={{ maxHeight: '80vh', overflow: 'auto' }}>
                        <Table >
                            <TableHeader>
                                <TableColumn
                                    className={`text-white`}
                                    style={{ backgroundColor: tipoInfo?.color }}
                                >

                                    Alimento

                                </TableColumn>
                                <TableColumn
                                    className={`text-white`}
                                    style={{ backgroundColor: tipoInfo?.color }}
                                >
                                    Cantidad</TableColumn>
                            </TableHeader>
                            <TableBody>

                                {
                                    alimentos.map((alimento) => (
                                        <TableRow key={alimento.id}>
                                            <TableCell
                                                className="text-black font-normal"
                                                style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}
                                            >
                                                {alimento.nombre}</TableCell>
                                            <TableCell
                                                style={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}
                                            >{alimento.cantidad}</TableCell>
                                        </TableRow>
                                    ))
                                }

                            </TableBody>
                        </Table>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>


    )
}
export default ModalAlimentos
