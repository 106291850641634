
import Header from "../components/Header";
import ListadoFechas from "../components/dashboard/ListadoFechas";
import Consejo from "../components/dashboard/Consejo";
import Receta from "../components/dashboard/Reecta";
import ComparadorGraficos from "../components/dashboard/ComparadorGraficos";
import { useRevisionContext } from "../contexts/RevisionContext";
import AvisoRevision from "../components/dashboard/AvisoRevision";
import Camina from "../components/dashboard/Camina";
import Dieta from "../components/dashboard/Dieta";



const Dashboard = () => {

    const { revision } = useRevisionContext(); 

    return (
        <>
            <Header />
           
            <section className="container p-4 ">
                {
                    revision &&
                    <AvisoRevision />
                }
           
                <ListadoFechas />
                 <div className="mt-2 w-full h-full">
                    <Camina />
                </div>
                <div className="mt-2">
                    <Consejo />
                </div>
                <div className="mt-2 w-full h-full">
                    <Dieta />
                </div>
               
                <div className="mt-2 w-full h-full">
                    <ComparadorGraficos />
                </div>
              <div className="mt-2">
                <Receta />
            </div>
    

            </section>
        </>
    );
}
export default Dashboard;