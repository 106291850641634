import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import Login from '../views/Login';
import Dashboard from '../views/Dashboard';
import FormularioIncial from '../views/FormularioInicial';
import Entrno from '../views/Entreno';
import Entrenamiento from '../views/Entrenamiento';
import MisDocumentos from '../views/Misdocumentos';
import RevisionSemanal from '../views/RevisionSemanal';
import Perfil from '../views/Perfil';
import DashboardAdmin from '../views/admin/DashboardAdmin';
import Configuracio from '../views/Configuracio';
import Usuarios from '../views/admin/Usuarios';
import User from '../views/admin/User';
import Ejercicios from '../views/admin/Ejercicios';
import Dieta from '../views/Diesta';
import Alimentos from '../views/admin/Alimentos';
import Recetas from '../views/admin/Recetas';
import Uploads from '../views/admin/Uploads';
import ResetPassword from '../views/ResetPassword';
import { useEffect } from 'react';
import { useState } from 'react';
import React from 'react';
import { Card, CardHeader, Image, Divider, CardBody,CardFooter, Link } from '@nextui-org/react';
import logo from '../assets/logo.png';

const AppRoutes = () => {
    const { isAuthenticated, isAdministrator } = useAuthContext();
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
        const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

        setIsMobile(mobile);
    }, []);

    if (!isMobile && !isAdministrator && isAuthenticated) {
        return (
            <div className="flex items-center justify-center h-screen">
            <Card className="max-w-4xl w-full">
                <CardHeader className="flex gap-3">
                    <Image
                        alt="nextui logo"
                        height={40}
                        radius="sm"
                        src={logo}
                        width={40}
                    />
                    <div className="flex flex-col">
                        <p className="text-md">+40 Coaching</p>
                        <p className="text-small text-default-500">app.40pluscoaching.es</p>
                    </div>
                </CardHeader>
                <Divider />
                <CardBody className="flex" >
                    <div className="flex flex-col gap-2 w-full">
                        <h1 className="text-2xl  text-center">¡Ups! 🤷‍♂️</h1>
                        <p className="text-center text-gray-400">Lo siento, esta aplicación solo está disponible para dispositivos móviles.</p>
                        <p className="text-center text-gray-400">Por favor, visita la aplicación desde tu teléfono móvil.</p>
                    </div>
                </CardBody>
                <Divider />
                <CardFooter className='flex justify-center'>
                    <Link href="mailto:admin@40pluscoaching.es" underline>
                    Tienes problemas con la aplicación? Contáctanos 
                    </Link>
                </CardFooter>
            </Card>
        </div>
        );
    }

    return (
        <Routes>
            <Route path="/" element={isAdministrator ? <DashboardAdmin /> : <Dashboard />} />
            <Route path="/reset-password/:hash" element={isAuthenticated ? <Navigate to="/" /> : <ResetPassword />} />
            <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login />} />
            <Route path="/empecemos" element={<FormularioIncial />} />
            <Route path="/listadoentrenos" element={<Entrno />} />
            <Route path="/entrenamiento/:id" element={<Entrenamiento />} />
            <Route path="/documentos" element={<MisDocumentos />} />
            <Route path="/revision-semanal" element={<RevisionSemanal />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/perfil" element={<Perfil />} />
            <Route path="/dieta" element={<Dieta />} />
            <Route path="/configuracion" element={<Configuracio />} />
            <Route path="/usuarios" element={isAdministrator ? <Usuarios /> : <Dashboard />} />
            <Route path="/alimentos" element={isAdministrator ? <Alimentos /> : <Dashboard />} />
            <Route path="/ejercicios" element={isAdministrator ? <Ejercicios /> : <Dashboard />} />
            <Route path="/usuario/:id" element={isAdministrator ? <User /> : <Dashboard />} />
            <Route path="/uploads" element={isAdministrator ? <Uploads /> : <Navigate to="/" />} />
            <Route path="/recetas" element={isAdministrator ? <Recetas /> : <Navigate to="/" />} />
        </Routes>
    );
};

export default AppRoutes;