import { Card, CardBody } from "@nextui-org/react";
import { Button } from "@nextui-org/react";
import { FaWalking } from "react-icons/fa";
import { useState, useEffect } from "react";
import { useMessageContext } from '../../contexts/MessageContext';
import UsersService from "../../services/UsersService";
const Camina = () => {
    const [pasos, setPasos] = useState(0);
    const { handleUnauthenticated } = useMessageContext();

    useEffect(() => {
        UsersService.GetPasos(handleUnauthenticated).then((res) => {
            
            setPasos(res);
          });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }
        , []);
    return (

        <Card className="shadow-lg rounded-lg">
            <CardBody className="grid grid-cols-5 gap-4">
                <div className="col-span-1 flex items-center justify-center">
                    <FaWalking className="text-5xl text-default-800" />
                </div>
                <div className="col-span-4 mt-2">
                    <p className="text-xs text-gray-400 mb-2 ">
                        Recuerda caminar cada día al menos
                    </p>
                    <Button
                        color="warning"
                        size="small"
                        auto
                        className="w-full"

                    >
                        {pasos} pasos
                        
                    </Button>
                </div>
            </CardBody>
        </Card>
    )
}
export default Camina
