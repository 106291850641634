import React, { useEffect } from "react";
import Header from "../components/Header";
import { useAuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import FormularioInicialForm from "../components/FormularioInicialForm";


const FormularioInicial = () => {
    const { isAuthenticated } = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {

        if (!isAuthenticated) {
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);



    return (
        <>
            <Header />
            <section className="container p-4 ">
                <div className="mt-2">
                    <h1 className="text-xl font-semibold text-center">Formulario inicial</h1>
                    <FormularioInicialForm />

                </div>
            </section>
        </>
    );
};

export default FormularioInicial;
