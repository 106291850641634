import Header from "../components/Header";
import FormularioSemanal from "../components/FormularioSemanal";
const RevisionSemanal = () => {


    return (
        <>
            <Header />
            <div className="container p-4">
            <h1 className="text-xl font-semibold text-center">Revisión semanal</h1>
                <FormularioSemanal />
            </div>
        </>
    )
}
export default RevisionSemanal