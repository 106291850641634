import React, { useEffect, useState } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Input } from "@nextui-org/react";
import AdminService from '../../../services/AdminService'
import AuthService from '../../../services/AuthService';
import { useMessageContext } from '../../../contexts/MessageContext';
import { FileUploader } from "react-drag-drop-files";
import { useParams } from 'react-router-dom';
const fileTypes = ["JPG", "PNG", "GIF", "PDF"];



const ModalArchivos = ({ accion, close, id, handleRefresh }) => {
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const { handleError, handleSuccess } = useMessageContext();
    const [file, setFile] = useState(null);
    const [titulo, setTitulo] = useState(null);
    const [descripcion, setDescripcion] = useState(null);
    const params = useParams();
    const idUser = params.id;


    const handleChange = (file) => {
        setFile(file);
        setTitulo(file?.name.split('.').slice(0, -1).join('.'))

        onOpen();
    };

    useEffect(() => {
        if (accion) {
            onOpen();
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accion]);



    const handleClose = () => {
        onClose();
        close();
        setFile(null);
        setTitulo(null);
        setDescripcion(null);

    };


    const handleSave = async () => {

        if (await AuthService.validateToken(handleError)) {
            let arxivo = {
                titulo: titulo,
                descripcion: descripcion,
                file: file
            }

            const res = await AdminService.EnviarArchivo(idUser, arxivo);
            if (res) {
                handleSuccess('Archivo subido correctamente');
                handleRefresh();
                handleClose();
            }
        }

    }


    return (

        <>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} onClose={handleClose}>
                <ModalContent>


                    <ModalHeader className="flex justify-between items-center gap-2 ml-1 mr-1">
                        Añadir archivo al usuario
                    </ModalHeader>
                    <ModalBody>
                        <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                        {file && <p className='text-xs mt-0 text-primary-600'>Archivo seleccionado: {file.name}</p>}
                        <p className='text-xs mt-6 mb-0'>Añadele un titulo a tu documento</p>
                        <Input type="text" placeholder="Titulo" value={titulo} onChange={(e) => setTitulo(e.target.value)} />
                        <p className='text-xs'>Añadele una descripción a tu documento</p>
                        <Input type="text" placeholder="Descripción" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={handleClose}>Cerrar</Button>
                        <Button color="primary" onPress={handleSave}>Guardar</Button>
                    </ModalFooter>


                </ModalContent>
            </Modal>
        </>
    );
};

export default ModalArchivos;
