
const AuthService = {
  login: async (username, password) => {

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        const responseError = await response.json();
        throw new Error(responseError?.error || 'Error en la autenticación');
      }
    } catch (error) {

      console.error('Error de red:', error.message);
      throw new Error(error.message);
    }
  },
  validateToken: async (handleUnauthenticated) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/validate`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },
      });

      if (response.ok) {
        return true;
      } else {
        handleUnauthenticated("Tu sesión ha caducado, por favor vuelve a iniciar sesión.");

        //en los proximos 2 segundos sera redirigido a la pagina de login
        setTimeout(() => {
          //se elimina el token
          localStorage.removeItem('40coaching_token');
          window.location.href = '/login';
        }, 2000);


        return false;
      }
    } catch (error) {
      handleUnauthenticated();
      return false;
    }
  },
  resetPassword: async (resetPassword) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ resetPassword }),
      });

      if (response.ok) {
        return await response.json();
      } else {
        const responseError = await response.json();
        throw new Error(responseError?.error || 'Error al restablecer la contraseña');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      throw new Error(error.message);
    }
  },
  comprobarHash: async (hash) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/comprobar-hash`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ hash }),
      });

      if (response.ok) {
        return await response.json();
      } else {
        const responseError = await response.json();
        throw new Error(responseError?.error || 'Error al comprobar el hash');
      }
    } catch (error) {
        return {code:500, message: 'Error al comprobar el hash'};
    }
  },
  resetPasswordConHash: async ({ hash, password }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password-con-hash`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ hash, password }),
      });

      if (response.ok) {
        return await response.json();
      } else {
        const responseError = await response.json();
       return responseError;
      }
    } catch (error) {
      console.error('Error de red:', error.message);
     return error
    }
  }


};

export default AuthService;
