
import { useParams } from 'react-router-dom';
import Header from '../../components/admin/Header';
import InfoUser from '../../components/admin/user/InfoUser';
import DatosUser from '../../components/admin/user/DatosUser';
import NotificacionesUser from '../../components/admin/user/NotificacionesUser';
import { useEffect } from 'react';
import AdminService from '../../services/AdminService';
import AuthService from '../../services/AuthService';
import { useMessageContext } from '../../contexts/MessageContext';
import { useState } from 'react';
import FormularioInicialUser from '../../components/admin/user/FormularioInicialUser';
import GraficosUser from '../../components/admin/user/GraficosUsuario';
import ListNotificacionesUser from '../../components/admin/user/ListNotificacionesUser';
import ListDietaUser from '../../components/admin/user/ListadoDieta';
import GraficoPesoUsuario from './../../components/admin/user/GraficoPesoUsuario';
import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import ListadoEjercicios from '../../components/admin/user/ListadoEjercicios';
import ListaSuplementos from '../../components/admin/user/ListaSuplmenetos';
import ListadoArchivos from '../../components/admin/user/ListadoArchivos';
import PasosUsuario from '../../components/admin/user/PasosUsuario';


const User = () => {
    const { id } = useParams();
    const { handleError } = useMessageContext();
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        fetchUser();
        // eslint-disable-next-line
    }, []);

    const fetchUser = async () => {

        if (await AuthService.validateToken(handleError)) {
            const usersData = await AdminService.getUserInfo(id);
            console.log(usersData);
            setUserInfo(usersData);
        }

    }

    return (
        <>
            <Header />

            <Breadcrumbs isDisabled className='pt-14 pl-14 pb-2'>
                <BreadcrumbItem>Administración</BreadcrumbItem>
                <BreadcrumbItem>Usuario</BreadcrumbItem>
                <BreadcrumbItem>User ID: {id}</BreadcrumbItem>
            </Breadcrumbs>

            <div className="pl-14 pr-14 pb-14 mx-auto flex flex-col lg:flex-row gap-4">
                <div className=" w-5/6 ">
                    <div className="pt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" >
                        {
                            userInfo && (
                                <>
                                    <InfoUser data={userInfo} />
                                    <DatosUser data={userInfo.info} />
                                    <NotificacionesUser data={userInfo.notificaciones} />
                                </>
                            )
                        }

                    </div>
                    <div className="pt-4">
                        {
                            userInfo && (
                                <>
                                    <div >


                                        <FormularioInicialUser data={userInfo.formulario} />

                                    </div>
                                    <div className="pt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" >

                                        <div>
                                            <GraficoPesoUsuario props={userInfo} />

                                        </div>
                                        <div>
                                            <GraficosUser data={userInfo} />

                                        </div>
                                        <div className='w-full h-full'>
                                            <ListadoEjercicios />
                                        </div>

                                    </div>

                                </>
                            )
                        }
                        <div className="pt-4 grid gap-4" >
                            {
                                userInfo && (
                                    <>
                                        <div className='w-full h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
                                            <ListadoArchivos />
                                            <ListaSuplementos />
                                        </div>
                                    </>
                                )
                            }

                        </div>
                    </div>

                </div>
                <div className=" w-1/6 flex flex-col gap-4">
                    {
                        userInfo && (
                            <>
                                <ListNotificacionesUser />
                                <PasosUsuario />
                                <ListDietaUser id={id} />
                            </>
                        )
                    }

                </div>
            </div>
        </>
    )
}
export default User