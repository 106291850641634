import { useState, useEffect } from "react";
import { Input, Spacer, Button } from "@nextui-org/react";
import { FiHelpCircle } from 'react-icons/fi';
import UserService from "../services/UsersService";
import AuthService from "../services/AuthService";
import { useMessageContext } from '../contexts/MessageContext';


import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure
} from "@nextui-org/react";

const FormularioSemanal = () => {

    const { isOpen: isHelpOpen, onOpen: onHelpOpen, onOpenChange: onHelpOpenChange } = useDisclosure();
    const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onOpenChange: onConfirmOpenChange } = useDisclosure();
    const [idPregunta, setIdPregunta] = useState(null);
    const { handleUnauthenticated, handleError, handleSuccess } = useMessageContext();
    const [formData, setFormData] = useState(null);
    const [s, setS] = useState(null);
    useEffect(() => {
        async function fetchSexo() {
            if (await AuthService.validateToken(handleError)) {
                setS(await UserService.GetS());
            }
        }
        fetchSexo();

    }
        , []);




    const preguntas = [

        {
            id: 8,
            titulo: 'Peso en ayunas',
            descripcion: 'Peso en ayunas (en kg)',
            type: 'number'
        },
        {
            id: 11,
            titulo: 'Diámetro del cuello',
            descripcion: 'Medida en cm del diámetro de tu cuello',
            type: 'number'
        },
        {
            id: 12,
            titulo: 'Cintura a la altura del ombligo',
            descripcion: 'Medida en cm de tu cintura a la altura del ombligo (sin esconder barriga)',
            type: 'number'
        },
        {
            id: 13,
            titulo: 'Cadera a la altura de la parte más ancha',
            descripcion: 'Medida en cm de tu cadera a la altura de la parte más ancha',
            type: 'number',
            s: 2
        }

    ];




    const handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        let jsonData = {};
        for (let [key, value] of data.entries()) {
            jsonData[key] = value;
        }
        setFormData(jsonData);
        onConfirmOpen();
    };
    const handleConfirm = async () => {
        if (await AuthService.validateToken(handleError)) {
            UserService.EnviarFormularioSemanal(formData, handleUnauthenticated, handleError, handleSuccess)
                .then((result) => {
                    if (result.ok) {
                        window.location.href = '/';
                    }

                })
            onConfirmOpenChange(false);
        };
    }

    return (
        <form onSubmit={handleSubmit}>
            <Spacer y={4} />
            {
                preguntas
                    .filter(pregunta => pregunta.s === undefined || pregunta.s === s)
                    .map((pregunta) => {
                        return (
                            <div key={pregunta.id}>
                                <Spacer y={4} />
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input
                                        type={pregunta.type}
                                        label={pregunta.titulo}
                                        width="100%"
                                        name={`pregunta${pregunta.id}`}
                                    />
                                    <FiHelpCircle
                                        size="1.5em"
                                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                                        onClick={() => { onHelpOpen(); setIdPregunta(pregunta.id) }}
                                    />
                                </div>
                            </div>
                        )
                    })
            }
            <Spacer y={4} />
            <Button auto type="secondary" ghost>Enviar</Button>
            <Modal isOpen={isHelpOpen} onOpenChange={onHelpOpenChange} >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{preguntas.find(pregunta => pregunta.id === idPregunta).titulo}</ModalHeader>
                            <ModalBody>
                                {
                                    preguntas
                                        .filter(pregunta => pregunta.s === s)
                                        .map(pregunta => pregunta.descripcion)
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onPress={onClose}>
                                    Entendido
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
            <Modal isOpen={isConfirmOpen} onOpenChange={onConfirmOpenChange} >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader> ¿Estás seguro de que quieres enviar el formulario?</ModalHeader>
                            <ModalBody>
                                Una vez enviado no podrás modificarlo.

                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Cancelar
                                </Button>
                                <Button color="primary" onPress={handleConfirm}>
                                    Confirmar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>


        </form>
    );
};

export default FormularioSemanal;