import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';
import { useEffect, useRef, useState } from "react";
import Tools from '../../../tools/tools';
import UserService from "../../../services/UsersService";
import { Card, CardBody } from '@nextui-org/react';
import { useMessageContext } from '../../../contexts/MessageContext';
import { Spinner } from "@nextui-org/react";

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale);

const Grasa = () => {

    const chartRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [porcentaje_grasa, setPorcentaje_grasa] = useState(null);
    const { handleUnauthenticated } = useMessageContext();




    const generarData = (revisiones) => {


        const data = revisiones.map((revision) => {
            return revision.porcentaje_grasa


        })
        setLoading(false)
        return data
    }

    // Función para extraer las fechas de las revisiones
    // Función para extraer las fechas de las revisiones y formatearlas en DD/MM/YYYY
    const extractFechas = (revisiones) => {
        const fechas = revisiones.map((revision) => {
            const fechaJs = Tools.sqlToJsDate(revision.fecha);
            const dia = fechaJs.getDate();
            const mes = fechaJs.getMonth() + 1; // Sumar 1 porque los meses se indexan desde 0
            //const año = fechaJs.getFullYear();

            // Formatear la fecha en el estilo DD/MM/YYYY
            const fechaFormateada = `${dia}/${mes}`;

            return fechaFormateada;
        });

        return fechas;
    };


    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }

        const fetchData = async () => {
            let config;
            try {
                const response = await UserService.getGrasa(handleUnauthenticated);
                if (response) {

                    setPorcentaje_grasa(response[response.length - 1].porcentaje_grasa);
                    if (chartRef.current) {
                        chartRef.current.destroy();
                    }

                    const fechas = extractFechas(response);
                    let data = generarData(response);
                    let minData = Math.min(...data) - 3;
                    let maxData = Math.max(...data) + 3;

                    config = {
                        type: "line",
                        data: {
                            labels: fechas,
                            datasets: [
                                {
                                    label: "% Grasa",
                                    backgroundColor: "rgb(255, 99, 132)",
                                    borderColor: "#e69c1a",
                                    data: data,
                                },
                            ],
                        },
                        options: {
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    grid: {
                                        display: false,
                                    },
                                    suggestedMin: minData,
                                    suggestedMax: maxData
                                },
                            },
                        },
                    }
                    let ctx2 = document.getElementById("myChart2").getContext("2d");
                    chartRef.current = new Chart(ctx2, config);
                }
            }
            catch (error) {
                console.error(error);
            }
       
          
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [porcentaje_grasa]);



    return (
        loading ?
            <Card
                className='mt-2'
            >
                <CardBody>
                    <Spinner size="lg" />
                </CardBody>
            </Card>
            :
            <Card
                className='mt-2 mb-7'
            >
                <CardBody>
                    <p className='text-xs text-gray-400 mb-2 '>
                        %Grasa
                        <br></br>
                        <span className='text-xl text-default-800'>
                            {porcentaje_grasa.toFixed(2)}
                        </span>

                    </p>
                    <div className="charts bg-default-50 dark:bg-default-50 h-full w-full flex items-center justify-center rounded-xl">
                        <canvas id="myChart2" style={{ width: "100%", height: "100%"}}></canvas>
                    </div>
                </CardBody>
            </Card>
    );
}

export default Grasa;