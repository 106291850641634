import { Card } from "@nextui-org/react"


const Privacidad = () => {


    return (
        <>
            <Card shadow className="p-4 mt-4 m-2">
                <h2 className="text-sm mb-4">Privacidad en la plataforma</h2>
                <p className="text-xs mb-2 text-gray-500">
                    En cumplimiento de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
                    Personales y Garantía de los Derechos Digitales y el Reglamento (UE) 2016/679 relativo a la
                    protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la
                    libre circulación de estos datos, 40 PLUS COACHING, S.L. con NIF B56849540 y domicilio en
                    Rambla Sant Francesc 23, 1º1ª – 08720 – Vilafranca del Penedès (Barcelona), le informa de que
                    sus datos, entre los que se incluyen datos de categoría especial, serán incorporados en un
                    registro de actividades del que es responsable con la finalidad de prestar el servicio contratado
                    de forma personalizada y segura, y realizar la gestión administrativa, contable y fiscal de los
                    mismos.
                    Sus datos de contacto se podrán utilizar para el envío de comunicaciones comerciales vía
                    correo electrónico o WhatsApp. Usted puede negarse a recibir estas comunicaciones
                    comerciales. Asimismo, también podrá darse de baja de las mismas en cualquier momento
                    solicitándolo a admin@40pluscoaching.es.
                    Asimismo, solicitamos su consentimiento expreso, voluntario y libre para el posible uso de su
                    imagen en Internet (redes sociales y página web de la entidad) con la finalidad de poder
                    mostrar los resultados y promocionar nuestros servicios. La publicación de las imágenes en
                    redes sociales e Internet podrá implicar la transferencia internacional de las mismas. Podrá
                    retirar el consentimiento otorgado en cualquier momento, sin que ello afecte a publicaciones
                    ya realizadas con el debido consentimiento.
                    Usted tiene derecho a acceder, limitar, oponerse, portar, rectificar y suprimir los datos
                    dirigiendo una comunicación escrita en la que se identifique de forma fehaciente, e indicando
                    el derecho que desea ejercer, al domicilio del Responsable o a admin@40pluscoaching.es.
                    Si quiere acceder a información adicional sobre el tratamiento de sus datos puede solicitarla
                    en los mismos medios indicados o consultarla en la Política de Privacidad de
                    www.40pluscoaching.es.

                </p>

            </Card >

        </>
    )
}
export default Privacidad