import { useEffect } from 'react'
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { useMessageContext } from '../../../contexts/MessageContext';
import { Button } from '@nextui-org/react';
import { useState } from 'react';
import AdminService from '../../../services/AdminService'
import AuthService from '../../../services/AuthService';
import { Avatar } from '@nextui-org/react';


const TablaUploads = ({ handleRefresh, refresh, handleChangeAccio}) => {

    
    const [imagenes, setImagenes] = useState([])
    const { handleError } = useMessageContext();
    useEffect(() => {
        fetchImagenes();
        // eslint-disable-next-line 
    }, [refresh])

    const fetchImagenes = async () => {

        if (await AuthService.validateToken(handleError)) {
            const imagenesData = await AdminService.getImagenes();
            setImagenes(imagenesData);
        }

    }

    const handleDelete = async (id) => {
        if (await AuthService.validateToken(handleError)) {
            const res = await AdminService.deleteUploads(id, handleError);
            if (res) {
                handleRefresh();
            }
        }
       
    }


    return (
        <Table>
            <TableHeader>
                <TableColumn span={2}>Imagen</TableColumn>
                <TableColumn span={2}>URL</TableColumn>
                <TableColumn span={2}>Eliminar imagen</TableColumn>
            </TableHeader>
            <TableBody>
                {imagenes.map((imagen) => (
                    <TableRow key={imagen.id}>
                        <TableCell><Avatar src={imagen.url} /></TableCell>
                        <TableCell>{imagen.url}</TableCell>
                     
                        <TableCell><Button color='danger'
                            onClick={() => {
                                if (window.confirm('¿Estás seguro de que quieres eliminar esta imagen?')) {
                                    handleDelete(imagen.id);
                                }
                            }}
                        >Eliminar</Button></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
export default TablaUploads
