
const Tools = {
    sqlToJsDate: (sqlDate) => {
        // Check if sqlDate is defined
        if (!sqlDate) {
            console.log('sqlDate is undefined or null');
            return;
        }

        // Create a Date object directly from the sqlDate string
        const jsDate = new Date(sqlDate);


        return jsDate;
    },
    fechaToDias: (fecha1, fecha2) => {
        //funcion que combierte fechas en la diferencia de dias
        const diffTime = Math.abs(fecha2 - fecha1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    },

    getInfoAlimento: (tipo) => {
        //funcion que devuelve un color segun el tipo
        let data = {};
        switch (tipo) {
            case 1:
                data = {
                    info: "Proteínas magras",
                    color: "#002554"
                }
                break;
            case 2:
                data = {
                    info: "Proteínas grasas",
                    color: "#d22630"
                }
                break;
            case 3:
                data = {
                    info: "Grasas",
                    color: "#ffc845"
                }
                break;
            case 4:
                data = {
                    info: "Grasas Ocasionales",
                    color: "blue"
                }
                break;
            case 5:
                data = {
                    info: "Carbohidratos",
                    color: "#888b8d"
                }
                break;
            case 6:
                data = {
                    info: "Lacteos",
                    color: "#e4d5d3"
                }
                break;
            case 7:
                data = {
                    info: "Verduras",
                    color: "#50a684"
                }
                break;
            case 8:
                data = {
                    info: "Frutas",
                    color: "#a83d72"
                }
                break;
            default:
                data = {
                    info: "Alimento sin clasificar",
                    color: "black"
                }
        }
        return data;
    },

    getListadoAvatares: () => {
        return [
            {
                id: 1,
                avatar: 'https://cdn-icons-png.flaticon.com/128/249/249201.png'
              },
              {
                id: 2,
                avatar: 'https://cdn-icons-png.flaticon.com/128/249/249216.png'
              },
              {
                id: 3,
                avatar: 'https://cdn-icons-png.flaticon.com/128/249/249221.png'
              },
              {
                id: 4,
                avatar: 'https://cdn-icons-png.flaticon.com/128/249/249226.png'
              },
              {
                id : 5,
                avatar: 'https://app.40pluscoaching.es/uploads/pec.jpg'
              }
        ]
    }

}



export default Tools;
