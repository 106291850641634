import { useState } from 'react';
import Header from "../components/Header";
import { Tabs, Tab } from "@nextui-org/react";
import Datos from "../components/perfil/Datos";
import Entreno from "../components/perfil/Entrenos";
import Rendimiento from "../components/perfil/Renimiento";

const Perfil = () => {
    const [selected, setSelected] = useState("datos");

    const handleChange = (value) => {
        setSelected(value);
    }

    return (
        <div>
            <Header />
            <div className="contaºer p-4">
                <Tabs
                    key='bordered'
                    variant='bordered'
                    aria-label="Tabs variants"
                    className="flex justify-center mt-4"
                    selectedKey={selected}
                    onSelectionChange={handleChange}
                >
                    <Tab key="datos" title="Datos" />
                    <Tab key="entreno" title="Entrenos" />
                    <Tab key="rendimiento" title="Rendimiento" />
                </Tabs>
            </div>

            {selected === "datos" && <Datos />}
            {selected === "entreno" && <Entreno />}
            {selected === "rendimiento" && <Rendimiento />}

        </div>
    )
}

export default Perfil;