
import { useEffect, useState } from "react";
import IMC from "../perfil/rendimiento/IMC";
import Peso from "../perfil/rendimiento/Peso";
import Grsa from "../perfil/rendimiento/Grasa";
import MAssaMagre from "../perfil/rendimiento/Massamagre";
import UsersService from '../../services/UsersService';
import { useMessageContext } from '../../contexts/MessageContext';

const Charts = (props) => {
    const { handleUnauthenticated } = useMessageContext();
    const [revisiones, setRevisiones] = useState(null);

    useEffect(() => {
        UsersService.GetRevisionesBBDD(handleUnauthenticated).then((res) => {
            setRevisiones(res);

        }).catch((err) => {
            console.log(err);

        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    return (
        <div>
            {revisiones && props.chart === 'Peso' && <Peso revisiones={revisiones} />}
            {props.chart === 'IMC' && <IMC />}
            {props.chart === 'Grasa' && <Grsa />}
            { props.chart === 'GvsM' && <MAssaMagre />}
        </div>
    );
       
}

export default Charts;