import { Card, CardHeader, CardBody, Divider } from "@nextui-org/react";
import { FaScrewdriverWrench } from "react-icons/fa6";
import { Switch, cn } from "@nextui-org/react";

const Soporte = () => {
    return (
        <div>
            <Card className="w-full min-h-[230px]">
                <CardHeader className="flex gap-3">
                    <FaScrewdriverWrench />
                    <div className="flex flex-col">
                        <p className="text-md">Mantenimiento</p>
                        <p className="text-small text-default-500">Administra el acceso a la APP</p>
                    </div>
                </CardHeader>
                <Divider />
                <CardBody className="flex justify-center gap-2">
                    <Switch
                    
                        classNames={{
                            base: cn(
                                "inline-flex flex-row-reverse w-full max-w-md bg-content1 hover:bg-content2 items-center",
                                "justify-between cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                            ),
                            wrapper: "p-0 h-4 overflow-visible",
                            thumb: cn("w-6 h-6 border-2 shadow-lg",
                                "group-data-[hover=true]:border-primary",
                                //selected
                                "group-data-[selected=true]:ml-6",
                                // pressed
                                "group-data-[pressed=true]:w-7",
                                "group-data-[selected]:group-data-[pressed]:ml-4",
                            ),
                        }}
                    >
                        <div className="flex flex-col gap-1">
                            <p className="text-medium">Activar modo mantenimiento</p>
                            <p className="text-tiny text-default-400">
                              Habilitando esta opción los usuarios no podran acceder a la app
                            </p>
                        </div>
                    </Switch>

                </CardBody>
            </Card>
        </div>
    )
}
export default Soporte