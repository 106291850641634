import React, { useEffect, useState } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button,  useDisclosure } from "@nextui-org/react";
import AdminService from '../../../services/AdminService'
import AuthService from '../../../services/AuthService';
import { useMessageContext } from '../../../contexts/MessageContext';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { Input as InputNext } from "@nextui-org/react";
import Tools from "../../../tools/tools";
import { Select, SelectItem } from "@nextui-org/react";
import { PiBowlFoodFill } from "react-icons/pi";
import { Tooltip } from "@nextui-org/react";
import { MdDelete } from "react-icons/md";


const ModalDieta = ({ accion, close, user}) => {
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const { handleError, handleSuccess } = useMessageContext();
    const [dieta, setDieta] = useState([]);
    const [tipoAlimento, setTipoAlimento] = useState(1);
    


    useEffect(() => {
        if (accion) {
            onOpen();

            if (accion === "add") {
                setDieta({ nombre: "", alimentos: [] });
            } else {
                // Hacemos una copia profunda de accion para evitar modificar el objeto original
                const copiaAccion = JSON.parse(JSON.stringify(accion));
                setDieta(copiaAccion);
            }
        }

        return () => {
            // Limpieza si es necesaria
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accion]);



    const handleClose = () => {
        onClose();
        close();
        //si se cierra el modal se limpia el estado y no se guarda nada
        setDieta([]);

    };


    const handleSave = async () => {

        if (accion === "add") {
            if (await AuthService.validateToken(handleError)) {
                dieta.usuario = user;

            

            const data = await AdminService.addDieta(dieta);
                if (data) {
                    close();
                    onClose();
                    handleSuccess("Comida creada correctamente");
                }
               


            }
        } else {

            if (await AuthService.validateToken(handleError)) {

                const data = await AdminService.updateDieta(dieta);
                if (data) {
                    close();
                    onClose();
                    handleSuccess("comida actualizada correctamente");
                }


            }
        }
    }

    const handleDelete = async () => {
        //primero preguntamos si esta seguro de eliminar la dieta
        if (window.confirm("¿Está seguro de eliminar la dieta?")) {
            if (await AuthService.validateToken(handleError)) {
                const data = await AdminService.delDieta(dieta.id);
                if (data) {
                    close();
                    onClose();
                    handleSuccess("comida eliminada correctamente");
                }
            }
        }
    }

    return (

        <>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} onClose={handleClose}>
                <ModalContent>


                    <ModalHeader className="flex justify-between items-center gap-2 ml-1 mr-1">
                        <InputNext value={dieta.nombre} onChange={(e) => { dieta.nombre = e.target.value; setDieta({ ...dieta }) }} placeholder="Nombre de esta comida"/>
                        {accion !== "add" ? <>
                            <Tooltip content="Eliminar comida" placement="top">
                                <Button color="danger" onClick={handleDelete} style={{ height: 40, width: 40, minWidth: 40, padding: 0, borderRadius: 50 }}><MdDelete /></Button>
                            </Tooltip>
                        </> : null}
                    </ModalHeader>
                    <ModalBody>
                        <div className="flex justify-center items-center gap-1">
                            <Select
                                name="principal"
                                value={tipoAlimento}
                                onSelectionChange={(value) => setTipoAlimento(value.currentKey)}
                                placeholder="Proteínas grasas"
                                labelPlacement="outside"
                                startContent={
                                    <PiBowlFoodFill />
                                }
                            >
                                {
                                    Array.from({ length: 8 }, (_, i) => (
                                        <SelectItem value={i + 1} key={i + 1}>
                                            {Tools.getInfoAlimento(i + 1).info}
                                        </SelectItem>
                                    ))
                                }
                            </Select>
                            <Button color="success" onClick={() => {
                                dieta.alimentos.push({ tipo: Tools.getInfoAlimento(Number(tipoAlimento)).info, cantidad: 0, tipoID: Number(tipoAlimento) });
                                setDieta({ ...dieta });
                            }}>Añadir</Button>
                        </div>


                        <Table>
                            <TableHeader>
                                <TableColumn>Alimento</TableColumn>
                                <TableColumn>Cantidad</TableColumn>
                                <TableColumn>Eliminar</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {dieta.alimentos && dieta.alimentos.length > 0 && dieta.alimentos.map((alimento) => (
                                    <TableRow key={alimento.id}>
                                        <TableCell>{alimento.tipo}</TableCell>
                                        <TableCell><InputNext value={alimento.cantidad} type="number" onChange={(e) => { alimento.cantidad = e.target.value; setDieta({ ...dieta }) }} /></TableCell>
                                        <TableCell><Button color="danger" onClick={() => { dieta.alimentos.splice(dieta.alimentos.indexOf(alimento), 1); setDieta({ ...dieta }) }}>Eliminar</Button></TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={handleClose}>Cerrar</Button>
                        <Button color="primary" onPress={handleSave}>Guardar</Button>
                    </ModalFooter>


                </ModalContent>
            </Modal>
        </>
    );
};

export default ModalDieta;
