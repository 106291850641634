import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "@nextui-org/react";
import { Input, Spacer } from '@nextui-org/react';
import bideps from '../../assets/Biceps.jpg';
import triceps from '../../assets/Triceps.jpg';
import abdominales from '../../assets/Abdominales.jpg';
import cuadriceps from '../../assets/Cuadriceps.jpg';
import femorales from '../../assets/Femoral.jpg';
import gemelos from '../../assets/Gemelos.jpg';
import gluteos from '../../assets/Glúteo.jpg';
import hombros from '../../assets/Hombros.jpg';
import pectorales from '../../assets/Pectorales.jpg';
import soleo from '../../assets/Sóleo.jpg';
import trapecio from '../../assets/Trapecio.jpg';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { useMessageContext } from '../../contexts/MessageContext';
import EntrenoService from "../../services/EntrenoService";
import AuthService from "../../services/AuthService";
import Calentamiento from "./Calentamiento";
import { useNavigate } from 'react-router-dom';


const Entreno = () => {
    const { id } = useParams();
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onOpenChange: onOpenChange2 } = useDisclosure();
    const { handleError, handleSuccess } = useMessageContext();
    const [ejerciciosRealizados, setEjerciciosRealizados] = useState([]);
    const [handleRefresh, setHandleRefresh] = useState(false);
    const [ejercicioActual, setEjercicioActual] = useState(0);
    const [ejercicios, setEjercicios] = useState([]);
    const [tipo, setTipo] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (AuthService.validateToken(handleError)) {

            EntrenoService.getEntrenos(id).then((response) => {
                setEjercicios(response.ejercicios);

                setTipo(response.tipo);
            }
            ).catch((error) => {
                handleError('Error al cargar el entrenamiento');
            });
        }
        // eslint-disable-next-line
    }, [id]);




    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        buttons: false,
        arrows: false,

    };




    const handleEnd = (ejercicio) => {


        //compurebo si es el ultimo ejercicio del entrenamiento muestro un mensaje de entrenamiento finalizado y redirijo a la pagina de inicio despues de 3 segundos
        const ejerciciosNoFinalizados = ejercicios.filter(e => !e.finalizado);

        if (ejerciciosNoFinalizados.length === 1) {


            EntrenoService.finalizarEntreno(id, ejerciciosRealizados, tipo).then((response) => {

                handleSuccess('💪🏆¡Entrenamiento finalizado! Felicidades, sigue así, ¡cada vez estás más cerca de tu objetivo!')
                navigate('/');
            }
            ).catch((error) => {
                console.log(error);
                handleError('Error al finalizar el entrenamiento');
            });


        }



        // We validate that all weights, repetitions and series of this exercise are filled
        const ejerciciosRealizadosDeEsteEjercicio = ejerciciosRealizados.filter(er => er.id === ejercicio.id);

        if (ejerciciosRealizadosDeEsteEjercicio.length !== ejercicio.series ||
            !ejerciciosRealizadosDeEsteEjercicio.every(er => er.peso && er.repeticiones)) {
            handleError('Debes llenar todas las series, pesos y repeticiones de este ejercicio');
            return;
        }

        if (ejerciciosNoFinalizados.length > 1) {
            handleSuccess('Ejercicio finalizado');
        }
        //en la lista de ejercicios modificamos el ejercicio finalizado a true
        const newEjercicios = [...ejercicios];

        const index = newEjercicios.findIndex(e => e.id === ejercicio.id);
        newEjercicios[index].finalizado = true;
        setEjercicios(newEjercicios);

        //actualizamos el estado
        setHandleRefresh(true);

    }


    useEffect(() => {
        if (handleRefresh) {
            setEjercicios(ejercicios => ejercicios.map(ejercicio => {
                const realizado = ejerciciosRealizados.find(er => er.id === ejercicio.id);
                return realizado ? { ...ejercicio, finalizado: true } : ejercicio;
            }));
            setHandleRefresh(false);
        }
    }, [handleRefresh, ejerciciosRealizados]);

    useEffect(() => {


    }, [id,]);



    return (
        <>
            <Calentamiento />
            <div className="h-full w-full p-4 " key={id}>

                <Slider {...settings}
                    className="h-full w-full" >
                    {ejercicios.map((ejercicio) => (
                        ejercicio.finalizado ? null : (
                            <div key={ejercicio.id} className="p-2">
                                <div className="relative p-2" onPress={onOpen}>
                                    <h2 className="text-lg font-semibold text-center mx-auto">
                                        {ejercicio.nombre.split('-').slice(1).join(' ')}
                                    </h2>

                                </div>
                                <Spacer y={6} />
                                <div className="grid grid-cols-3 gap-2 ">
                                    <div className="bg-default-50 dark:bg-default-50 p-2 rounded-md">
                                        <div className="w-full flex flex-col gap-2 min-w-unit-16">
                                            <Card className="w-full h-16 rounded-xl flex items-center justify-center flex-col gap-1 " >
                                                <h2 className="text-2xl">{ejercicio.repeticiones}</h2>
                                                <h2 className="text-xs">Repeticiones</h2>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className="bg-default-50 dark:bg-default-50 p-2 rounded-md">
                                        <div className="w-full flex flex-col gap-2 min-w-unit-16">
                                            <Card className="w-full h-16 rounded-xl flex items-center justify-center flex-col gap-1 " >
                                                <h2 className="text-2xl">{ejercicio.series}</h2>
                                                <h2 className="text-xs">Series</h2>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className="bg-default-50 dark:bg-default-50 p-2 rounded-md">
                                        <Card className="w-full h-16 rounded-xl flex items-center justify-center flex-col gap-1 " >
                                            <h2 className="text-2xl">{ejercicio.descanso}</h2>
                                            <h2 className="text-xs">Descanso</h2>
                                        </Card>
                                    </div>
                                </div>
                                <Spacer y={4} />
                                <div onClick={() => console.log(ejerciciosRealizados)}>
                                    <h2 className="text-medium font-semibold mx-auto">Notas del ejercicio</h2>
                                    <Spacer y={1} />
                                    <p className="text-xs mx-auto font-light">{ejercicio.descripcion}</p>
                                    <em className="text-xs mx-auto font-light">{ejercicio.notas}</em>
                                </div>
                                <Spacer y={6} />
                                <div className="grid grid-cols-2 gap-2 bg-default-50 dark:bg-default-50 p-2 rounded-md ">
                                    <div className="flex flex-col gap-5 justify-center">
                                        <div>
                                            <h3 className="text-medium font-semibold mx-auto">Primario</h3>
                                            <p className="text-sm mx-auto font-light">{ejercicio.primero}</p>
                                        </div>
                                        <div>
                                            <h3 className="text-medium font-semibold mx-auto">Secundario</h3>
                                            <p className="text-sm mx-auto font-light">{ejercicio.secundario}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={
                                            ejercicio.primero === 'Pecho' ? pectorales :
                                                ejercicio.primero === 'Espalda' ? trapecio :
                                                    ejercicio.primero === 'Hombros' ? hombros :
                                                        ejercicio.primero === 'Biceps' ? bideps :
                                                            ejercicio.primero === 'Triceps' ? triceps :
                                                                ejercicio.primero === 'Piernas' ? cuadriceps :
                                                                    ejercicio.primero === 'Gluteos' ? gluteos :
                                                                        ejercicio.primero === 'Abdominales' ? abdominales :
                                                                            ejercicio.primero === 'Trapecio' ? trapecio :
                                                                                ejercicio.primero === 'Femoral' ? femorales :
                                                                                    ejercicio.primero === 'Gemelo' ? gemelos :
                                                                                        ejercicio.primero === 'Soleo' ? soleo :
                                                                                            ejercicio.primero === 'Deltoide' ? hombros :
                                                                                                ejercicio.primero === 'Pectoral' ? pectorales :
                                                                                                    ejercicio.primero === 'Dorsal' ? trapecio :
                                                                                                        bideps


                                        } alt="biceps" className="mx-auto rounded-md" />
                                    </div>

                                </div>
                                <Spacer y={6} />
                                <div>
                                    <h2 className="text-medium font-semibold mx-auto">Tus marcas anteriores</h2>
                                    <Spacer y={1} />
                                    {
                                        ejercicio.ejerciciosRealizados && ejercicio.ejerciciosRealizados.length > 0 ? (
                                            Object.values(ejercicio.ejerciciosRealizados).filter(item => typeof item === 'object').map((serie, index) => (
                                                <div key={index}>
                                                    <span key={index} className='text-xs text-gray-400'>
                                                        <b> Serie {serie.serie}</b>: {serie.repeticiones} x {serie.peso}kg

                                                    </span>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-xs mx-auto font-light">No hay marcas anteriores</p>
                                        )



                                    }
                                </div>
                                <Spacer y={6} />
                                <div>
                                    <h2 className="text-medium font-semibold mx-auto">Registra tus marcas</h2>
                                    <Spacer y={1} />
                                    {
                                        Array.from({ length: ejercicio.series }).map((_, i) => (
                                            <div key={i}>
                                                <Spacer y={3} />
                                                <h3 className="text-xs font-thin mx-auto">Serie {i + 1}</h3>
                                                <div className="grid grid-cols-2 gap-2 mt-1">
                                                    <Input label="Peso" placeholder="Ingresa el peso"
                                                        value={ejerciciosRealizados.find(er => er.id === ejercicio.id && er.serie === i + 1)?.peso}
                                                        type="number"
                                                        key={`${ejercicio.id}-${i}`} // This is important to force the component to re-render when the user changes the exercise
                                                        onChange={(e) => {
                                                            const newEjerciciosRealizados = [...ejerciciosRealizados];
                                                            const index = newEjerciciosRealizados.findIndex(
                                                                er => er.id === ejercicio.id && er.serie === i + 1
                                                            );

                                                            if (index !== -1) {
                                                                // Update the existing object
                                                                newEjerciciosRealizados[index].peso = e.target.value;
                                                            } else {
                                                                // Add a new object
                                                                newEjerciciosRealizados.push({
                                                                    id: ejercicio.id,
                                                                    serie: i + 1,
                                                                    peso: e.target.value
                                                                });
                                                            }

                                                            setEjerciciosRealizados(newEjerciciosRealizados);


                                                        }}
                                                    />
                                                    <Input label="Repeticiones" placeholder="Ingresa las repeticiones"
                                                        type="number"
                                                        value={ejerciciosRealizados.find(er => er.id === ejercicio.id && er.serie === i + 1)?.repeticiones}
                                                        key={`${ejercicio.id}-${i}-repeticiones`} // This is important to force the component to re-render when the user changes the exercise
                                                        onChange={(e) => {
                                                            const newEjerciciosRealizados = [...ejerciciosRealizados];
                                                            const index = newEjerciciosRealizados.findIndex(
                                                                er => er.id === ejercicio.id && er.serie === i + 1
                                                            );

                                                            if (index !== -1) {
                                                                // Update the existing object
                                                                newEjerciciosRealizados[index].repeticiones = e.target.value;
                                                            } else {
                                                                // Add a new object
                                                                newEjerciciosRealizados.push({
                                                                    id: ejercicio.id,
                                                                    serie: i + 1,
                                                                    repeticiones: e.target.value
                                                                });
                                                            }

                                                            setEjerciciosRealizados(newEjerciciosRealizados);

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    }


                                </div>

                                <Spacer y={6} />
                                <div className="grid grid-cols-2 gap-2">
                                    <Button color="default" className="mx-auto w-full text-xs" onClick={() => {
                                        onOpen();
                                        setEjercicioActual(ejercicio.id);
                                    }}
                                    >
                                        Como realizar el ejercicio
                                    </Button>
                                    <Button color="default" className="mx-auto w-full text-xs"

                                        onClick={() => {
                                            onOpen2();
                                            setEjercicioActual(ejercicio.id);
                                        }
                                        }
                                    >
                                        Substituir el ejercicio
                                    </Button>

                                </div>

                                <Spacer y={2} />
                                <Button color="primary" className="mx-auto w-full" onClick={
                                    () => {
                                        handleEnd(ejercicio);
                                    }
                                }>
                                    Finalizar ejercicio
                                </Button>

                            </div>
                        )
                    ))}
                </Slider>

            </div >
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                placement="bottom-center"
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader>Como realizar el ejercicio</ModalHeader>
                            <ModalBody>

                                <iframe width="315" height="560"
                                    src={"https://www.youtube.com/embed/" + ejercicios.find(e => e.id === ejercicioActual).url}
                                    title="YouTube video player" frameborder="0"
                                    allowfullscreen></iframe>
                            </ModalBody>
                            <ModalFooter>

                                <Button color="primary" onPress={onClose}>
                                    Entendido
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal
                isOpen={isOpen2}
                onOpenChange={onOpenChange2}
                placement="bottom-center"
            >
                <ModalContent>
                    {(onClose2) => (
                        <>
                            <ModalHeader>Estas seguro que quieres cambiar este ejercicio por otro?</ModalHeader>
                            <ModalBody>

                                <Button color="default" onPress={
                                    () => {
                                        //buscamos en el array de ejercicios el ejercicio actual
                                        const ejercicio = ejercicios.find(e => e.id === ejercicioActual);

                                        //ahora buscamos si tiene substitutos
                                        if (ejercicio.substitutos) {


                                            const newEjercicios = [...ejercicios];
                                            const index = newEjercicios.findIndex(e => e.id === ejercicio.id);
                                            const substitutos = Array.isArray(ejercicio.substitutos) ? [...ejercicio.substitutos] : [ejercicio.substitutos];

                                            // Save the current exercise before replacing it
                                            const currentExercise = { ...newEjercicios[index] };
                                            delete currentExercise.substitutos; // Remove the substitutos property to avoid circular references

                                            newEjercicios[index] = substitutos.shift();
                                            newEjercicios[index].finalizado = false;
                                            newEjercicios[index].substitutos = [...substitutos, currentExercise]; // Move the current exercise to the end of the substitutes array

                                            setEjercicios(newEjercicios);
                                            setHandleRefresh(true);
                                            onClose2();

                                        } else {
                                            //si no tiene substitutos mostramos un mensaje de error
                                            handleError('Este ejercicio no tiene substitutos');
                                        }
                                    }
                                }>

                                    Si
                                </Button>
                                <Button color="primary" onPress={onClose2}>
                                    No
                                </Button>

                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>

        </>
    )
}
export default Entreno