import { ToastContainer } from 'react-toastify';
import RoutesList from './Routes';
import { useColorMode } from '../contexts/ColorModeContext';
import { useAuthContext } from '../contexts/AuthContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';



const MainContent = () => {

  const { colorMode } = useColorMode();
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();



  useEffect(() => {
    //validate if the user is authenticated and redirect to login if not
if (!isAuthenticated && !window.location.pathname.includes("/reset-password")) {
  navigate("/login");
}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);




  return (
    <main className={colorMode + " text-foreground bg-background"} style={{ minHeight: '100vh' }}>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={colorMode}
      />


      <RoutesList />
    </main>
  );
};

export default MainContent;