import AdminService from '../../../services/AdminService';
import AuthService from '../../../services/AuthService';
import { useMessageContext } from '../../../contexts/MessageContext';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Card, CardBody, CardHeader } from '@nextui-org/react';
import { Button } from '@nextui-org/react';
import { FaCirclePlus } from "react-icons/fa6";
import ModalEntreno from './ModalEntreno';
import ModalEditarEntreno from './ModalEditarEntreno';
import { Tooltip } from '@nextui-org/react';
import Tools from '../../../tools/tools';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const ListadoEjercicios = () => {



    const { handleError } = useMessageContext();
    const { id } = useParams();
    const [entrenos, setEntrenos] = useState(null);
    const [ejercicios, setEjercicios] = useState(null);
    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        fetchUserEntrenos();
        // eslint-disable-next-line
    }, [refresh]);


    const handleRefresh = () => {
        setRefresh(!refresh);
    }


    const fetchUserEntrenos = async () => {

        if (await AuthService.validateToken(handleError)) {

            const entrenoData = await AdminService.entrenamientosUsuario(id);
            const ejerciciosData = await AdminService.getEjercicios(id);

            console.log(entrenoData);
            setEntrenos(entrenoData);
            setEjercicios(ejerciciosData);
        }
    }
    const handleClose = () => {
        setAddNew(false);
        setEdit(false);
    }


    const handleDelete = async (id) => {
        if (await AuthService.validateToken(handleError)) {
            const res = await AdminService.deleteEntreno(id);
            if (res) {
                handleRefresh();
            }
        }
        
    }
const handleDeleteEntreno = async (id) =>{
    if (await AuthService.validateToken(handleError)) {
        const res = await AdminService.deleteEntrenoEliminar(id);
        if (res) {
            handleRefresh();
        }
    }
}


    return (
        <>
            <div className=' bg-default-50 dark:bg-default-50 h-full p-4 rounded-xl'>
                <div className='mb-4 flex flex-column align-center justify-between'>
                    <p className='text-xs text-gray-400 flex justify-center'>
                        Listado de entrenamientos del usuario
                    </p>
                    <Button color='warning' className='p-1 self-end' onClick={() => setAddNew(true)}>
                        <FaCirclePlus />
                    </Button>
                </div>
                {
                    entrenos && ejercicios && (
                        <div className='h-96 overflow-y-auto p-2'>
                            {
                                entrenos.map((entreno, index) => (
                                    <Card key={index} className='mb-2 bg-default-100 dark:bg-default-100'>
                                        <CardHeader className='flex items-center'>

                                            <div className='m-2 absolute right-0'>
                                                <Tooltip content={'Editar entreno'}>
                                                    <Button color='primary'
                                                        onClick={() => setEdit(entreno.id)}
                                                        style={{ height: 40, width: 40, minWidth: 40, padding: 0, borderRadius: 50, marginRight: 5 }}>
                                                        {
                                                            <FaEdit />
                                                        }
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip content={entreno.finalizado == 1 ? 'Añadir visivilidad' : 'Quitar visivilidad'}>
                                                    <Button color={entreno.finalizado == 1 ? 'success' : 'warning'}
                                                        onClick={() => handleDelete(entreno.id)}
                                                        style={{ height: 40, width: 40, minWidth: 40, padding: 0, borderRadius: 50 }}>
                                                        {
                                                            entreno.finalizado == 1 ? (
                                                                <FaEye />
                                                            ) : (
                                                                <FaEyeSlash />
                                                            )

                                                        }
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip content={'Eliminar entreno'}> 
                                                    <Button color='danger'
                                                        onClick={() => handleDeleteEntreno(entreno.id)}
                                                        className='ml-1'
                                                        style={{ height: 40, width: 40, minWidth: 40, padding: 0, borderRadius: 50, marginRight: 5 }}>
                                                        {
                                                            <MdDelete />
                                                        }
                                                    </Button>
                                                </Tooltip>
                                            </div>

                                            <p className='ml-2'> {entreno.nombre} </p>
                                            {
                                                entreno.fecha_ult_entreno ? (
                                                    <p className='text-xs ml-2 text-success-500'>
                                                        {Tools.sqlToJsDate(entreno.fecha_ult_entreno).toLocaleDateString()}
                                                    </p>
                                                ) : (
                                                    <p className='text-xs ml-2 text-warning-500'>
                                                        Pendiente
                                                    </p>
                                                )
                                            }

                                        </CardHeader>
                                        <CardBody>
                                            {entreno.ejercicios.map((ejercicio, index) => (
                                                <div key={index} className='mb-3'>

                                                    {
                                                        //buscamos el ejercicio en el array de ejercicios que el id sea igual al id del ejercicio
                                                        ejercicios.find(ej => ej.id === parseInt(ejercicio.id_ejercicio)) && (
                                                            <span className='text-xs text-gray-400 '>
                                                                {ejercicios.find(ej => ej.id === parseInt(ejercicio.id_ejercicio)).nombre}
                                                                <br></br>
                                                                {ejercicio.realizado && (
                                                                    Object.values(ejercicio).filter(item => typeof item === 'object').map((serie, index) => (
                                                                        <>
                                                                            <span key={index} className='text-xs text-gray-400'>
                                                                              <b> Serie {serie.serie}</b>: {serie.repeticiones} x {serie.peso}kg

                                                                            </span>
                                                                            <br></br>
                                                                        </>

                                                                    ))
                                                                )}
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            ))}

                                        </CardBody>
                                    </Card>

                                ))
                            }
                        </div>
                    )
                }
            </div>
            <ModalEntreno accion={addNew} close={handleClose} refresh={handleRefresh} />
            <ModalEditarEntreno accion={edit} close={handleClose} refresh={handleRefresh} />
        </>
    )
}
export default ListadoEjercicios