import React from "react";
import { Skeleton } from "@nextui-org/react";
import { useState, useEffect, useMemo } from "react";
import { Listbox, ListboxItem } from "@nextui-org/react";
import { ListboxWrapper } from "./ListboxWrapper";
import Charts from "./Charts";


export default function ComparadorGraficos() {
    const [loading, setLoading] = useState(true);
    const [selectedKeys, setSelectedKeys] = useState(new Set(["GvsM"]));

    useEffect(() => {
 
            setLoading(false);
      
    }, []);

    const selectedValue = useMemo(
        () => Array.from(selectedKeys).join(", "),
        [selectedKeys]
    );


    return (
        <>
            {loading ? (
                <div className="w-full flex items-center gap-2 h-72">
                    <div className="w-4/6 h-full flex flex-col gap-2">
                        <Skeleton className="h-full rounded-xl" />

                    </div>
                    <div className="w-2/6 h-full flex flex-col gap-2">
                        <Skeleton className="h-full rounded-xl" />

                    </div>


                </div>
            ) : (
                <div className="w-full flex items-center gap-2 h-72">
                    <div className="w-4/6 h-full flex flex-col gap-2 rounded-xl bg-default-50 dark:bg-default-50 items-center justify-center">
                        <div className="w-full p-2">
                            
                            <Charts className="w-full" chart={selectedValue} />
                        </div>
                        
                    </div>
                    <div className="w-2/6 h-full flex flex-col gap-2 rounded-xl bg-default-50 dark:bg-default-50 items-center justify-center">
                        <div className="flex flex-col gap-2 rounded-xl">
                            <ListboxWrapper>
                                <Listbox
                                    aria-label="Graficos"
                                    variant="flat"
                                    disallowEmptySelection
                                    selectionMode="single"
                                    selectedKeys={selectedKeys}
                                    onSelectionChange={setSelectedKeys}>
                                    <ListboxItem key="Peso">Peso</ListboxItem>
                                    <ListboxItem key="IMC">IMC</ListboxItem>
                                    <ListboxItem key="Grasa">% Grasa</ListboxItem>
                                    <ListboxItem key="GvsM">%G vs %M</ListboxItem>
                                </Listbox>
                            </ListboxWrapper>
                        </div>

                    </div>


                </div>
            )}
        </>
    );
}