import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { useEffect } from "react";
import { Input } from "@nextui-org/react";
import AdminService from '../../../services/AdminService'
import AuthService from "../../../services/AuthService";
import { useMessageContext } from '../../../contexts/MessageContext';
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";





export default function ModalRecatas({ tipoModal, setTipo, handleRefresh, idEdit, setId }) {
    const { handleError } = useMessageContext();
    const fileTypes = ["PDF"];
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const [file, setFile] = useState(null);
    const [titulo, setTitulo] = useState(null);
    const [img, setImg] = useState(null);
    

    useEffect(() => {
        if (tipoModal) {
            onOpen()
        }
    


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoModal]);

   

    const handleClose = () => {
        setTipo();
        onClose();
        setId();
    }
    const handleGuardar = async () => {
        if (tipoModal === 'add') {
            if (await AuthService.validateToken(handleError)) {
              let receta = {
                titulo: titulo,
                file: file,
                img: img
              }
                const res = await AdminService.addReceta(receta, handleError);
                if (res) {
                    setFile(null)
                    setTitulo(null)
                    setImg(null)
                    onClose()
                }

            }
        }

        if (tipoModal === 'edit') {
            if (await AuthService.validateToken(handleError)) {
      
            }
        }

        handleClose();
        handleRefresh();
    }

    const handleChange = (file) => {
        setFile(file);

        onOpen();
    };


return (
    <>
        <Modal isOpen={isOpen} onOpenChange={onOpenChange} onClose={handleClose}>
            <ModalContent>
                {tipoModal === 'add' && (
                    <>
                        <ModalHeader>Añadir receta</ModalHeader>

                        <ModalBody>
                        <Input label="Nombre" placeholder="Nombre" value={titulo} onChange={(e) => setTitulo(e.target.value)} />
                        <Input label="Url imagen" placeholder="Url imagen" value={img} onChange={(e) => setImg(e.target.value)} />
                            <p className='text-xs mt-4'>Subir receta</p>
                            <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                            {file && ( <p className='text-xs text-primary-500'>Archivo subido: <b>{file.name}</b></p>)}                                
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={handleClose}>
                                Cerrar
                            </Button>
                            <Button color="primary" onPress={handleGuardar}>
                            Guardar
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    </>
);
}