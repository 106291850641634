import Header from "../components/Header";
import Seleccionador from "../components/entreno/Seleccionador";
const Entrno = () => {
    return (
        <>
            <Header />
            <section className="container p-4 flex justify-center items-center w-full h-full">
                <div className=" w-full h-full">
                    <Seleccionador />
                </div>
            </section>
        </>
    )
}
export default Entrno