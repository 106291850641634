import { useState, useEffect } from 'react'
import AdminService from '../../../services/AdminService'
import AuthService from '../../../services/AuthService';
import Tools from '../../../tools/tools';
import { FaEye } from "react-icons/fa"
import { RiDeleteBinFill } from "react-icons/ri";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, User, Chip, Tooltip } from "@nextui-org/react";
import { Link } from 'react-router-dom';
import { useMessageContext } from '../../../contexts/MessageContext';
const TablaUsuarios = ({ handleRefresh, refresh }) => {

    const [users, setUsers] = useState([])
    const {handleError } = useMessageContext();
    useEffect(() => {
        
        fetchUsers();
       // eslint-disable-next-line 
    }, [refresh])
    const fetchUsers = async () => {
         
        if(await AuthService.validateToken(handleError)){
        const usersData = await AdminService.getUsers();
        setUsers(usersData);
        }

    }

    const eliminarUsuario = async (id) => {
        const response = await AdminService.delUsuario(id);
        if (!response.error) {
            fetchUsers();
        }
    }

    return (
        <Table data={users}>
            <TableHeader>
                <TableColumn span={2}>Nombre</TableColumn>
                <TableColumn span={2}>Notificaciones</TableColumn>
                <TableColumn span={2}>Caducidad</TableColumn>
                <TableColumn span={2}>Ult. Conexion</TableColumn>
                <TableColumn span={2}>Tipo usuario</TableColumn>
                <TableColumn span={2}>Acciones</TableColumn>
            </TableHeader>
            <TableBody>
                {users.map((user) => (
                    <TableRow key={user.id}>
                        <TableCell span={2}>
                            <User
                                avatarProps={{ radius: "lg" }}
                                description={user.email}
                                name={user.nombre + ' ' + user.apellidos}
                            >
                                {user.email}
                            </User>

                            {user.name}</TableCell>
                        <TableCell span={2}>{user.telefono}</TableCell>

                        <TableCell span={2}>
                            <Tooltip content={`Quedan: ${Tools.fechaToDias(Tools.sqlToJsDate(user.caducidad), new Date())} días de suscripción`}>
                                <Chip className="text-xs" color={
                                    Tools.fechaToDias(Tools.sqlToJsDate(user.caducidad), new Date()) > 100 ? 'success' :
                                        Tools.fechaToDias(Tools.sqlToJsDate(user.caducidad), new Date()) > 50 ? 'warning' : 'danger'
                                }>
                                    {Tools.sqlToJsDate(user.caducidad).toLocaleDateString()}
                                </Chip>
                            </Tooltip>
                        </TableCell>
                        <TableCell span={2}> {user.ult_conexion ? Tools.sqlToJsDate(user.ult_conexion).toLocaleString() : 'Nunca'}</TableCell>
                        <TableCell span={2} >{user.permisos === 3 ? <Chip className="text-xs" color="success">Admin</Chip> : <Chip className="text-xs" color="warning">Usuario</Chip>}</TableCell>
                        <TableCell span={2} className="relative flex items-center gap-2">

                            <Tooltip content="Ver usuario">
                                <Link to={`/usuario/${user.id}`}>
                                <span className="text-lg text-default-400 cursor-pointer active:opacity-50 h-full">
                                    <FaEye />
                                </span>
                                </Link>
                            </Tooltip>

                            <Tooltip content="Eliminar usuario">
                                <span className="text-lg text-danger-300 cursor-pointer active:opacity-50 " onClick={() => eliminarUsuario(user.id)}>
                                    <RiDeleteBinFill />
                                </span>
                            </Tooltip>

                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
export default TablaUsuarios
