
import { Card, CardHeader, CardBody, Image } from "@nextui-org/react";
import pdf from '../../assets/icons/pdf.png';
import jpg from '../../assets/icons/jpg.png';
import png from '../../assets/icons/png.png';
import { useEffect, useState } from "react";
import { useMessageContext } from '../../contexts/MessageContext';
import UserService from '../../services/UsersService';
import AuthService from '../../services/AuthService';


const ListadoDocumentos = ({ updated }) => {
    const { handleUnauthenticated, handleError, handleSuccess } = useMessageContext();
    const [archivos, setArchivos] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (await AuthService.validateToken(handleError)) {
                    const response = await UserService.getArchivos(handleUnauthenticated, handleError, handleSuccess);
                    if (response) {
                        setArchivos(response);

                    }
                }
            } catch (error) {
                // Manejo de errores
                console.error(error);
            }
        };

        fetchData();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updated]);





    return (<>

        <div className="mt-2">
            <p className='text-xs mb-1'
                onClick={() => console.log(archivos)}
            >Documentos subidos</p>
            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {
                    archivos &&
                        archivos.length > 0 ? (
                        archivos.map((_, index) => (
                            <Card key={index} className="py-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" >
                                <CardHeader className="pb-0 pt-2 px-4 flex-col items-start" onClick={() => window.open(_.ruta + '/' + _.nombre, "_blank")}>
                                    <p className="text-tiny uppercase font-bold">{_.nombre.split('.')[0]}</p>
                                    <small className="text-default-500">{_.descripcion}</small>
                                    <small className=" text-xs text-default-400">{_.fecha}</small>
                                </CardHeader>
                                <CardBody className="overflow-visible py-2" onClick={() => window.open(_.ruta + '/' + _.nombre, "_blank")}>
                                    <Image
                                        alt="Card background"
                                        className="object-cover rounded-xl"
                                        src={_.nombre.split('.')[1] === "pdf" ? pdf : _.nombre.split('.')[1] === "jpg" ? jpg : png}
                                        width={70}
                                    />
                                </CardBody>
                            </Card>
                        ))
                    ) : (
                        <p>No hay documentos</p>
                    )
                }

            </div>
        </div>

    </>
    )
}
export default ListadoDocumentos