import React, { useEffect, useState } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input, useDisclosure } from "@nextui-org/react";
import AdminService from '../../../services/AdminService'
import AuthService from '../../../services/AuthService';
import { useMessageContext } from '../../../contexts/MessageContext';
import { DndContext } from '@dnd-kit/core';
import DraggableEjercicios from "./DraggableEjercicios";
import Droppable from "./Droppable";
import { useParams } from 'react-router-dom';
import { Select, SelectItem, Avatar } from '@nextui-org/react';

const ModalEntreno = ({ accion, close, refresh }) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { handleError, handleSuccess } = useMessageContext();
  const { id } = useParams();
  const [ejercicios, setEjercicios] = useState([]);
  const [ejerciciosParaEntr, setEjerciciosParaEntr] = useState([]);
  const [musculo, setMusculo] = useState('Pecho');
  const [totalEjercicios, setTotalEjercicios] = useState(0);
  const [ejerciciosGuardar, setEjerciciosGuardar] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);




  const avatares = [
    {
      id: 1,
      avatar: 'https://cdn-icons-png.flaticon.com/128/249/249201.png'
    },
    {
      id: 2,
      avatar: 'https://cdn-icons-png.flaticon.com/128/249/249216.png'
    },
    {
      id: 3,
      avatar: 'https://cdn-icons-png.flaticon.com/128/249/249221.png'
    },
    {
      id: 4,
      avatar: 'https://cdn-icons-png.flaticon.com/128/249/249226.png'
    },
    {
      id: 5,
      avatar: 'https://app.40pluscoaching.es/uploads/pec.jpg'
    }


  ];


  useEffect(() => {
    if (accion) {
      onOpen();
      fetchEjercicios();
    }


    return () => {
      setEjercicios([]);
      setEjerciciosParaEntr([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accion]);

  const fetchEjercicios = async () => {
    if (await AuthService.validateToken(handleError)) {
      const ejerciciosData = await AdminService.getEjercicios();

      setTotalEjercicios(ejerciciosData);
      //del listado solo se muestran los ejercicios que coincidan con el musculo primero o secundario 
      setEjercicios(ejerciciosData.filter(ejercicio => ejercicio.primero === musculo || ejercicio.secundario === musculo));


    }
  };

  const handleGuardar = async () => {

    if (!selectedAvatar || !selectedTitle) {
      handleError('Debes seleccionar un avatar y un título para el entrenamiento');

      return;
    }

    if (ejerciciosGuardar.length === 0) {
      handleError('Debes agregar al menos un ejercicio al entrenamiento');

      return;
    }


    if (await AuthService.validateToken(handleError)) {
      const response = await AdminService.createEntrenamiento({
        avatar: selectedAvatar,
        title: selectedTitle,
        ejercicios: ejerciciosGuardar,
        idUser: id
      });

      if (response) {
        handleSuccess('Entrenamiento creado correctamente');
        handleClose();
        refresh();
      }
    }

  };


  const handleClose = () => {
    onClose();
    close();
    setEjerciciosParaEntr([]);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;


    if (over && active.id !== over.id) {
      // Encuentra el ejercicio arrastrado basado en su ID
      const draggedEjercicio = ejercicios.find(ejercicio => ejercicio.id === parseInt(active.id));

      if (draggedEjercicio) {
        // Elimina el ejercicio arrastrado de su estado anterior
        // setEjercicios(prev => prev.filter(ejercicio => ejercicio.id !== parseInt(active.id)));

        // Agrega el objeto completo del ejercicio arrastrado a su nuevo estado
        setEjerciciosParaEntr(prev => [...prev, draggedEjercicio]);
      }
    }
  };


  const handleChangeMusculo = (e) => {
    setMusculo(e.target.value);
    setEjercicios(totalEjercicios.filter(ejercicio => ejercicio.primero === e.target.value || ejercicio.secundario === e.target.value));
  };

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} onClose={handleClose} className="w-5/6 max-w-5xl">
        <ModalContent>
          <ModalHeader>Crear nuevo entrenamiento</ModalHeader>
          <ModalBody>
            <div className="flex gap-4 items-center">
              <Input type="text" label="Título del entrenamiento" placeholder="Push Day" onChange={(e) => setSelectedTitle(e.target.value)} />
              <Select
                items={avatares}
                label="Avatar"
                classNames={{
                  label: "group-data-[filled=true]:-translate-y-5",
                  trigger: "min-h-unit-16",
                  listboxWrapper: "max-h-[400px]",
                }}
                onChange={(e) => setSelectedAvatar(e.target.value)}
                className="w-28"
                popoverProps={{
                  classNames: {
                    base: "before:bg-default-200",
                    content: "p-0 border-small border-divider bg-background",
                  },
                }}
                renderValue={(items) => {
                  return items.map((item) => (

                    <Avatar
                      alt={item.data.name}
                      className="flex-shrink-0"
                      size="sm"
                      src={item.data.avatar}
                    />

                  ));
                }}
              >
                {(avatares) => (
                  <SelectItem key={avatares.id} textValue={avatares.name}
                  >

                    <Avatar className="flex-shrink-0" size="sm" src={avatares.avatar} />


                  </SelectItem>
                )}
              </Select>


            </div>
            <div className="mb-4">
              <Select
                label="Musculo"
                onChange={(e) => handleChangeMusculo(e)}
                defaultSelectedKeys={[musculo]}
              >
                <SelectItem value="Pecho" key={"Pecho"}>Pecho</SelectItem>
                <SelectItem value="Espalda" key={"Espalda"}>Espalda</SelectItem>
                <SelectItem value="Hombros" key={"Hombros"}>Hombros</SelectItem>
                <SelectItem value="Biceps" key={"Biceps"}>Biceps</SelectItem>
                <SelectItem value="Triceps" key={"Triceps"}>Triceps</SelectItem>
                <SelectItem value="Piernas" key={"Piernas"}>Piernas</SelectItem>
                <SelectItem value="Gluteos" key={"Gluteos"}>Gluteos</SelectItem>
                <SelectItem value="Abdominales" key={"Abdominales"}>Abdominales</SelectItem>
                <SelectItem value="Trapecio" key={"Trapecio"}>Trapecio</SelectItem>
                <SelectItem value="Femoral" key={"Femoral"}>Trapecio</SelectItem>
                <SelectItem value="Cuadriceps" key={"Cuadriceps"}>Cuádriceps</SelectItem>
                <SelectItem value="Aductores " key={"Aductores"}>Aductores</SelectItem>
                <SelectItem value="Gemelo" key={"Gemelo"}>Gemelo</SelectItem>
                <SelectItem value="Gemelo" key={"Soleo"}>Sóleo</SelectItem>
                <SelectItem value="Deltoides" key={"Deltoides"}>Deltoides</SelectItem>
              </Select>
            </div>

            <DndContext onDragEnd={handleDragEnd}>
              <div className="flex gap-2 flex-wrap mb-4">
                {ejercicios && ejercicios.map(ejercicio => (
                  <div style={{zIndex: 99}} key={ejercicio.id}>
                  <DraggableEjercicios key={ejercicio.id} id={ejercicio.id.toString()} ejercicio={ejercicio}/>
                  </div>
                ))}
              </div>
              <span className="text-xs text-gray-500 bloc k mt-2">Arrastra los ejercicios para agregarlos al entrenamiento</span>
              <Droppable children={ejerciciosParaEntr} guardar={setEjerciciosGuardar} />
            </DndContext>


          </ModalBody>
          <ModalFooter>
            <Button color="danger" variant="light" onPress={handleClose}>Cerrar</Button>
            <Button color="primary" onPress={handleGuardar}>Guardar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalEntreno;
