import { Card, CardBody } from '@nextui-org/react';


const ListNotificacionesUser = () => {



    return (
        <div className='pt-4 h-full'>
               <Card className="h-full px-2 py-2">

                <CardBody>
                    <p className="text-xs font-semibold text-gray-500">Notificaciones</p>
                    <div className='mt-2'>
                        <p className='text-sm font-light'>No hay notificaciones</p>
                    </div>

                </CardBody>

            </Card>
        </div>
    )
}
export default ListNotificacionesUser