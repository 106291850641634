
import { Button } from '@nextui-org/react';
import { FaCirclePlus } from "react-icons/fa6";
import AdminService from '../../../services/AdminService';
import AuthService from '../../../services/AuthService';
import { useMessageContext } from '../../../contexts/MessageContext';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import { MdDelete } from 'react-icons/md';
import { Tooltip } from '@nextui-org/react';
import ModalSuplementos from './ModalSuplementos';



const ListaSuplementos = () => {
    const { id } = useParams();
    const { handleError, handleSuccess } = useMessageContext();
    const [suplementos, setSuplementos] = useState(null);
    const [accion, setAccion] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        fetchSuplementos();
        // eslint-disable-next-line
    }, [refresh]);

    const fetchSuplementos = async () => {

        if (await AuthService.validateToken(handleError)) {
            const suplements = await AdminService.getSuplementos(id);
            setSuplementos(suplements);
        }

    }

    const handleDelete = async (idSuplemento) => {
        if (await AuthService.validateToken(handleError)) {
            const data = await AdminService.delSuplemento(idSuplemento);
            if (data) {
                handleSuccess("Suplemento eliminado correctamente");
                handleRefresh();
            }
        }
    }

    const handleRefresh = () => {
        setRefresh(!refresh);
    }


    return (
        <>
            <div className=' bg-default-50 dark:bg-default-50 h-full p-4 rounded-xl'>
                <div className='mb-4 flex flex-column align-center justify-between'>
                    <p className='text-xs text-gray-400 flex justify-center'>
                        Listado suplementos del usuario
                    </p>
                    <Button color='warning' className='p-1 self-end' onClick={() => setAccion("add")}>
                        <FaCirclePlus />
                    </Button>
                </div>

                <Table aria-label="Example static collection table">
                    <TableHeader>
                        <TableColumn>NOMBRE</TableColumn>
                        <TableColumn>NOTAS</TableColumn>
                        <TableColumn>URL</TableColumn>
                        <TableColumn>ELIMINAR</TableColumn>
                    </TableHeader>
                    <TableBody>
                        {
                            suplementos && suplementos.map((suplemento, index) => (
                                <TableRow key={index}>

                                    <TableCell>{suplemento.nombre}</TableCell>
                                    <TableCell>{suplemento.notas}</TableCell>
                                    <TableCell>
                                        <a href={suplemento.url} target='_blank' rel='noreferrer'
                                            className='text-blue-500 hover:underline'
                                        >Ver Suplemento</a>
                                    </TableCell>
                                    <TableCell>
                                        <Button color='error'>
                                            <Tooltip content="Eliminar suplemento" placement="top">
                                                <Button color="danger" onClick={()=>handleDelete(suplemento.id) } style={{ height: 40, width: 40, minWidth: 40, padding: 0, borderRadius: 50 }}><MdDelete /></Button>
                                            </Tooltip>
                                        </Button>
                                    </TableCell>

                      
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>

            </div>
            <ModalSuplementos accion={accion} close={() => setAccion(false)} id={id} handleRefresh={() => handleRefresh()} />
        </>

    )
}

export default ListaSuplementos;