// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { NextUIProvider } from "@nextui-org/react";
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { MessageProvider } from './contexts/MessageContext';
import { AuthProvider } from './contexts/AuthContext';
import { ColorModeProvider } from './contexts/ColorModeContext';
import {RevisionProvider} from './contexts/RevisionContext';
import MainContent from './components/MainContent';




const root = ReactDOM.createRoot(document.getElementById('root'));
//https://medium.com/@zilch0/gcc-install-gcc-4-9-4-on-centos-7-ce83f5240fe7
root.render(
  <React.StrictMode>
    <MessageProvider>
      <BrowserRouter>
        <AuthProvider>
          <ColorModeProvider>
           <RevisionProvider>
            <NextUIProvider>
              <MainContent />
            </NextUIProvider>
            </RevisionProvider>
          </ColorModeProvider>
        </AuthProvider>
      </BrowserRouter>
    </MessageProvider>
  </React.StrictMode>
);
