import { Card,  CardHeader } from '@nextui-org/react';
import Draggable from './Draggable';
import { IoMenuOutline } from "react-icons/io5";

const DraggableEjercicios = ({ ejercicio, id }) => {
    return (
        <Draggable key={ejercicio.id} id={ejercicio.id} className='w-fit'>
        <Card className='w-fit z-10'>
            <CardHeader className='flex items-center gap-2'>
                <p
                    className='text-xs text-gray-500 flex justify-center'
                >{ejercicio.nombre}</p>
                <IoMenuOutline className='text-gray-500' />
            </CardHeader>
        </Card>
    </Draggable>
    )
}

export default DraggableEjercicios;