import { Button } from "@nextui-org/react";
import { FaDownload } from "react-icons/fa6";
import DietaService from "../../services/DietaService";

const DescargarDieta = () => {

    const  handleDescargar = async () => {

        let json = await DietaService.descargarDieta();
      
        let url_pdf = json.pdf;

  
        const link = document.createElement('a');
        link.href = url_pdf;
        link.setAttribute('download', 'dieta.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        
    }   



    return (
        <div className="flex justify-center items-center h-20 mt-2">
               <Button color="warning" startContent={<FaDownload />} className="w-1/2" onClick={handleDescargar}>
                    Descargar dieta
                </Button>
        </div>
    )
}
export default DescargarDieta