
import Header from "../components/Header";
import TablaDieta from "../components/nutricion/TablaDieta";
import ModalAlimentos from "../components/nutricion/ModalAlimentos";
import ListadoAlimentos from "../components/nutricion/ListadoAlimentos";
import { useState } from "react";
import AcalaraionesDieta from "../components/nutricion/AclaraciónesDieta";
import TablaSuplementos from "../components/nutricion/TablaSuplementos";
import DescargarDieta from "../components/nutricion/DescargarDieta";

const Dieta = () => {
    const [accioSeleccionada, setAccioSeleccionada] = useState(null);


    const handleChangeAccio = (tipo) => {
        setAccioSeleccionada(tipo);
    }

    const clearTipo = () => {
        setAccioSeleccionada(null)
    }


    return (
        <div className="h-full">
            <Header />
            <ListadoAlimentos handleChangeAccio={handleChangeAccio} />
            <AcalaraionesDieta />
            <TablaDieta handleChangeAccio={handleChangeAccio} />
            <TablaSuplementos />
            <DescargarDieta />
            <ModalAlimentos tipo={accioSeleccionada} clearTipo={clearTipo} />
     


        </div >
    )
}
export default Dieta