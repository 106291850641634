import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { useEffect, useState } from "react";
import DietaService from "../../services/DietaService";
import { FaEye } from "react-icons/fa";

const TablaDieta = ({ handleChangeAccio }) => {
    const [dieta, setDieta] = useState([]);

    useEffect(() => {
        getDieta();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDieta = async () => {
        const data = await DietaService.getDieta();
        const parsedData = data.map(item => ({
            ...item,
            alimentos: JSON.parse(item.alimentos)
        }));
        setDieta(parsedData);
    }

    return (
        <div className="h-full w-full p-4 ">
            {dieta.length === 0 ? <p className="text-center ">No hay dieta programada</p> :
                dieta.map((comida) => (
                    <div key={comida.id} className="mb-4">
                        <p className="text-xs text-default-500 mb-1 ml-2">
                            {comida.nombre}
                        </p>
                        <Table key={comida.id} className="mb-4">
                            <TableHeader>
                                <TableColumn>Tipo</TableColumn>
                                <TableColumn>Cantidad</TableColumn>
                                <TableColumn>Alimentos</TableColumn>
                            </TableHeader>
                            <TableBody>
                                {
                                    comida.alimentos.map((alimento) => (
                                        <TableRow key={alimento.tipoID}
                                            className="text-white text-xs m-1"
                                            style={{
                                                backgroundColor:
                                                    alimento.tipoID === 1 ? "#002554" :
                                                        alimento.tipoID === 2 ? "#d22630" :
                                                            alimento.tipoID === 3 ? "#ffc845" :
                                                                alimento.tipoID === 4 ? "pink" :
                                                                    alimento.tipoID === 5 ? "#888b8d" :
                                                                        alimento.tipoID === 6 ? "#e4d5d3" :
                                                                            alimento.tipoID === 7 ? "#50a684" :
                                                                                alimento.tipoID === 8 ? "#a83d72" :
                                                                                    "gray"
                                            }}

                                        >
                                            <TableCell
                                                className="text-white text-center"
                                                style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}
                                            >{alimento.tipo}</TableCell>
                                            <TableCell
                                                className="text-white text-center "
                                            >x {alimento.cantidad}</TableCell>
                                            <TableCell
                                                className="text-white text-center "
                                                style={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}
                                            >
                                                <div className="flex items-center justify-center">
                                                    <FaEye className="text-xl cursor-pointer" onClick={() => handleChangeAccio(alimento.tipoID)} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </div>
                ))
            }
        </div>
    )
}
export default TablaDieta;