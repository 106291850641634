import Header from "../../components/admin/Header";
import TablaUsuarios from "../../components/admin/users/TablaUsuarios";
import Acciones from "../../components/admin/users/Acciones";
import Modal from "../../components/admin/users/Modal";
import { useState } from "react";

const Usuarios = () => {
    const [accioSeleccionada, setAccioSeleccionada] = useState(null);
    const [refresh, setRefresh] = useState(0);


    const handleChangeAccio = (tipo) => {
        setAccioSeleccionada(tipo);
    }

    const setTipo = () => {
        setAccioSeleccionada(null)
    }
    const handleRefresh = () => {
        let random = Math.floor(Math.random() * 1000);
        console.log(random);
           setRefresh(random);
       }

    return (
        <>
            <Header />
            <div className="p-14">
                <div className="w-fit">
                    <Acciones handleChangeAccio={handleChangeAccio} />
                </div>
                <div className="mt-4 mx-auto">

                    <TablaUsuarios handleRefresh={handleRefresh} refresh={refresh} />
                    <Modal tipoModal={accioSeleccionada}  setTipo={setTipo} handleRefresh={handleRefresh} />
                </div>
            </div>
        </>
    )
}
export default Usuarios