

const AdminService = {
  getUsers: async () => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },

      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  editarEjercicio: async (id, edit) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/editejercicio/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },
        body: JSON.stringify(edit),
      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },


  getEjercicio: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/ejercicio/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },

      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },


  consultarUsuario: async (nombre) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/comprobarusers/${nombre}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },

      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      throw new Error(error.message);
    }
  },
  registrarUsuario: async (usuario, tipoUsuario, sexo) => {
    usuario.tipoUsuario = tipoUsuario;
    usuario.sexo = sexo;
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/registrar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },
        body: JSON.stringify(usuario, tipoUsuario),
      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      throw new Error(error.message);
    }
  },
  delUsuario: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/eliminar/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },

      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      throw new Error(error.message);
    }
  },
  getUserInfo: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/userinfo/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },

      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      throw new Error(error.message);
    }
  },
  getImc: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/imc/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },

      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      throw new Error(error.message);
    }
  },
  getEjercicios: async () => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/ejercicios`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },

      });

      if (response.ok) {
        const ejerciciosData = await response.json();
        return ejerciciosData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  registrarEjercicio: async (usuario) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/addejercicio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },
        body: JSON.stringify(usuario),
      });

      if (response.ok) {
        const ejerciciosData = await response.json();
        return ejerciciosData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      throw new Error(error.message);
    }
  },
  delEjercicio: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/eliminarejercicio/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },

      });

      if (response.ok) {
        const ejercicioData = await response.json();
        return ejercicioData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      throw new Error(error.message);
    }
  },

  entrenamientosUsuario: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/entrenamientosusuario/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },

      });

      if (response.ok) {
        const entrenamientosData = await response.json();
        return entrenamientosData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      throw new Error(error.message);
    }
  },
  getAlimentos: async () => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/alimentos`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const alimentosData = await response.json();
        return alimentosData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  addAlimento: async (nombre, cantidad, tipoAlimento) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/addalimento`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },
        body: JSON.stringify({ nombre, cantidad, tipoAlimento }),
      });

      if (response.ok) {
        const alimentosData = await response.json();
        return alimentosData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  getAlimento: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/alimento/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const alimentosData = await response.json();
        return alimentosData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  editAlimento: async (id, nombre, cantidad, tipoAlimento) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/editalimento/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },
        body: JSON.stringify({ nombre, cantidad, tipoAlimento }),
      });

      if (response.ok) {
        const alimentosData = await response.json();
        return alimentosData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  delAlimento: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/eliminaralimento/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const alimentosData = await response.json();
        return alimentosData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  cantidadUsuarios: async () => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/cantidadusuarios`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },

      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  cantidadEjercicios: async () => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/cantidadejercicios`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },

      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      if (error.message === 'Error en la autenticación') {
        localStorage.removeItem('40coaching_token');
        localStorage.removeItem('40coaching_admin');
        window.location.href = '/login';
      }
      throw new Error(error);
    }
  },
  infoConexiones: async () => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/conexionestabla`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },

      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);

    }
  },
  conexiones: async () => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/conexiones`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token,
        },

      });

      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  getDieta: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/dieta/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const dietaData = await response.json();
        return dietaData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  updateDieta: async (dieta) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/updatedieta`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },
        body: JSON.stringify(dieta),
      });

      if (response.ok) {
        const dietaData = await response.json();
        return dietaData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  delDieta: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/eliminardieta/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const dietaData = await response.json();
        return dietaData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  addDieta: async (dieta) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/adddieta`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },
        body: JSON.stringify(dieta),
      });

      if (response.ok) {
        const dietaData = await response.json();
        return dietaData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  createEntrenamiento: async (entrenamiento) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/addentrenamiento`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },
        body: JSON.stringify(entrenamiento),
      });

      if (response.ok) {
        const entrenamientoData = await response.json();
        return entrenamientoData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  getSuplementos: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/suplementos/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const suplementosData = await response.json();
        return suplementosData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  delSuplemento: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/eliminarsuplemento/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const suplementosData = await response.json();
        return suplementosData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  addSuplemento: async (suplemento) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/addsuplemento`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },
        body: JSON.stringify(suplemento),
      });

      if (response.ok) {
        const suplementosData = await response.json();
        return suplementosData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  getRecetas: async () => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/recetas`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const recetasData = await response.json();
        return recetasData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  addReceta: async (receta) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    const formData = new FormData();
    formData.append('archivo', receta['file']);
    formData.append('titulo', receta['titulo']);
    formData.append('img', receta['img']);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/addreceta`, {
        method: 'POST',
        headers: {
          Token: token
        },
        body: formData,
      });

      if (response.ok) {
        const recetasData = await response.json();
        return recetasData;
      } else {
        console.error('Error de red');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
    }
  },
  deleteReceta: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/eliminarreceta/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const recetasData = await response.json();
        return recetasData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  deleteEntreno: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/deleteEntreno/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const recetasData = await response.json();
        return recetasData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  deleteEntrenoEliminar: async (id, idEntreno) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/deleteEntrenoEliminar/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const recetasData = await response.json();
        return recetasData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }

  },
  getPasos: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/pasos/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const pasosData = await response.json();
        return pasosData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  updatePasos: async (id, pasos) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/editpasos/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },
        body: JSON.stringify({ pasos }),
      });

      if (response.ok) {
        const pasosData = await response.json();
        return pasosData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  getArchivos: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/archivos/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const archivosData = await response.json();
        return archivosData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  getEntreno: async (id) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/entreno/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const entrenoData = await response.json();
        return entrenoData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  EditEntrenamiento: async (entrenamiento) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/editentreno`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },
        body: JSON.stringify(entrenamiento),
      });

      if (response.ok) {
        const entrenoData = await response.json();
        return entrenoData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  getImagenes: async () => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/imagenes`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const imagenesData = await response.json();
        return imagenesData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  addUpload : async (upload, handleError) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    const formData = new FormData();
    formData.append('archivo', upload['file']);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/addupload`, {
        method: 'POST',
        headers: {
          Token: token
        },
        body: formData,
      });

      if (response.ok) {
        const uploadData = await response.json();
        return uploadData;
      } else {
        console.error('Error de red');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
    }
  },
  EnviarArchivo: async (id, archivo) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    const formData = new FormData();
    formData.append('archivo', archivo['file']);
    formData.append('titulo', archivo['titulo']);
    formData.append('descripcion', archivo['descripcion']);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/addarchivo/${id}`, {
        method: 'POST',
        headers: {
          Token: token
        },
        body: formData,
      });

      if (response.ok) {
        const archivosData = await response.json();
        return archivosData;
      } else {
        console.error('Error de red');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
    }
  },
  deleteUploads: async (id, handleError) => {
    let token = localStorage.getItem('40coaching_token');
    if (token.startsWith('"') && token.endsWith('"')) {
      token = token.slice(1, -1);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/eliminarupload/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Token: token
        },

      });

      if (response.ok) {
        const uploadData = await response.json();
        return uploadData;
      } else {
        throw new Error('Error en la autenticación');
      }
    } catch (error) {
      console.error('Error de red:', error.message);
      throw new Error(error.message);
    }
  }

}

export default AdminService;
