import { useState } from 'react'
import { FileUploader } from "react-drag-drop-files";
import { Spacer, Input } from '@nextui-org/react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import UserService from '../../services/UsersService';
import AuthService from '../../services/AuthService';
import { useMessageContext } from '../../contexts/MessageContext';
const fileTypes = ["JPG", "PNG", "GIF", "PDF"];


const DocumentosForm = ({ archivosActualizados }) => {
    const [file, setFile] = useState(null);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [titulo, setTitulo] = useState(null);
    const [descripcion, setDescripcion] = useState(null);
    const { handleUnauthenticated, handleError, handleSuccess } = useMessageContext();

    const handleChange = (file) => {
        setFile(file);
        setTitulo(file?.name.split('.').slice(0, -1).join('.'))

        onOpen();
    };


    const handleGuardar = async () => {


        let arxivo = {
            titulo: titulo,
            descripcion: descripcion,
            file: file
        }

        if (await AuthService.validateToken(handleError)) {
            const res = await UserService.EnviarArchivo(arxivo, handleUnauthenticated, handleError, handleSuccess);
            if (res) {
                setFile(null)
                setTitulo(null)
                setDescripcion(null)
                onOpenChange()

                archivosActualizados(Math.random())
            }
        }

    }

    return (
        <>

            <div className="mt-2">
                <p className='text-xs mb-1'>Sube un documento</p>
                <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
            </div>
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                placement="bottom-center"
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader>Información del documento</ModalHeader>
                            <ModalBody>
                                <p className='text-xs'>Añadele un titulo a tu documento</p>
                                <Input type="text" placeholder="Titulo" value={titulo} onChange={(e) => setTitulo(e.target.value)} />
                                <Spacer y={1} />
                                <p className='text-xs'>Añade una descripción</p>
                                <Input type="text" placeholder="Ej: Analítica 01/01/2024" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} />
                            </ModalBody>
                            <ModalFooter>

                                <Button color="danger" auto onClick={() => {
                                    setFile(null)
                                    onClose()
                                }
                                }>
                                    Cancelar
                                </Button>
                                <Button color="primary" onPress={handleGuardar}>
                                    Guardar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}
export default DocumentosForm