
import { Button } from '@nextui-org/react'
import Tools from '../../tools/tools'

const ListadoAlimentos = ({ handleChangeAccio }) => {


    return (
        <div className="w-full flex items-center gap-1 overflow-x-auto pt-1 pb-1 pr-0.5 pl-0.5">
            {
                Array.from({ length: 8 }, (_, i) => {
                    const tipoInfo = Tools.getInfoAlimento(i + 1);
              
               
                    return (
                        <Button
                            value={i + 1}
                            key={i + 1}
                            className={`w-fit flex-shrink-0 text-white`}
                            style={{ backgroundColor: tipoInfo?.color }}
                            onClick={() => handleChangeAccio(i + 1)}
                        >
                            {tipoInfo.info}
                        </Button>
                    );
                })
            }
        </div >
    )
}
export default ListadoAlimentos