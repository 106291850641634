import UserService from "../../services/UsersService";
import AuthService from "../../services/AuthService";
import { useEffect, useState } from "react";
import { useMessageContext } from '../../contexts/MessageContext';
import { Progress } from "@nextui-org/react";
import Tools from "../../tools/tools";
import { Link } from "@nextui-org/react";
import { Card } from '@nextui-org/react';

const Datos = () => {
    const { handleUnauthenticated,handleError } = useMessageContext();
    const [datos, setDatos] = useState(null);
    const [formulario, setFormulario] = useState(null);
    const [totalDias, setTotalDias] = useState(0);
    const [diasRestantes, setDiasRestantes] = useState(0);

    useEffect(() => {

        const fetchData = async () => {
            try {
                if (await AuthService.validateToken(handleError)) {
                    const response = await UserService.getInfoPerfil(handleUnauthenticated);
                    if (response) {
                        setDatos(response.datos);
                        setFormulario(response.formulario);
                        const dias = Tools.fechaToDias(Tools.sqlToJsDate(response.formulario.fecha), Tools.sqlToJsDate(response.datos.caducidad));
                        setTotalDias(dias);
                        const restantes = Tools.fechaToDias(Tools.sqlToJsDate(response.datos.caducidad), new Date());
                        setDiasRestantes(dias - restantes);
                    }
                }
            }
            catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [handleUnauthenticated]);

    return (
        datos ? (
            <>
                <div className="container mx-auto p-6">
                    <span className="font-light text-sm">Bienvenido</span> {datos?.nombre && <h1 className="text-xl"> {datos?.nombre} {datos?.apellidos}</h1>}
                    <div className="flex flex-wrap justify-between mt-4 bg-inherit">
                        <Card shadow className="w-full p-4">
                            <Progress
                                label="Has consumido el"
                                size="sm"
                                value={diasRestantes}
                                maxValue={totalDias}
                                color="warning"
                                showValueLabel={true}
                                className="max-w-md"
                            />
                            <p className="text-xs text-gray-500 mt-2">Tu suscripción termina el {Tools.sqlToJsDate(datos?.caducidad).toLocaleDateString()}</p>
                        </Card>
                        <Card shadow className="w-full p-4 mt-4">
                            <h2 className="text-sm">Datos de tu cuenta</h2>
                            <p className="text-xs text-gray-500 mt-2">
                                Nombre de usuario: <span className="font-semibold text-default-500">{datos?.user}</span>
                            </p>
                            <p className="text-xs text-gray-500 mt-2">
                                Email: <span className="font-semibold text-default-500">{datos?.email}</span>
                            </p>
                            <p className="text-xs text-gray-500 mt-2">
                                Telefono: <span className="font-semibold text-default-500">{datos?.telefono}</span>
                            </p>
                            <p className="text-xs text-gray-500 mt-2">
                                Contraseña: <span className="font-semibold text-default-500"> <Link className="font-semibold text-default-500 text-xs" href="#" showAnchorIcon>modificar-la</Link></span>
                            </p>

                        </Card>

                        <Card shadow className="w-full p-4 mt-4">
                            <h2 className="text-sm">Información sobre tí</h2>
                            <p className="text-xs text-gray-500 mt-2">
                                Alergias: <span className="font-semibold text-default-500">{formulario?.alergias}</span>
                            </p>
                            <p className="text-xs text-gray-500 mt-2">
                                Enfermedades: <span className="font-semibold text-default-500">{formulario?.enfermedad}</span>
                            </p>
                            <p className="text-xs text-gray-500 mt-2">
                                Colesterol: <span className="font-semibold text-default-500">{formulario?.colesterol}</span>
                            </p>
                            <p className="text-xs text-gray-500 mt-2">
                                Profesion: <span className="font-semibold text-default-500">{formulario?.profesion}</span>
                            </p>
                            <p className="text-xs text-gray-500 mt-2">
                                Sueles hacer deporte: <span className="font-semibold text-default-500">{formulario?.deporte}</span>
                            </p>
                            <p className="text-xs text-gray-500 mt-2">
                                ¿Que momento sueles tener mas hambre?: <span className="font-semibold text-default-500">{formulario?.hambre}</span>
                            </p>

                        </Card>
                        <p className="text-xs text-gray-500 mt-2 text-center w-full">
                            ¿Quieres cambiar algun dato?: <span className="font-semibold text-default-500"><Link className="font-semibold text-default-500 text-xs" href="#" showAnchorIcon>Contactar</Link> </span>
                        </p>


                    </div>
                </div>
            </>
        ) : null
    )
}
export default Datos