import { Button } from '@nextui-org/react';
import { FaCirclePlus } from "react-icons/fa6";
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminService from '../../../services/AdminService';
import AuthService from '../../../services/AuthService';
import { useMessageContext } from '../../../contexts/MessageContext';
import { useEffect } from 'react';
import { Card, CardHeader, CardBody, Image } from "@nextui-org/react";
import pdf from '../../../assets/icons/pdf.png';
import jpg from '../../../assets/icons/jpg.png';
import png from '../../../assets/icons/png.png';
import ModalArchivos from './ModalArchivos';

const ListadoArchivos = () => {
    const { handleError } = useMessageContext();
    const { id } = useParams();
    const [accion, setAccion] = useState(false);
    const [archivos, setArchivos] = useState(null);
    const [refresh, setRefresh] = useState(false);


    useEffect(() => {
        fetchArchivos();
        // eslint-disable-next-line
    }
        , [refresh]);

    const fetchArchivos = async () => {

        if (await AuthService.validateToken(handleError)) {
            const archivosList = await AdminService.getArchivos(id);
            setArchivos(archivosList);

        }

    }

    const handleRefresh = () => {
        setRefresh(!refresh);
    }



    return (
        <div className=' bg-default-50 dark:bg-default-50 h-full p-4 rounded-xl w-full'>
            <div className='mb-4 flex flex-column align-center justify-between'>
                <p className='text-xs text-gray-400 flex justify-center'>
                    Listado archivos del usuario
                </p>
                <Button color='warning' className='p-1 self-end' onClick={() => setAccion("add")}>
                    <FaCirclePlus />
                </Button>

            </div>

            {
                archivos &&
                    archivos.length > 0 ? (
                    <div className='flex gap-4 flex-wrap'>
                        {archivos.map((_, index) => (
                            <Card key={index} className="py-4 cursor-pointer w-36 h-36">
                                <CardHeader className="pb-0 pt-2 px-4 flex-col items-start" onClick={() => window.open(_.ruta + '/' + _.nombre, "_blank")}>
                                    <p className="text-xs font-bold ">{_.nombre.split('.')[0]}</p>
                                    <small className="text-default-500">{_.descripcion}</small>
                                </CardHeader>
                                <CardBody className="overflow-visible" onClick={() => window.open(_.ruta + '/' + _.nombre, "_blank")}>
                                    <Image
                                        alt="Card background"
                                        className="object-cover rounded-xl"
                                        src={_.nombre.split('.')[1] === "pdf" ? pdf : _.nombre.split('.')[1] === "jpg" ? jpg : png}
                                        width={30}
                                    />
                                </CardBody>
                            </Card>

                        ))}
                    </div>
                ) : (
                    <p>No hay documentos</p>
                )
            }
            <ModalArchivos accion={accion} setAccion={setAccion} close={() => setAccion(false)} handleRefresh={handleRefresh} />
        </div>
    )
}
export default ListadoArchivos