import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, User, Chip } from "@nextui-org/react";
import AdminService from '../../../services/AdminService'
import AuthService from "../../../services/AuthService";
import { useMessageContext } from '../../../contexts/MessageContext';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tools from "../../../tools/tools";


const Conexiones = () => {

    const navigate = useNavigate();
    const [conexiones, setConexiones] = useState(0);
    const { handleError } = useMessageContext();


    useEffect(() => {
        loadConexiones();
    }
        // eslint-disable-next-line
        , [])

    const loadConexiones = async () => {
        if (await AuthService.validateToken(handleError)) {
            const infoConexiones = await AdminService.infoConexiones();
            if (infoConexiones) {
                setConexiones(infoConexiones);
            }

        }
    }

    return (
        <div>
            <Card className="w-full px-9 py-4">
                <CardHeader className="flex flex-col items-center">
                    <h2
                        className="text-small font-medium text-default-500 text-center"
                    >Últimas conexiones</h2>
                </CardHeader>

                <CardBody>
                    <Table>
                        <TableHeader>
                            <TableColumn align="left">Usuario</TableColumn>
                            <TableColumn align="left">Ult. Conexión</TableColumn>
                            <TableColumn align="left">Caducidad</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {
                                conexiones && conexiones.map((conexion) => (
                                    <TableRow>
                                        <TableCell onClick={() => navigate('/usuario/' + conexion.id)} className="cursor-pointer">
                                            <User
                                                name={conexion.nombre + " " + conexion.apellidos}
                                                textBottom={conexion.usuario}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <span className="text-default-500 text-xs font-light">{Tools.sqlToJsDate(conexion.fecha).toLocaleString()} </span>
                                        </TableCell>
                                        <TableCell>
                                            <span className="text-default-500 text-sm font-light">
                                                <Chip color="secondary" size="small">{Tools.sqlToJsDate(conexion.caducidad).toLocaleDateString()} </Chip>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }

                        </TableBody>
                    </Table>
                </CardBody>

            </Card>
        </div>
    )
}
export default Conexiones