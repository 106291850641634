import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';
import { useRef, useEffect } from "react";
import { Card, CardBody } from '@nextui-org/react';
import AdminService from '../../../services/AdminService'
import AuthService from "../../../services/AuthService";
import { useMessageContext } from '../../../contexts/MessageContext';
import { useState } from 'react';


Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale);

const Grafico = () => {
    const { handleError } = useMessageContext();
    const chartRef = useRef(null);
    const [actividad, setActividad] = useState([]);


    useEffect(() => {
        loadActividad();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const loadActividad = async () => {
        if (await AuthService.validateToken(handleError)) {
            const actividad = await AdminService.conexiones();
            if (actividad) {
                console.log(actividad);
                setActividad(actividad);
            }
        }
    }





    useEffect(() => {
        const chart = chartRef.current;
        const labels = [...new Set(actividad.map(a => new Date(a.fecha).toLocaleDateString()))];
        const data = actividad.reduce((acc, a) => {
            const fecha = new Date(a.fecha).toLocaleDateString();
            if (!acc[fecha]) {
                acc[fecha] = 0;
            }
            acc[fecha]++;
            return acc;
        }, {});
        let config = {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Actividad',
                    data: data,
                    fill: false,
                    borderColor: "#e69c1a",
                    tension: 0.1
                }]
            },
            options: {
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                    },
                },
            },
        };
        const myChart = new Chart(chart, config);
        return () => {
            myChart.destroy();
        };
    }, [actividad])

    return (
        <Card className="w-full mt-4">
            <CardBody>
                <canvas ref={chartRef} style={{ width: "100%", height: "100px" }}></canvas>
            </CardBody>
        </Card>
    )
}

export default Grafico;
