import React from "react";
import {  Button } from "@nextui-org/react";
import { AcmeLogo } from "../LogoWhite.jsx";
import { BlackLogo } from "../LogoBlack.jsx";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem } from "@nextui-org/navbar";
import { useAuthContext } from '../../contexts/AuthContext';
import { FaSun, FaMoon } from 'react-icons/fa';
import { useColorMode } from '../../contexts/ColorModeContext';
import { useNavigate } from 'react-router-dom';
import { Link as LinkR } from "react-router-dom";


export default function Header() {

  const { logout } = useAuthContext();
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();

  const menuItems = [
    "Dashboard",
    "Usuarios",
    "Entrenos",
    "Dietas",
    "Configuración",
    "Log Out",
  ];
  const handleClick = (item) => {
    switch (item) {
      case "Dashboard":
        navigate('/');
        break;
      case "Usuarios": 
        navigate('/usuarios')
        break;
      case "Log Out":
        logout();
        break;
      default:
        break;
    }
  }

  return (
    <Navbar isBordered>
      <NavbarContent className="sm:hidden" justify="start">
        <NavbarMenuToggle />
      </NavbarContent>

      <NavbarContent className="sm:hidden pr-3" justify="center">
        <NavbarBrand onClick={() => navigate('/')}>
          {
            colorMode === 'dark' ? <AcmeLogo /> : <BlackLogo />
          }
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarBrand onClick={() => navigate('/')}>
          {
            colorMode === 'dark' ? <AcmeLogo /> : <BlackLogo />
          }
        </NavbarBrand>
        <NavbarItem>
          <LinkR color="primary" to="/">
            Dashboard
          </LinkR>
        </NavbarItem>
        <NavbarItem>
          <LinkR to="/usuarios" color="foreground">
            Usuarios
          </LinkR>
        </NavbarItem>
        <NavbarItem>
       
          <LinkR to="/ejercicios" color="foreground">
            Ejercicios
          </LinkR>
        </NavbarItem>
        <NavbarItem>

          <LinkR to="/alimentos" color="foreground">
            Alimentos
          </LinkR>
        </NavbarItem>
        <NavbarItem>
          <LinkR color="foreground" href="#">
            Configuración
          </LinkR>
        </NavbarItem>
      </NavbarContent>

      <NavbarContent justify="end">
        <NavbarItem>
          <Button color="warning" variant="flat" onClick={() => logout()} >
            Logout 📤
          </Button>
        </NavbarItem>
        <NavbarItem onClick={toggleColorMode}>
          {colorMode === 'dark' ? <FaSun /> : <FaMoon />}
        </NavbarItem>
      </NavbarContent>

      <NavbarMenu>
        {menuItems.map((item, index) => (
          <NavbarMenuItem key={`${item}-${index}`}>
            <LinkR
              className="w-full"
              color={
                index === menuItems.length - 1 ? "danger" : "foreground"
              }
              onClick={() => handleClick(item)}
              size="lg"
            >
              {item}
            </LinkR>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>
  );
}
