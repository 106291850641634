import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { useEffect } from "react";
import { Input } from "@nextui-org/react";
import AdminService from '../../../services/AdminService'
import AuthService from "../../../services/AuthService";
import { useMessageContext } from '../../../contexts/MessageContext';
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";





export default function ModalUploads({ tipoModal, setTipo, handleRefresh, idEdit, setId }) {
    const { handleError } = useMessageContext();
    const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "SVG", "WEBP"];
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const [file, setFile] = useState(null);

    

    useEffect(() => {
        if (tipoModal) {
            onOpen()
        }
    


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoModal]);

   

    const handleClose = () => {
        setTipo();
        onClose();
    }
    const handleGuardar = async () => {
        if (tipoModal === 'add') {
            if (await AuthService.validateToken(handleError)) {
              let upload = {
                file: file
              }
                const res = await AdminService.addUpload(upload, handleError);
                if (res) {
                    setFile(null)

                    onClose()
                }

            }
        }

    
        handleClose();
        handleRefresh();
    }

    const handleChange = (file) => {
        setFile(file);

        onOpen();
    };


return (
    <>
        <Modal isOpen={isOpen} onOpenChange={onOpenChange} onClose={handleClose}>
            <ModalContent>
                {tipoModal === 'add' && (
                    <>
                        <ModalHeader>Subir imagen</ModalHeader>

                        <ModalBody>
                            <p className='text-xs'>Arrastra o haz click para subir una imagen al servidor</p>
                            <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                            {file && ( <p className='text-xs text-primary-500'>Archivo subido: <b>{file.name}</b></p>)}                                
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={handleClose}>
                                Cerrar
                            </Button>
                            <Button color="primary" onPress={handleGuardar}>
                            Guardar
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    </>
);
}