import Header from "../../components/admin/Header";
import TablaUploads from "../../components/admin/uploads/TablaUploads";
import Acciones from "../../components/admin/uploads/Acciones";
import Modal from "../../components/admin/uploads/Modal";
import { useState } from "react";



const Uploads = () => {
    const [accioSeleccionada, setAccioSeleccionada] = useState(null);
    const [refresh, setRefresh] = useState(0);
  
    


    const handleChangeAccio = (tipo,id) => {
        setAccioSeleccionada(tipo);
     
    }
    const handleRefresh = () => {
     let random = Math.floor(Math.random() * 1000);
        setRefresh(random);
    }

    const setTipo = () => {
        setAccioSeleccionada(null)
    }



    return (
        <>
            <Header />
            <div className="p-14">
                <div className="w-fit">
                <Acciones  handleChangeAccio={handleChangeAccio} />
                </div>
                <div className="mt-4 mx-auto">
                   <TablaUploads  handleRefresh={handleRefresh} refresh={refresh} handleChangeAccio={handleChangeAccio} />
                   <Modal tipoModal={accioSeleccionada}  setTipo={setTipo} handleRefresh={handleRefresh}/>
                </div>
            </div>
        </>
    )
}
export default Uploads