// contexts/ColorModeContext.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';

const ColorModeContext = createContext();

export const useColorMode = () => {
  const context = useContext(ColorModeContext);
  if (!context) {
    throw new Error('useColorMode debe ser utilizado dentro de un ColorModeProvider');
  }
  return context;
};

export const ColorModeProvider = ({ children }) => {
  // Leer el modo de color actual de localStorage al cargar la aplicación
  const initialColorMode = localStorage.getItem('colorMode') || 'light';
  const [colorMode, setColorMode] = useState(initialColorMode);

  // Cambiar el modo de color y almacenarlo en localStorage
  const toggleColorMode = () => {
    const newColorMode = colorMode === 'light' ? 'dark' : 'light';
    setColorMode(newColorMode);
    localStorage.setItem('colorMode', newColorMode);
  };

  // Efecto secundario para asegurarse de que el colorMode esté actualizado después de la carga

  useEffect(() => {
    localStorage.setItem('colorMode', colorMode);
  }, [colorMode]);

  const contextValue = {
    colorMode,
    toggleColorMode,
  };

  return (
    <ColorModeContext.Provider value={contextValue}>
      {children}
    </ColorModeContext.Provider>
  );
};
