import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import AuthService from "../services/AuthService";
import logo from "../assets/logo.png";
import { EyeFilledIcon } from "../components/login/EyeFilledIcon";
import { EyeSlashFilledIcon } from "../components/login/EyeSlashFilledIcons";
import { useNavigate } from 'react-router-dom';
import { useMessageContext } from '../contexts/MessageContext';
import { Card, CardHeader, CardBody, CardFooter, Divider, Link, Image, Input, Button } from "@nextui-org/react";

const ResetPassword = () => {

    const { hash } = useParams();
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const { handleError, handleSuccess } = useMessageContext();

    const navigate = useNavigate();

    useEffect(() => {
        //comprobar si el hash es correcto
        comprobarHash(hash)

    }, [hash]);



    const toggleVisibility = () => setIsVisible(!isVisible);
    const toggleVisibility2 = () => setIsVisible2(!isVisible2);

    const comprobarHash = async (hash) => {
        const respuesta = await AuthService.comprobarHash(hash);
        if (respuesta.code !== 200) {
            handleError("Ha ocurrido un error");
            navigate("/login");
        }


    }
    const handleEnviar = async () => {
        //primero comprobamos que las contraseñas sean iguales
        if (password !== password2) {
            handleError("Las contraseñas no coinciden");
            return;
        }
        //comprobamos que la contraseña tenga al menos 8 caracteres
        if (password.length < 8) {
            handleError("La contraseña debe tener al menos 8 caracteres");
            return;
        }
        //enviamos la contraseña
        const respuesta = await AuthService.resetPasswordConHash({ hash, password });
        if (respuesta.code === 200) {
            handleSuccess("Contraseña restablecida correctamente");
            navigate("/login");
        } else if (respuesta.code === 401) {
            handleError("La contraseña no puede ser igual a la anterior");
        } else {
            handleError("Ha ocurrido un error");
            navigate("/login");
        }
    }


    return (
        <main className="flex flex-col items-center justify-center w-full h-screen ">
            <div className="">
                {
                    <div>
                        <Card className="max-w-[400px]" >
                            <CardHeader className="flex gap-3">
                                <Image
                                    alt="nextui logo"
                                    height={40}
                                    radius="sm"
                                    src={logo}
                                    width={40}
                                />
                                <div className="flex flex-col">
                                    <p className="text-md">Restablecer contraseña</p>
                                    <p className="text-small text-default-500">app.40pluscoaching.es</p>
                                </div>
                            </CardHeader>
                            <Divider />
                            <CardBody className="flex" >
                                <Input
                                    label="Contraseña"
                                    placeholder="Tu contraseña"
                                    onChange={(e) => setPassword(e.target.value)} value={password}
                                    endContent={
                                        <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                            {isVisible ? (
                                                <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                            ) : (
                                                <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                            )}
                                        </button>
                                    }
                                    type={isVisible ? "text" : "password"}
                                    className="mb-2"
                                />
                                <Input
                                    label="Repite la contraseña"
                                    placeholder="Repite tu contraseña"
                                    onChange={(e) => setPassword2(e.target.value)} value={password2}
                                    endContent={
                                        <button className="focus:outline-none" type="button" onClick={toggleVisibility2}>
                                            {isVisible2 ? (
                                                <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                            ) : (
                                                <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                            )}
                                        </button>
                                    }
                                    type={isVisible2 ? "text" : "password"}
                                    className="mb-2"
                                />
                                <Button color="primary"
                                    onClick={handleEnviar}
                                >Restablecer contraseña</Button>
                            </CardBody>
                            <Divider />
                            <CardFooter>
                                <Link
                                    isExternal
                                    showAnchorIcon
                                    className="text-sm cursor-pointer"
                                    href="/login"
                                >
                                    Regresar al incio de sesión
                                </Link>
                            </CardFooter>
                        </Card>


                    </div>
                }
            </div>

        </main>

    );
}
export default ResetPassword;