import Entreno from "../components/entrenamiento/Entreno";
import Header from "../components/Header";

const Entrenamiento = () => {
    return (
        <div className="h-full">
            <Header />
            <Entreno />


        </div >
    )
}
export default Entrenamiento