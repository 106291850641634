import { Card, CardBody } from "@nextui-org/react"
import { Slider } from "@nextui-org/react";
import UserService from "../../../services/UsersService";
import { useEffect, useState } from "react";

const IMC = () => {
   
    const [imc, setImc] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
       
            try {
                const response = await UserService.getIMC();
                if (response) {

                    setImc(response.imc);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    return (
        
        <Card className='mt-3 mb-6'> 
            <CardBody>
                <p className='text-xs text-gray-400 mb-2 '>
                    IMC
                    <br></br>
                    <span className='text-xl text-default-800'>
                        {imc}
                    </span>

                </p>

                <Slider
                    key={imc} // Add this line
                    label=""
                    size="sm"
                    showTooltip={true}
                    step={1}
                    formatOptions={{ style: "decimal" }}
                    maxValue={40}
                    minValue={15}
                    isDisabled={true}
                    marks={[
                        {
                            value: 18.5,
                            label: "18.5",
                        },
                        {
                            value: 24.9,
                            label: "24.9",
                        },
                        {
                            value: 29.9,
                            label: "29.9",
                        },
                        {
                            value: 34.9,
                            label: "34.9",
                        },
                        {
                            value: 39.9,
                            label: "39.9",
                        },
                    ]}
                    defaultValue={imc}
                    classNames={{
                        base: "max-w-md gap-3",
                        track: "border-s-primary-100",
                        filler: "bg-gradient-to-r from-primary-100 to-primary-500",
                        thumb: "bg-primary-500",
                      }}
                />
            </CardBody>
        </Card>
    )
}
export default IMC