
import { Card, CardHeader, Image, CardFooter, Button, Divider, CardBody, Spacer } from "@nextui-org/react";
import { useState } from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalFooter,
    useDisclosure
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import EntrenoService from "../../services/EntrenoService";
import AuthService from "../../services/AuthService";
import { useMessageContext } from '../../contexts/MessageContext';
import Tools from "../../tools/tools";


const Seleccionador = () => {

    const navigate = useNavigate();
    const [entrenoSeleccionado, setEntrenoSeleccionado] = useState(null);
    const { handleError } = useMessageContext();
    const [listaEntrenos, setListaEntrenos] = useState([]);

    const { isOpen: isConfirmOpen, onOpenChange: onConfirmOpenChange } = useDisclosure();


    useEffect(() => {
        //cargamos los entrenos del usuario getListaEntrenos

        if (AuthService.validateToken(handleError)) {

            EntrenoService.getListaEntrenos().then((entrenos) => {
                setListaEntrenos(entrenos.listadoEntrenos);
            }
            ).catch((error) => {
                console.log(error);
            });
        }
    }, [])



    const handleConfirm = () => {
        onConfirmOpenChange(false);


        //redirect a la pagina de entrenamiento con el id del entreno
        navigate(`/entrenamiento/${entrenoSeleccionado}`);
    }

    //https://www.flaticon.es/packs/gym-and-fitness-2?word=gym


    return (
        <div className="" >
            {listaEntrenos.length > 0 ? (
                listaEntrenos.map((entreno) => (
                    <div key={entreno.id}>
                        <Spacer y={4} />

                        <Card className="max-w-[400px]" key={entreno.id}>
                            <CardHeader className="flex gap-3">
                                <Image
                                    alt="nextui logo"
                                    height={40}
                                    radius="sm"
                                    src={Tools.getListadoAvatares().find(avatar => avatar.id === entreno.avatar).avatar}
                                    width={40}
                                />
                                <div className="flex flex-col">
                                    <p className="text-md">{entreno.nombre}</p>
                                    <p className="text-small text-default-500">40 Plus Coaching</p>
                                </div>
                            </CardHeader>
                            <Divider />
                            <CardBody>
                                <Spacer y={2} />
                                <div className="flex justify-between">
                                    <p className="text-md">Ejercicios</p>
                                   <p className="text-md text-default-500">{entreno.ejercicios.split(',').length}</p>
                                </div>
                                <Spacer y={2} />
                                <div className="flex justify-between">
                                    <p className="text-md">Tiempo aprox.</p>
                                    <p className="text-md text-default-500">{entreno.tiempo} minutos</p>
                                </div>


                                <Spacer y={2} />
                            </CardBody>
                            <Divider />
                            <CardFooter>
                                <Button auto color="default" size="small" onClick={() => { setEntrenoSeleccionado(entreno.id); onConfirmOpenChange(true) }}>
                                    Empezar
                                </Button>
                                <p className="absolute right-4 bottom-4 text-default-500 text-xs">{entreno.fecha_ult_entreno ? `Última vez el: ${Tools.sqlToJsDate(entreno.fecha_ult_entreno).toLocaleDateString()}` : 'Entreno aun no realizado'}</p>

                            </CardFooter>
                        </Card>
                    </div>
                ))
            ) : (
                <p className="text-center">No hay entrenos disponibles</p>
            )}

            <Modal isOpen={isConfirmOpen} onOpenChange={onConfirmOpenChange} >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader> ¿Estás seguro de que quieres empezar el entrenamiento {listaEntrenos.find(entreno => entreno.id === entrenoSeleccionado).nombre}?</ModalHeader>

                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Cancelar
                                </Button>
                                <Button color="primary" onPress={handleConfirm}>
                                    Confirmar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

        </div>
    )
}

export default Seleccionador