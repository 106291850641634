import { Card, CardBody } from '@nextui-org/react';
import AdminService from '../../../services/AdminService';
import AuthService from '../../../services/AuthService';
import { useEffect } from 'react';
import { useMessageContext } from '../../../contexts/MessageContext';
import { useState } from "react";
import { Slider } from "@nextui-org/react";


const GraficosUser = ({ data }) => {
 

    const { handleError } = useMessageContext();
    const [imc, setImc] = useState(null);
    useEffect(() => {

        if(!data.formulario) return;

        fetchUser();
        // eslint-disable-next-line
    }, []);


    const fetchUser = async () => {

        if (await AuthService.validateToken(handleError)) {
            const IMC = await AdminService.getImc(data.formulario.id_usuario);

            setImc(IMC.imc);
        }

    }

    return (
        <Card className="w-full px-9 py-4 h-full">

            <CardBody>
                <div className="">   
                   
                        <Card className='bg-gradient-to-r from-primary-100 to-primary-500'>
                            <CardBody>
                                <p className='text-xs text-gray-400 mb-2 '>
                                    IMC
                                    <span className='text-xl text-default-800 ml-2'>
                                        {imc}
                                    </span>

                                </p>
                                <Slider
                                    key={imc} // Add this line
                                    label=""
                                    size="sm"
                                    showTooltip={true}
                                    step={1}
                                    formatOptions={{ style: "decimal" }}
                                    maxValue={40}
                                    minValue={15}
                                    isDisabled={true}
                                    marks={[
                                        {
                                            value: 18.5,
                                            label: "18.5",
                                        },
                                        {
                                            value: 24.9,
                                            label: "24.9",
                                        },
                                        {
                                            value: 29.9,
                                            label: "29.9",
                                        },
                                        {
                                            value: 34.9,
                                            label: "34.9",
                                        },
                                        {
                                            value: 39.9,
                                            label: "39.9",
                                        },
                                    ]}
                                    defaultValue={imc}
                                    classNames={{
                                        base: "max-w-md gap-3",
                                        track: "border-s-warning-100",
                                        filler: "bg-gradient-to-r from-warning-100 to-warning-500",
                                        thumb: "bg-warning-500",
                                    }}
                                />
                            </CardBody>
                        </Card>
                        
                    </div>
            </CardBody>
        </Card>
    )
}
export default GraficosUser