import React, { createContext, useContext, useState } from 'react';

const RevisionContext = createContext();

export const useRevisionContext = () => {
    return useContext(RevisionContext);
    }


  //aqui simplemente devolveremos un true o false dependiendo de si al usuario le toca o no revisión
  
    export const RevisionProvider = ({ children }) => {
    const [revision, setRevision] = useState(false);

    const tocaRevision = () => {
        setRevision(true);
    }

    const noTocaRevision = () => {
        setRevision(false);
    }

    return (
        <RevisionContext.Provider value={{ revision, tocaRevision, noTocaRevision }}>
        {children}
        </RevisionContext.Provider>
    );
    }
