import React, { useEffect, useState } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import AdminService from '../../../services/AdminService'
import AuthService from '../../../services/AuthService';
import { useMessageContext } from '../../../contexts/MessageContext';
import { Input as InputNext } from "@nextui-org/react";


const ModalSuplementos = ({ accion, close, id, handleRefresh }) => {
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const { handleError, handleSuccess } = useMessageContext();
    
    const [nombreSuplemento, setNombreSuplemento] = useState("");
    const [notasSuplemento, setNotasSuplemento] = useState("");
    const [enlaceSuplemento, setEnlaceSuplemento] = useState("");





    useEffect(() => {
        if (accion) {
            onOpen();
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accion]);



    const handleClose = () => {
        onClose();
        close();

    };


    const handleSave = async () => {

        //si no tiene nombre o enlace no se guarda
        if (!nombreSuplemento || !enlaceSuplemento) {
            handleError("Rellene todos los campos");
            return;
        }

        if (await AuthService.validateToken(handleError)) {
            try {
                let dataSuplemento = {
                    nombre: nombreSuplemento,
                    enlace: enlaceSuplemento,
                    notas: notasSuplemento,
                    usuario: id
                }

                const data = await AdminService.addSuplemento(dataSuplemento);
                if (data) {
                    handleSuccess("Suplemento añadido correctamente");
                    handleClose();
                    handleRefresh();
                 
                }
            } catch (error) {
                handleError(error);
            }

        }


    }


    return (

        <>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} onClose={handleClose}>
                <ModalContent>


                    <ModalHeader className="flex justify-between items-center gap-2 ml-1 mr-1">
                        Añadir suplemento al usuario
                    </ModalHeader>
                    <ModalBody>
                        <InputNext label="Nombre" placeholder="Nombre del suplemento" onChange={(e) => setNombreSuplemento(e.target.value)} />
                        <InputNext label="Notas" placeholder="Notas del suplemento" onChange={(e) => setNotasSuplemento(e.target.value)} />
                        <InputNext label="Enlace" placeholder="Enlace del suplemento" onChange={(e) => setEnlaceSuplemento(e.target.value)} />
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={handleClose}>Cerrar</Button>
                        <Button color="primary" onPress={handleSave}>Guardar</Button>
                    </ModalFooter>


                </ModalContent>
            </Modal>
        </>
    );
};

export default ModalSuplementos;
