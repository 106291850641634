import { Skeleton } from "@nextui-org/react";
import { useEffect, useState } from "react";
import UsersService from "../../services/UsersService";
import { Card, CardBody } from "@nextui-org/react";
import { useMessageContext } from '../../contexts/MessageContext';

import { ScrollShadow } from "@nextui-org/react";
import Slider from "react-slick";

export default function Consejo() {
  const [consejo, setConsejo] = useState([]);
  const { handleUnauthenticated, clearMessages } = useMessageContext();


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    buttons: false,
    arrows: false,

};

  useEffect(() => {
    clearMessages();

    UsersService.GetConsejo(handleUnauthenticated).then((res) => {
      setConsejo(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    
    <>
      <div className="max-w-[900px] w-full flex items-center gap-2 ">
        <div className="w-full h-full">
          {consejo.length > 0 ? (
            <Card isFooterBlurred className="h-60 w-full rounded-xl" >
              <CardBody className="absolute z-10 top-1 flex-col items-start h-full w-full">
                <ScrollShadow hideScrollBar className="w-full h-[calc(100%-10px)]">
                <Slider {...settings}>
                    {
                        consejo.map((item, index) => (
                            <div key={index}>
                                <p className="text-warning-600 font-medium text-sm text-center pt-4 ">{item.titulo}</p>
                                <p className="dark:text-white light:text-black font-medium text-xs text-center p-4">{item.consejo}</p>
                            </div>
                        ))
                    }
                </Slider>
                  
                </ScrollShadow>
              </CardBody>
              
            

            </Card>
          ) : (
            <Skeleton className="h-60 w-full rounded-xl" />
          )}
        </div>
      </div>
    </>
  );
}