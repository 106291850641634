import { useEffect, useState } from "react";
import { useMessageContext } from '../../contexts/MessageContext';
import EntrenoService from "../../services/EntrenoService";
import AuthService from "../../services/AuthService";
import { Card, CardHeader, CardBody } from '@nextui-org/react';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { EyeFilledIcon } from "../login/EyeFilledIcon";
import { Modal, ModalContent, ModalHeader, ModalFooter, ModalBody, useDisclosure } from '@nextui-org/react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Tools from "../../tools/tools";

const Entreno = () => {
    const { handleUnauthenticated, handleError } = useMessageContext();
    const [entrenos, setEntrenos] = useState(null);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [selectedEntreno, setSelectedEntreno] = useState(null);



    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        buttons: false,
        arrows: true,

    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (await AuthService.validateToken(handleError)) {
                    const response = await EntrenoService.getEntrenosRealizados(handleUnauthenticated);
                    if (response) {
                        setEntrenos(response.entrenosRealizados);
                    }
                }
            }
            catch (error) {
                console.error(error);
            }
        }
        fetchData();

    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [handleUnauthenticated]
    );


    return (
        <div className="container mx-auto p-6">
            {entrenos && entrenos[0] && (
                <>
                    <Card shadow className="w-full p-4" >
                        <CardHeader>
                            <p className="text-xs text-gray-500">
                                Tú último entreno <span className="text-default-600 text-r">{Tools.sqlToJsDate(entrenos[0].data.fecha).toLocaleDateString()}</span>
                            </p>
                        </CardHeader>
                        <CardBody>
                            <h1 className="text-xl mb-3">{entrenos[0].nombre}</h1>
                            <Slider {...settings}
                                className="w-11/12 mx-auto"
                            >
                                {
                                    Object.values(entrenos[0].data.ejercicios).map((ejercicio, index) => (
                                        <div key={index} className="">
                                            <p className="text-sm text-gray-500 text-center">{ejercicio.nombre}</p>
                                            <Table hover
                                                className="p-2"
                                            >

                                                <TableHeader>
                                                    <TableColumn>Serie</TableColumn>
                                                    <TableColumn>Repeticiones</TableColumn>
                                                    <TableColumn>Peso</TableColumn>
                                                </TableHeader>
                                                <TableBody>
                                                    {
                                                        Object.values(ejercicio.series).map((serie, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell className="">{serie.serie}</TableCell>
                                                                <TableCell>{serie.repeticiones}</TableCell>
                                                                <TableCell>{serie.peso}
                                                                    <span className="text-xs text-gray-500"> kg</span></TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </div>
                                    ))
                                }
                            </Slider>

                        </CardBody>
                    </Card>
                    <Card shadow className="w-full p-4 mt-4">
                        <CardHeader>
                            <p className="text-xs text-gray-500">
                                Entreneos anteriores
                            </p>
                        </CardHeader>
                        <CardBody>
                            <Table hover
                                className="p-2"
                            >
                                <TableHeader>
                                    <TableColumn>Fecha</TableColumn>
                                    <TableColumn>Nombre</TableColumn>
                                    <TableColumn>Acciones</TableColumn>
                                </TableHeader>
                                <TableBody>

                                    {
                                        entrenos.map((entreno, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{Tools.sqlToJsDate(entreno.data.fecha).toLocaleDateString()}</TableCell>
                                                <TableCell>{entreno.nombre}</TableCell>
                                                <TableCell className="flex justify-center"
                                                    onClick={() => {
                                                        setSelectedEntreno(entreno);
                                                        onOpen();
                                                    }
                                                    }
                                                >
                                                    <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none"


                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </CardBody>
                    </Card>
                    {
                        selectedEntreno && (
                            <Modal isOpen={isOpen} onOpenChange={onOpenChange}

                            >
                                <ModalContent>
                                    <ModalHeader className="flex justify-between">

                                        {selectedEntreno?.nombre}
                                        <span className="text-xs ml-2 text-gray-500 mr-4">
                                            {Tools.sqlToJsDate(selectedEntreno?.data.fecha).toLocaleDateString()}
                                        </span>

                                    </ModalHeader>
                                    <ModalBody>

                                        <Slider {...settings}
                                            className="w-11/12 mx-auto"
                                        >
                                            {
                                                Object.values(selectedEntreno?.data.ejercicios).map((ejercicio, index) => (
                                                    <>
                                                        <p className="text-sm text-gray-500 mt-">{ejercicio.nombre}</p>
                                                        <Table hover key={index} className="mb-3">
                                                            <TableHeader>
                                                                <TableColumn>Serie</TableColumn>
                                                                <TableColumn>Repeticiones</TableColumn>
                                                                <TableColumn>Peso</TableColumn>
                                                            </TableHeader>
                                                            <TableBody>
                                                                {
                                                                    Object.values(ejercicio.series).map((serie, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell className="">{serie.serie}</TableCell>
                                                                            <TableCell>{serie.repeticiones}</TableCell>
                                                                            <TableCell>{serie.peso}
                                                                                <span className="text-xs text-gray-500"> kg</span></TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </>
                                                ))
                                            }
                                        </Slider>

                                    </ModalBody>
                                    <ModalFooter>
                                        <button className="btn btn-primary" onClick={onOpenChange}>Cerrar</button>
                                    </ModalFooter>
                                </ModalContent>


                            </Modal>
                        )
                    }
                </>
            )}
            
        </div>
    )
}
export default Entreno;