import Header from "../components/Header";
import DocumentosForm from "../components/documentos/DocumentosForm";
import ListadoDocumentos from "../components/documentos/ListadoDocumentos";
import { Spacer } from '@nextui-org/react';
import { useState } from 'react';

const Misdocumentos = () => {
    const [updated, setUpdated] = useState(false);

    const archivosActualizados = (updated) => {
        setUpdated(updated);
    };


    return (
        <>
        <Header />
        <div className="container p-4">
            <DocumentosForm archivosActualizados={archivosActualizados} />
            <Spacer y={5} />
            <ListadoDocumentos updated={updated} />
            </div>
        </>
    )
}
export default Misdocumentos