import { Card, CardBody, Button, Tooltip } from '@nextui-org/react';
import { FaCirclePlus } from "react-icons/fa6";
const Acciones = ({handleChangeAccio}) => {
    return (
        <Card className="w-full px-9 py-4">
            
            <CardBody>
                <div className="flex items-center justify-center gap-4">
                    <Tooltip showArrow={true} content="Añadir receta">
                        <Button color="warning" auto shadow className="p-3 text-lg min-w-fit rounded-full float-right" onClick={() => handleChangeAccio("add") }>
                            <FaCirclePlus />
                        </Button>
                    </Tooltip>
                </div>
            </CardBody>

        </Card>
    )
}
export default Acciones