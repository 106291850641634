import { Card, CardBody } from '@nextui-org/react';


const DatosUser = ({ data }) => {



    return (
        <Card className="w-full px-9 py-4">

            <CardBody>
                <div className='mt-2'>
                <p className="text-xs font-semibold text-gray-500 mt-2">Nombre y Apellidos <span className='dark:text-white ml-1'> {data.nombre} {data.apellidos}</span></p>
                <p className="text-xs font-semibold text-gray-500 mt-2">Email <span className='dark:text-white ml-1'>{data.email}</span></p>
                <p className="text-xs font-semibold text-gray-500 mt-2">Teléfono <span className='dark:text-white ml-1'>{data.telefono}</span></p>          
                <p className="text-xs font-semibold text-gray-500 mt-2">Quiere recibir publicidad <span className='dark:text-white ml-1'>{data.publicidad ? 'Si' : 'No'}</span></p>
                <p className="text-xs font-semibold text-gray-500 mt-2">Autoriza el uso y difusión de su imagen <span className='dark:text-white ml-1'>{data.imagen ? 'Si' : 'No'}</span></p>
                </div>  

            </CardBody>

        </Card>
    )
}
export default DatosUser