import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useReducer, useState } from "react";
import { Input } from "@nextui-org/react";
import { IoMail } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { BsFillPencilFill } from "react-icons/bs";
import { BsFillCalendarDateFill } from "react-icons/bs"
import AdminService from '../../../services/AdminService'
import AuthService from "../../../services/AuthService";
import { useMessageContext } from '../../../contexts/MessageContext';
import { Select, SelectItem } from "@nextui-org/react";
import { RiUserSettingsFill } from "react-icons/ri";
import { FaPeopleArrows } from "react-icons/fa6";

const initialState = {
    nombre: '',
    apellidos: '',
    user: '',
    pass: '',
    telefono: '',
    email: '',
    caducidad: '',

};

function reducer(state, action) {
    switch (action.type) {
        case 'field': {
            return {
                ...state,
                [action.fieldName]: action.payload,
            };
        }
        default:
            return state;
    }
}




export default function ModalUsuarios({ tipoModal, setTipo, handleRefresh }) {
    const { handleError } = useMessageContext();
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { nombre, apellidos, user, pass, telefono, email, caducidad } = state;
    const [existe, setExiste] = useState(false);
    const [tipoUsuario, setTipoUsuario] = useState(1);
    const [sexo, setSexo] = useState(1);

    const consultarUsuario = async (nombre) => {
        if (nombre) {
            if (await AuthService.validateToken(handleError)) {
                const userExist = await AdminService.consultarUsuario(nombre);
                if (userExist.existe) {
                    setExiste(true);
                } else {
                    setExiste(false);
                }
            }
        } else {
            setExiste(false);
        }
    }

    const handleInputChange = (e) => {
        dispatch({
            type: 'field',
            fieldName: e.target.name,
            payload: e.target.value,
        });
    };

    useEffect(() => {
        if (tipoModal) {
            onOpen()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoModal, existe]);

    const handleClose = () => {
        setTipo();
        onClose();
    }
    const handleGuardar = async () => {


        if (await AuthService.validateToken(handleError)) {

            await AdminService.registrarUsuario(state, tipoUsuario, sexo);
        }

        handleRefresh();
        handleClose();
    }

    return (
        <>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} onClose={handleClose}>
                <ModalContent>
                    {() => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                {tipoModal === 'add' && 'Añadir un nuevo usuario'}</ModalHeader>
                            <ModalBody>
                                {
                                    tipoModal === 'add' && (
                                        <>
                                            <Input
                                                type="text"
                                                name="nombre"
                                                value={nombre}
                                                onChange={handleInputChange}
                                                placeholder="Nombre"
                                                labelPlacement="outside"
                                                startContent={
                                                    <BsFillPencilFill />
                                                }
                                            />
                                            <Input
                                                type="text"
                                                name="apellidos"
                                                value={apellidos}
                                                onChange={handleInputChange}
                                                placeholder="Apellidos"
                                                labelPlacement="outside"
                                                startContent={
                                                    <BsFillPencilFill />
                                                }
                                            />

                                            <Input
                                                type="text"
                                                name="user"
                                                value={user}
                                                onChange={handleInputChange}
                                                onBlur={() => consultarUsuario(user)}
                                                placeholder="Usuario"
                                                labelPlacement="outside"
                                                startContent={
                                                    <FaUser />
                                                }
                                                errorMessage={existe && 'El nombre de usuario ya existe'}

                                            />

                                            <Input
                                                type="text"
                                                name="pass"
                                                value={pass}
                                                onChange={handleInputChange}
                                                placeholder="Contraseña"
                                                labelPlacement="outside"
                                                startContent={
                                                    <FaLock />
                                                }
                                            />
                                            <Input
                                                type="number"
                                                name="telefono"
                                                value={telefono}
                                                onChange={handleInputChange}
                                                placeholder="653653653"
                                                labelPlacement="outside"
                                                startContent={
                                                    <FaPhone />
                                                }
                                            />
                                            <Select
                                                name="sexo"
                                                onChange={(e) => setSexo(e.target.value)}
                                                placeholder="Sexo del usuario"
                                                labelPlacement="outside"
                                                startContent={
                                                    <FaPeopleArrows />
                                                }
                                            >
                                                <SelectItem value="1" key="1">Hombre</SelectItem>
                                                <SelectItem value="2" key="2">Mujer</SelectItem>
                                            </Select>
                                            <Input
                                                type="email"
                                                name="email"
                                                value={email}
                                                onChange={handleInputChange}
                                                placeholder="you@example.com"
                                                labelPlacement="outside"
                                                startContent={
                                                    <IoMail />
                                                }
                                            />
                                            <Input
                                                type="date"
                                                name="caducidad"
                                                value={caducidad}
                                                onChange={handleInputChange}
                                                placeholder="Caducidad"
                                                labelPlacement="outside"
                                                startContent={
                                                    <BsFillCalendarDateFill />
                                                }
                                            />
                                            <Select
                                                name="permisos"
                                                onChange={(e) => setTipoUsuario(e.target.value)}
                                                placeholder="Tipo de usuario"
                                                labelPlacement="outside"
                                                startContent={
                                                    <RiUserSettingsFill />
                                                }
                                            >
                                                <SelectItem value="1" key="1">Usuario</SelectItem>
                                                <SelectItem value="3" key="3">Admin</SelectItem>
                                            </Select>


                                        </>

                                    )
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={handleClose}>
                                    Cerrar
                                </Button>
                                <Button color="primary" onPress={handleGuardar}>
                                    Guardar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}